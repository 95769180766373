// eslint-disable-next-line
export const mentionPattern = /\B@\[([\w\s\.@]+)\]\((\d+)\)/g

/**
 * @param str
 * @param replacement
 * @param index
 * @param length
 */
export function replaceAt(
    str,
    replacement,
    index,
    length = 0
) {
    const prefix = str.substr(0, index);
    const suffix = str.substr(index + length);
  
    return prefix + replacement + suffix;
}

/**
 * Returns active word.
 * @param input
 * @param cursorPosition
 */
export function getActiveWord(input, cursorPosition) {
    const tokenizedQuery = input.split(/[\s\n]/).reduce((acc, word, index) => {
        const previous = acc[index - 1];
        const start = index === 0 ? index : previous.range[1] + 1;
        const end = start + word.length;
  
        return acc.concat([{ word, range: [start, end], index }]);
    }, []);
  
    if (cursorPosition === undefined) {
        return undefined;
    }
  
    const activeWord = tokenizedQuery.find(
        ({ range }) => range[0] < cursorPosition && range[1] >= cursorPosition
    );
  
    return activeWord;
}

/**
 * Renders comments content as html (wraps all mentions with span element).
 * @param {str} content 
 */
export function renderCommentContent(content) {
    return content.replaceAll(
        mentionPattern,
        "<span class='commentItem__comment--mention' data-user='$2'>@$1</span>"
    )
}
  

/**
 * @param content
 */
export function getPlainContent(content) {
    return content.replaceAll(
        mentionPattern,
        '@$1'
    )
}