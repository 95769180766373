/**
 * Renders the objects given.
 * @override
 * @param {CanvasRenderingContext2D} ctx Context to render on.
 * @param {Array} objects Objects to render.
 * @param {object} renderConfig The config object that will be valid during this rendering.
 */
export default function _renderObjects(ctx, objects, renderConfig = {}) {
    let i, len;
    for (i = 0, len = objects.length; i < len; ++i) {
        objects[i] && objects[i].render(ctx, renderConfig);
    }
}