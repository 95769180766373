import {
    BrowserRouter as Router,
    useRoutes,
    Navigate
} from 'react-router-dom';
import { Provider } from 'react-redux';
import InputEmailToResetPassword from './components/auth/inputEmailToResetPassword/InputEmailToResetPassword';
import ResetPasswordScreen from './components/auth/resetPasswordScreen/ResetPasswordScreen';
import BoardListing from './components/boardListing/BoardListing';
import Auth from './components/auth/Auth';
import LandingComponent from './components/landingComponent/Landing';
import NimaBoardComponent from './components/nimaBoard/NimaBoard';
import store from './redux/Store';
import BoardWrapper from './components/boardWrapper/BoardWrapper';
import CanvasesProvider from './context/canvases/CanvasesProvider';
import Maintenance from './components/maintenance/Maintenance';
import { environment } from './environment';
import CreateRequest from './components/request/CreateRequest';
import { loggedInUser } from './services/AuthService';

const BoardWrapperWithProviders = <CanvasesProvider>
    <BoardWrapper />
</CanvasesProvider>


const AppWrapper = () => useRoutes([
    { path: '/', element: <Auth /> },
    {
        path: '/input_email_to_reset_password',
        element: <InputEmailToResetPassword />,
    },
    {
        path: '/reset_password',
        element: <ResetPasswordScreen />,
    },
    { path: '/boards', element: <BoardListing /> },
    { path: '/request-access/:whiteBoardId', element: <CreateRequest /> },
    { path: '/board', element: BoardWrapperWithProviders },
    { path: '/board/:whiteBoardId', element: BoardWrapperWithProviders},
    { path: '/:studio_build_card_id/whiteboard', element: <LandingComponent /> },
    { path: '/:studio_build_card_id/teamBoard', element: BoardWrapperWithProviders },
    { path: '/:studio_build_card_id/nimaBoard', element: <NimaBoardComponent /> },
    { path: '/:whiteBoardId/bmeetBoard', element: BoardWrapperWithProviders },
    { path: '/:whiteBoardId/enterprise', element: BoardWrapperWithProviders },
    { path: '*', element: <Navigate to={loggedInUser() ? '/boards' : '/'} /> }
]);

const App = () => (
    environment.MAINTENANCE
        ?
            <Maintenance />
        :
        (
            <Router>
                <Provider store={store}>
                    <AppWrapper />
                </Provider>
            </Router>
        )
);


export default App;


