import { useCallback, useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric'
import PropTypes from 'prop-types';
import { IMAGE_TITLE_FONT_SIZE, TEXT_EDITOR_PLACEHOLDER, TABLE_DEFAULTS } from '../../helpers/Constant';
import { isTargetLine } from '../../helpers/FabricMethods';
import { isTableTitleTaken, showMessageForSameTableTitle } from '../../helpers/table/TableMethods';

import './TextEditor.scss';
import { isFrameTitleTaken, showMessageForSameFrameTitle } from '../../helpers/frame/FrameMethods';

const TextEditor = ({ canvas }) => {
    const [value, setValue] = useState('');
    const [isOpened, setIsOpened] = useState(false);
    const [textPos, setTextPos] = useState('');
    const targetRef = useRef();
    const editorRef = useRef();
    const textEditorWrapperRef = useRef();
   
    const measureTextWidthHeight = (options) => {
        const textMeasurementCanvas = document.createElement('canvas');
        const textMeasurementContext = textMeasurementCanvas.getContext('2d');  
        textMeasurementContext.save();
        textMeasurementContext.font = options.font;
        const textMeasurement = textMeasurementContext.measureText(options.text);
        const textHeight = textMeasurement.actualBoundingBoxAscent + textMeasurement.actualBoundingBoxDescent;

        return {
            width: textMeasurement.width,
            height: textHeight
        }
    }

    /**
     * Returns the width of the text.
     * @param {object} options 
     * @param {string} options.text - Text to be measured.
     * @param {...object} options - Css options.
     * @returns 
     */
    const measureTextWidthForTable = (options) => {
        const elementForMeasurement = document.createElement('span');
        const { text, ...cssOptions } = options
        const styles = {
            position: 'fixed',
            left: '0',
            top: '0',
            zIndex: '-1',
            ...cssOptions
        }
        // use nbsp instead of space
        elementForMeasurement.innerHTML = text.replaceAll(' ', '&nbsp;');

        // apply the styles
        Object.entries(styles).forEach(([key, value]) => {
            elementForMeasurement.style[key] = value;
        });

        // we need to append the element to the body
        // in order to get the width
        document.querySelector('body').appendChild(elementForMeasurement);

        let width = elementForMeasurement.offsetWidth;
        
        // remove the element
        elementForMeasurement.remove();

        return width;
    }


    const handleCloseEditor = useCallback((isValueUpdated) => {
        if (targetRef.current.shapeType === 'frame') {
            targetRef.current.tempHideText = false;

            canvas.renderAll();
            if (isValueUpdated) {
                const frame = canvas.getObjects().find(obj => obj.uuid === targetRef.current.uuid);
                canvas.fire('modify-to-undo-stack', {target: frame});
            }
        } else if (isValueUpdated) {
            const updatedObj = canvas.getObjects().find(obj => obj.uuid === targetRef.current.uuid);
            canvas.fire('modify-to-undo-stack', {target: updatedObj});
        }

        if (targetRef.current.type === 'optimizedImage' || isTargetLine(targetRef.current) || targetRef.current.type === 'table') {
            targetRef.current.tempHideText = false;
            if (targetRef.current.type === 'table') {
                targetRef.current.setCoords();
            }
            canvas.renderAll();
        }

        setValue('');
        setIsOpened(false);
    }, [canvas]);

    const handleUpdateText = () => {
        const target = targetRef.current;
        let isValueUpdated = false;
        if (target.shapeType === 'frame') {
            const trimmedVal = value.trim();
            if (trimmedVal.length > 0) {
                if (isFrameTitleTaken(canvas, trimmedVal, targetRef.current)) {
                    showMessageForSameFrameTitle(trimmedVal);
                }
                else {
                    targetRef.current.text = value;
                    canvas.fire('frame-text-updated', targetRef.current.uuid);
                    isValueUpdated = true;
                }
            }
        } else if (isTargetLine(target)) {
            const trimmedVal = value.trim(); 
            targetRef.current.tempHideText = false;
            if (trimmedVal.length > 0 || trimmedVal === '') {
                targetRef.current.onShapeChanged();
                targetRef.current.text = value;
                targetRef.current._setPositionDimensions({});
                canvas.renderAll();
                canvas.fire('text-editor-text-updated', targetRef.current);
                canvas.fire('modified-with-event', {target: targetRef.current});
                isValueUpdated = true;
            }
        } else if (target.type === 'optimizedImage') {
            const trimmedVal = value.trim();
            targetRef.current.tempHideText = false;
            if (trimmedVal.length > 0) {
                targetRef.current.text = value;
                canvas.renderAll();
                canvas.fire('text-editor-text-updated', targetRef.current);
                canvas.fire('modified-with-event', {target: targetRef.current});
                isValueUpdated = true;
            }
        } else if (target.type === 'table') {
            const trimmedVal = value.trim();
            if (trimmedVal.length > 0) {
                if (isTableTitleTaken(canvas, trimmedVal, targetRef.current)) {
                    showMessageForSameTableTitle(trimmedVal);
                } else {
                    targetRef.current.tempHideText = false;
                    targetRef.current.title = value;
                    canvas.fire('connector-text-updated', targetRef.current);
                    canvas.fire('modified-with-event', {target: targetRef.current});
                    isValueUpdated = true;
                }
            }
        }
        handleCloseEditor(isValueUpdated);
    }


    const centerTextEditor = useCallback(() => {
        const width = textPos.x - (editorRef.current.offsetWidth / 2);
        const height = textPos.y - (editorRef.current.offsetHeight / 2);

        textEditorWrapperRef.current.style.transform = `translate(${width}px, ${height}px)`;
    }, [textPos]);

    const getPossibleWidthForTextEditor = useCallback(() => {
        const windowWidth = window.innerWidth;
        const boundingRect = editorRef.current.getBoundingClientRect();
        const possibleWidth = windowWidth - boundingRect.left;
        return possibleWidth;
    }, []);


    const getCalculatedEditorWrapperData = useCallback(() => {
        try {
            const zoom = targetRef?.current?.canvas?.getZoom();
            const scaleX = targetRef?.current?.scaleX;
            const scaleY = targetRef?.current?.scaleY;

            const calculatedEditorWrapperData = {
                height: `${(TABLE_DEFAULTS.titleBoxHeight) * zoom * scaleY}px`,
                maxHeight: `${(TABLE_DEFAULTS.titleBoxHeight + TABLE_DEFAULTS.cellBorderWidth) * zoom * scaleY}px`,
                borderWidth: `${TABLE_DEFAULTS.cellBorderWidth * zoom * scaleX}px`,
                borderRadius: `${TABLE_DEFAULTS.borderRadius * zoom * scaleX}px`,
                maxWidth: targetRef.current.width * zoom * scaleX + 'px',
                paddingLeft: `${TABLE_DEFAULTS.titleBoxPadding * zoom * scaleX}px`,
                paddingRight: `${TABLE_DEFAULTS.titleBoxPadding * zoom * scaleX}px`,
                fontSize: TABLE_DEFAULTS.fontSize * zoom * scaleX + 'px'
            } 
            return calculatedEditorWrapperData;
        } catch (err) {
            console.error('error while calculating editor wrapper data', err)
        }
        return {}

    }, [])

    useEffect(() => {
        if (isOpened) {
            if (targetRef.current && targetRef.current.shapeType === 'frame') {
                targetRef.current.tempHideText = true;
                targetRef.current.canvas.renderAll();

                editorRef.current.style.width = getPossibleWidthForTextEditor() + 'px';
            } else if (targetRef.current && isTargetLine(targetRef.current)) {
                // if the action is updating, then calculate the width of input according the current text
                const zoom = targetRef.current.canvas.getZoom();
                if (targetRef.current.isTextEnabled()) {
                    const textBoundingRect = measureTextWidthHeight({ 
                        text: targetRef.text,
                        font: `${targetRef.current.textFontSize * zoom}px Rubik, sans-serif`
                    })
                    targetRef.current.onShapeChanged();
                    targetRef.current.tempHideText = true;
                    targetRef.current.canvas.renderAll();
                    editorRef.current.style.width = textBoundingRect.width + 'px';
                    editorRef.current.style.color = targetRef.current.textColor;
                } else {
                    const textBoundingRect = measureTextWidthHeight({ 
                        text: TEXT_EDITOR_PLACEHOLDER,
                        font: `${targetRef.current.textFontSize * zoom}px Rubik, sans-serif`
                    })
                    editorRef.current.style.width = textBoundingRect.width + 'px';
                }
                // For update: we need this:

                editorRef.current.style.fontSize = targetRef.current.textFontSize * targetRef.current.canvas.getZoom() + 'px';
                centerTextEditor();
            } else if (targetRef.current && targetRef.current.type === 'optimizedImage') {
                targetRef.current.tempHideText = true;
                targetRef.current.canvas.renderAll();
                editorRef.current.style.width = getPossibleWidthForTextEditor() + 'px';
                editorRef.current.style.fontSize = IMAGE_TITLE_FONT_SIZE + 'px';
            } else if (targetRef.current && targetRef.current.type === 'table') {
                const possibleWidth = getPossibleWidthForTextEditor()
                editorRef.current.style.maxWidth = possibleWidth + 'px';
                targetRef.current.onShapeChanged();
                targetRef.current.tempHideText = true;
                const zoom = targetRef.current.canvas.getZoom();
                const scale = targetRef.current.scaleX;
                let wrapperWidth = targetRef.current.width * zoom * scale;

                if (wrapperWidth > possibleWidth) {
                    wrapperWidth = possibleWidth;
                }

                textEditorWrapperRef.current.style.width = wrapperWidth + 'px'

                const calculatedEditorWrapperData = getCalculatedEditorWrapperData();

                Object.entries(calculatedEditorWrapperData).forEach(([key, value]) => {
                    editorRef.current.style[key] = value;
                });

                targetRef.current.canvas.renderAll();
            }

            // in the next process, we need to focus on the input
            setTimeout(() => {
                editorRef.current.focus();
            }, 0);
        }
    }, [isOpened, centerTextEditor, getPossibleWidthForTextEditor, getCalculatedEditorWrapperData])

    useEffect(() => {
        const canvasEditorOpenListener = (target) => {
            if(target.isLocked) return;
            if(target.lockMovementX && target.lockMovementY && target.shapeType !== 'frame') return;
            setValue(target.text);
            targetRef.current = target;
            
            if (target.shapeType === 'frame' ) {
                const targetCoords = target.aCoords;
                const transformedPos = fabric.util.transformPoint(new fabric.Point(targetCoords.textTL.x, targetCoords.textTL.y), canvas.viewportTransform);
                setTextPos({
                    x: transformedPos.x - 10,
                    y: transformedPos.y - 10,
                });
        
                canvas.renderAll();
            }  else if (target.type === 'table') {
                const { textTL } = target.aCoords;
                const transformedPos = fabric.util.transformPoint(
                    new fabric.Point(
                        textTL.x,
                        textTL.y
                    ),
                    target.getViewportTransform()
                );

                setTextPos({
                    x: transformedPos.x,
                    y: transformedPos.y
                });
                setValue(target.title);
                canvas.renderAll();
            } else if (isTargetLine(target)) {
                const middlePoint = target.getMiddlePointForText();

                const transformedMiddlePoint = fabric.util.transformPoint(
                    new fabric.Point(
                        middlePoint.x,
                        middlePoint.y
                    ), 
                    canvas.viewportTransform
                );

                setTextPos({
                    x: transformedMiddlePoint.x,
                    y: transformedMiddlePoint.y
                });
            } else if (target.type === 'optimizedImage') {
                const targetCoords = target.aCoords;
                const transformedPos = fabric.util.transformPoint(new fabric.Point(targetCoords.textTL.x, targetCoords.textTL.y), canvas.viewportTransform);

                setTextPos({
                    x: transformedPos.x,
                    y: transformedPos.y
                });
            }
    
            setIsOpened(true);
        }
        const handleMouseWheel = () => {
            if (isOpened) {
                handleCloseEditor();
            }
        }
        const localCanvas = canvas ? canvas : null;

        if (localCanvas) {
            localCanvas.on('open_text_editor', canvasEditorOpenListener)
            localCanvas.on('mouse:wheel', handleMouseWheel)
        }

        return () => {
            if (localCanvas) {
                localCanvas.off('open_text_editor', canvasEditorOpenListener)
                localCanvas.off('mouse:wheel', handleMouseWheel);
            }
        }
    }, [canvas, isOpened, handleCloseEditor]);


    useEffect(() => {
        const target = targetRef.current;
        if (target && isTargetLine(target))  {
            const zoom = target.canvas.getZoom();
            const textBoundingRect = measureTextWidthHeight({
                text: value,
                font: `${target.textFontSize * zoom}px Rubik, sans-serif`
            });
            if (editorRef.current) {
                editorRef.current.style.width = textBoundingRect.width + 'px';
                centerTextEditor();
            }
        } else if (target && target.type === 'table') {
            if (editorRef.current) {
                const zoom = target?.canvas?.getZoom() || 1;
                const calculatedProperties = getCalculatedEditorWrapperData();
                delete calculatedProperties.maxWidth;
                let newTextboxWidth = measureTextWidthForTable({
                    text: value,
                    border: '3px solid #536DFE',
                    fontFamily: 'Rubik, sans-serif',
                    ...calculatedProperties
                })

                const possibleWidth = getPossibleWidthForTextEditor();
                const targetWidth = targetRef.current.width * zoom * targetRef.current.scaleX;

                if (newTextboxWidth >= possibleWidth) {
                    newTextboxWidth = possibleWidth;
                }

                if (newTextboxWidth >= targetWidth) {
                    newTextboxWidth = targetWidth;
                }

                newTextboxWidth = newTextboxWidth + 'px';

                
                editorRef.current.style.width = newTextboxWidth;
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    if (isOpened) {
        return (
            <div 
                className={`textEditor editorType-${targetRef.current.shapeType}`}
                ref={textEditorWrapperRef}
                style={{
                    transform: `translate(${textPos.x}px, ${textPos.y}px)`,
                }}
            >
                <input 
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={isTargetLine(targetRef.current) ? TEXT_EDITOR_PLACEHOLDER : ''}
                    ref={editorRef}
                    value={value}
                    onBlur={() => handleUpdateText()}
                    // onKeyDown={(e) => {
                    //     if (e.key === 'Enter') {
                    //         e.preventDefault();
                    //         handleUpdateText();
                    //     }
                    // }} 
                />
            </div>
        )
    } 
}

TextEditor.propTypes = {
    canvas: PropTypes.object
}

export default TextEditor;
