import { fabric } from 'fabric';
import isEqual from 'lodash.isequal';
import { getSelectedCellByPointer, sendUpdates } from './TableEventMethods';
import { EMITTER_TYPES, TABLE_DEFAULTS } from '../Constant';
import eventEmitter from '../EventEmitter';
import { isTargetLocked } from '../FabricMethods';
import { deepClone } from '../CommonUtils';
import store from '../../redux/Store';

/**
 * @param e
 */
export function tableMouseDownHandler(e) {
    const target = e.target;
    target.isMoved = false;
}


/**
 * @param e
 */
export function tableMovingHandler(e) {
    const target = e.transform.target;
    target.isMoved = true;

    // Closing the textbox if in edit mode.
    if (target.editingTextbox && target.editingTextbox.isEditing) {
        target.editingTextbox.exitEditing();
    }

    // Recalculate cell positions if cell is selected.
    if (target.isCellSelected) {
        target.calculateCellPositions();
    }
}


/**
 * @param e
 */
export function tableMouseUpHandler(e) {
    const target = e.target;
    const canvas = this.canvas;
    const currentMode = store.getState()?.board?.currentMode;
    if (!canvas || isTargetLocked(target) || (canvas && canvas.isDrawingMode === true) || currentMode === 'LASSO') {
        return;
    }
    if (target.mouseUpCount > 0 && !target.isMoved) {
        const pointer = canvas.getPointer(e);

        target.calculateCellPositions();

        const selectedCell = getSelectedCellByPointer(pointer, target.cells);
        if (!selectedCell) return;

        const selectedCellIndex = target.cells.findIndex((o) => o.id === selectedCell.id);

        // If cell is selected
        if (target.selectedCellIds.includes(selectedCell.id)) {
            // In order to avoid multiple textbox creation, we are checking it.
            if (!target.editingTextbox) {
                target.toggleCellTextVisibility(selectedCell.id, true);
                const textbox = new fabric.Textbox(
                    selectedCell.text ? selectedCell.text : '',
                    {
                        styles: deepClone(selectedCell.styles),
                        left: selectedCell.cellCoords.tl.x + (target.cellTextPadding * target.scaleX),
                        top: selectedCell.cellCoords.tl.y + (target.cellTextPadding * target.scaleY),
                        width: selectedCell.width - target.cellTextPadding * 2,
                        height: selectedCell.height,
                        padding: 0,
                        objectCaching: false,
                        fontSize: selectedCell.fontSize,
                        textAlign: selectedCell.textAlign,
                        fontFamily: 'Rubik',
                        tableUuid: target.uuid,
                        cellId: selectedCell.id,
                        avoidDiscard: true,
                        isTableTextbox: true,
                        hasHyperlink: this.hasHyperlink,
                        cellCoords: selectedCell.cellCoords,
                        scaleX: target.scaleX,
                        scaleY: target.scaleY,
                        tableScaleX: target.scaleX,
                        tableScaleY: target.scaleY,
                        hasBorders: false,
                        hasControls: false,
                        canvas: canvas,
                        tableObject: target,
                        flipX :false,
                        flipY:false,
                        isDynamic: true
                    }
                );
                target.onShapeChanged();
                canvas.add(textbox);
                textbox.setSelectionStart(textbox.text.length);
                textbox.setSelectionEnd(textbox.text.length);
                textbox.enterEditing();
                textbox.hiddenTextarea.setAttribute('maxlength', TABLE_DEFAULTS.maxCharacterCount);
                target.setActiveTextbox(textbox);
    
                textbox.on('editing:entered', () => {
                    target.setCellAsSelected(selectedCell.id);
                });
    
                textbox.on('editing:exited', () => {
                    canvas.fire('exit-shape-text-editing');

                    const currentData = deepClone({
                        text: selectedCell.renderedText?.text ?? '',
                        styles: selectedCell.renderedText?.styles ?? {},
                        textAlign: selectedCell?.textAlign,
                        // fontSize: selectedCell?.fontSize
                    });
    
                    const textValue = textbox.text.length > TABLE_DEFAULTS.maxCharacterCount ? textbox.text.substring(0 , 6000) : textbox.text;
                    target.setCellText(textValue);
                    target.changeCellStyles(textbox.styles, true);
                    target.changeCellStyles({ fontSize: textbox.fontSize, textAlign: textbox.textAlign });
                    target.toggleCellTextVisibility(selectedCell.id, false);
                    target.setActiveTextbox(null);
                    canvas.remove(textbox);
    
                    const newData = {
                        text: textValue,
                        styles: textbox.styles,
                        textAlign: textbox.textAlign,
                        // fontSize: textbox.fontSize
                    }
    
                    // Currently, we don't check the font size because its was designed to be update for every change.
                    let isDataChanged = !isEqual(currentData, newData);

                    // If hyperlink copied from text / shape
                    if (textbox.hasHyperlink && textbox.hyperLinkPositions?.length > 0 && target.hasHyperlink !== true) {
                        target.hasHyperlink = true;
                        isDataChanged = true;
                    }

                    if (isDataChanged) {
                        sendUpdates(canvas, target);
                    }
                });

                textbox.on('changed', () => {
                    const textboxHeight = textbox.height + target.cellTextPadding * 2;
                    const isHeightChanged = textboxHeight > target.cells[selectedCellIndex].height;
    
                    if (isHeightChanged) {
                        target.changeCellRowHeight(
                            selectedCell.id,
                            Math.max(textboxHeight, target.defaultCellHeight)
                        );
                    }
                });

                canvas.on('before-reload-canvas', () => {
                    if (canvas.getObjects().find(e => e.uuid === target.uuid)) {
                        textbox.exitEditing();
                    }
                });
            }
        } else {
            target.setCellAsSelected(selectedCell.id);
            eventEmitter.fire(EMITTER_TYPES.TOOLBAR_HIDE);
            eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW);
            canvas.requestRenderAll();
        }
    } else {
        target.mouseUpCount++;
    }
}

export const tableTitleDoubleClickHandler = (e) => {
    const target = e.target;

    const pointer = target.canvas.getPointer(e);
    const aCoords = target.aCoords;

    if (
        pointer.x >= aCoords?.textTL?.x &&
        pointer.x <= aCoords?.textTR?.x &&
        pointer.y >= aCoords?.textTL?.y &&
        pointer.y <= aCoords?.textBL?.y
    ) {
        target.canvas.fire('open_text_editor', target);
    }
}


/**
 * @param {fabric.Canvas} canvas 
 * @returns {string}
 */
export function generateTableTitle(canvas) {
    let maxNumber = 0;
    canvas.getObjects().forEach((obj) => {
        if (obj.shapeType === 'table') {
            const match = obj.title.match(/Table Name (\d+)/);
            if (match) {
                const number = parseInt(match[1], 10);
                if (number > maxNumber) {
                    maxNumber = number;
                }
            } else {
                maxNumber += 1;
            }
        }
    });
  
    if (maxNumber === 0) {
        return `Table Name`;
    }

    return `Table Name ${maxNumber + 1}`;
}