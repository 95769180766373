import React, { useRef } from 'react'
import PropTypes from 'prop-types';
import useMention from '../../../hooks/UseMention';
import MentionBox from '../../mentionBox/MentionBox';
import SendEmailOne from '../../svgIcons/SendEmailOne';
import { isUserHasAccessToFeature } from '../../../helpers/CommonFunctions';

import './CommentInput.scss';
import clsx from 'clsx';

const REGEX = /@[^ ]+/g;
/**
 * Component for adding new comments or replies.
 * @param {object} props
 * @param props.handleAdd
 * @param props.users
 * @param props.selectedComment
 * @param props.handleResolveComment
 * @param props.additionalStyles
 * @param props.emitCommentCreated
 * @param props.userAccess
 */
const CommentInput = ({ handleAdd, users, selectedComment, handleResolveComment, additionalStyles, emitCommentCreated, userAccess }) => {
    const buttonRef = useRef(null);
    const inputRef = useRef(null);
    const divRef = useRef(null);

    const hasAccessToCommentModification = isUserHasAccessToFeature('comment_modification', userAccess);
    const syncScroll = (e) => {
        divRef.current.scrollTop = e.target.scrollTop;
        divRef.current.scrollLeft = e.target.scrollLeft;
    };

    const {
        value,
        visibleValue,
        setVisibleValue,
        queryFilter,
        showMentions,
        closeMentions,
        disabled,
        handleSelect,
        handleChange,
        getTaggedUserObject
    } = useMention(inputRef);

    const addComment = () => {
        handleKeepFocusedToInput();
        if (disabled || !hasAccessToCommentModification) return;
        handleAdd(value, getTaggedUserObject());
        setVisibleValue('');
        if (selectedComment && selectedComment.resolved) {
            handleResolveComment(selectedComment.uuid, false);
        }

        // emit commentCreated event to go back to previous action state
        if (emitCommentCreated) {
            document.dispatchEvent(new Event('commentCreated', { detail: { comment: value } }));
        }
    }

    const handleKeepFocusedToInput = () => {
        inputRef?.current?.focus();
    }
    
    const handleFocusAndBlur = (event, value) => {
        if (value === 'focus') {
            event.target.parentNode.classList.add('active');
            return
        }
        if (!selectedComment) return;
        event.target.parentNode.classList.remove('active');
    }

    return (
        <div onClick={handleKeepFocusedToInput} className='commentInput' style={{ ...additionalStyles }}>
            <div className={clsx('input-container', { disabled: !hasAccessToCommentModification })}>
                <input
                    className='commentInput--input'
                    data-testid='commentInput--input'
                    disabled={!hasAccessToCommentModification}
                    onBlur={(e) => handleFocusAndBlur(e, 'blur')}
                    onChange={(e) => handleChange(e)}
                    onFocus={(e) => handleFocusAndBlur(e, 'focus')}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !disabled && hasAccessToCommentModification && !showMentions) {
                            addComment();
                        }
                    }}
                    onScroll={syncScroll}
                    placeholder={`${selectedComment && selectedComment.resolved ? 'Reply to re open this thread': 'Type your message here...'}`}
                    readOnly={!hasAccessToCommentModification}
                    ref={inputRef}
                    type='text'
                    value={visibleValue}
                />
                <div className="input-renderer" ref={divRef}>
                    {
                        visibleValue
                            .split(' ')
                            .map((word, i) => {
                                if (word.match(REGEX)) {
                                    return (
                                        <span className="highlight-purple" key={i}>
                                            {word + ' '}
                                        </span>
                                    );
                                } else {
                                    return <span key={i}>{word + ' '}</span>;
                                }
                            })
                    }
                </div>
            </div>
            {
                showMentions ? <MentionBox
                    addComment={addComment}
                    closeMentions={closeMentions}
                    filter={queryFilter}
                    handleSelectCallback={handleSelect}
                    users={users} /> : null
            }
            <button
                type="button"
                aria-label="Add comment"
                className={clsx('commentInput--btn', { 'disabled': disabled || !hasAccessToCommentModification })}
                data-testid='commentInput--btn'
                onClick={addComment}
                ref={buttonRef}
            >
                <div onClick={handleKeepFocusedToInput}><SendEmailOne /></div>
                {
                    (!disabled || hasAccessToCommentModification) ? <span className="tooltiptext">Send</span> : null
                }
            </button>
        </div>
    )
}

CommentInput.propTypes = {
    handleAdd: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.oneOf(['view', 'comment', 'edit'])
    })),
    selectedComment: PropTypes.shape({
        id: PropTypes.number,
        whiteboardId: PropTypes.number,
        pageId: PropTypes.number,
        userId: PropTypes.number,
        parentId: PropTypes.number,
        content: PropTypes.string,
        contentSearch: PropTypes.string,
        taggedUserIds: PropTypes.any,
        resolved: PropTypes.any,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        colorCode: PropTypes.string,
        uuid: PropTypes.string,
        parentUuid: PropTypes.any,
        isDeleted: PropTypes.bool,
        isEdited: PropTypes.bool,
        threadLink: PropTypes.any,
        resolvedBy: PropTypes.object,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        replies: PropTypes.arrayOf(PropTypes.shape({
            userId: PropTypes.number,
            uuid: PropTypes.string,
            content: PropTypes.string,
            parentUuid: PropTypes.string,
            isDeleted: PropTypes.bool,
            user: PropTypes.shape({
                name: PropTypes.string,
            }),
            createdAt: PropTypes.string,
            userComment: PropTypes.arrayOf(PropTypes.shape({
                readAt: PropTypes.string
            }))
        })),
        userComment: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            readAt: PropTypes.any
        })),
        user: PropTypes.shape({
            commentUsername: PropTypes.string,
            name: PropTypes.string
        })
    }),
    handleResolveComment: PropTypes.func,
    additionalStyles: PropTypes.object,
    emitCommentCreated: PropTypes.bool,
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED'])
}

export default CommentInput;