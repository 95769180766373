import React, { useEffect, useRef, useState } from 'react';
import { useLayer } from 'react-laag';
import { motion, AnimatePresence } from 'framer-motion';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { formatDateTime } from '../../../helpers/FormatTime';
import { isUserHasAccessToFeature } from '../../../helpers/CommonFunctions';
import { renderCommentContent } from '../../../helpers/mentions';
import CheckCircle from '../../svgIcons/CheckCircle';
import VerticalMenuDots from '../../svgIcons/VerticalMenuDots';

import './CommentItem.scss';
import CommentEditBox from './edit/CommentEditBox';
import { centerToObjectWithAnimation, getFabricObject } from '../../../helpers/FabricMethods';
import { useSelector } from 'react-redux';

/**
 * Component for single comment reply.
 * @param {object} props
 * @param props.selectedComment
 * @param props.selectedCommentIcon
 * @param props.setSelectedCommentIcon
 * @param props.isOwner
 * @param props.handleUpdateContent
 * @param props.handleDeleteComment
 * @param props.handleResolveComment
 * @param props.handleReadComment
 * @param props.handleMarkAsUnreadComment
 * @param props.parentUuid
 * @param props.users
 * @param props.isParent
 * @param props.wrapperRef
 * @param props.userAccess
 * @param props.showReplies
 * @param props.setShowReplies
 * @param props.canvas
 * @param props.isDrawer
 * @param props.setTriggerMarks
 * @param props.setMarkingReplies
 */
const CommentItem = ({ selectedComment, selectedCommentIcon, setSelectedCommentIcon, isOwner, handleUpdateContent, handleDeleteComment, handleResolveComment, handleReadComment, handleMarkAsUnreadComment, parentUuid, users, isParent, wrapperRef, userAccess, showReplies, setShowReplies, canvas, isDrawer, setTriggerMarks, setMarkingReplies }) => {
    const [isOpenedEditInput, setIsOpenedEditInput] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [timer, setTimer] = useState(setTimeout(() => { }));
    const contentRef = useRef();

    const { id } = useSelector(state => state?.user);

    const closeMenu = () => setShowMenu(false);

    const checkIsRead = () => {
        return (selectedComment?.userComment?.find(item => item.userId === id)?.readAt === null || selectedComment?.replies?.map(a => a.userComment)?.map(c => c.find(f => f.userId === id))?.map(i => i?.readAt)?.includes(null))
    }

    useEffect(() => {
        setTriggerMarks && setTriggerMarks(prevState => !prevState);
    }, [])

    const { triggerProps, layerProps, renderLayer } = useLayer({
        isOpen: showMenu,
        onOutsideClick: closeMenu,
        onDisappear: closeMenu,
        auto: true,
        placement: 'bottom-end',
    });

    const handleOpenEditInput = () => {
        setIsOpenedEditInput(!isOpenedEditInput)
    }

    const handleEditEmit = (payload) => {
        let isReply = (selectedComment.parentId || selectedComment.parentUuid) ? true : false;
        setIsOpenedEditInput(false);
        handleUpdateContent({
            ...payload,
            commentUuid: selectedComment.uuid,
            isReply,
            parentUuid
        });
    }

    const closeEditBox = () => {
        setIsOpenedEditInput(false);
    }

    const handleClickComment = () => {
      
        if (canvas) {
            const clickedObj = getFabricObject(canvas, 'commentID', selectedComment.parentUuid ?? parentUuid);
            if(!clickedObj?.visible) return
            clearTimeout(timer);
        
            const newTimer = setTimeout(() => {
                if (clickedObj?.commentID !== selectedCommentIcon?.commentID) {
                    setSelectedCommentIcon(null);
                }

                if (clickedObj) {
                    clickedObj.set({ hasBorders: false, hasControls: false });
                    centerToObjectWithAnimation(canvas, clickedObj, {
                        adaptZoomLevel: true,
                        selectAfterAnimation: isUserHasAccessToFeature('objectAnimation', userAccess),
                        showToolbar: isUserHasAccessToFeature('subToolbar', userAccess)
                    });
                    setTimeout(() => {
                        setSelectedCommentIcon(clickedObj);
                    }, 500)
                }
            }, 500);
            setTimer(newTimer);
        }

    }

    useEffect(() => {
        if (isOpenedEditInput) {
            contentRef.current.style.display = 'none';
        } else {
            contentRef.current.style.display = 'block';
        }
    }, [isOpenedEditInput])

    const showRepliesButton =
      setShowReplies &&
      Array.isArray(selectedComment.replies) &&
      selectedComment.replies.length > 0;

    const getButtonLabel = () => {
        const replyText = selectedComment.replies?.length > 1 ? 'replies' : 'reply';
        if (showReplies) {
            return `Hide ${replyText}`;
        }
        return `${selectedComment.replies.length} ${replyText}`;
    };

    return (
        <li onClick={handleClickComment}  className="commentItem">
            <header className="commentItem__header">
                <hgroup className="commentItem__header--left">
                    {checkIsRead() && isParent && isDrawer ? <div><span className="badge success" /></div> : null}
                    <h4 className="commentItem__header--title">
                        {selectedComment.user.name}
                    </h4>
                </hgroup>
                <div className={clsx('commentItem__header--right', { 'float-right': !isOwner })}>
                    <div className="commentItem__header--date">
                        {formatDateTime(selectedComment.createdAt).replace(' PM', '').replace(' AM', '').replace(' at ', ', ')}
                    </div>
                    {((
                        isUserHasAccessToFeature(
                            'comment_modification',
                            userAccess
                        ) && isOwner && !isDrawer
                    ) || (isUserHasAccessToFeature(
                        'comment_modification',
                        userAccess
                    ) && isDrawer && (isParent || (!isParent && isOwner)))) ? <div className="commentItem__header--actions">
                        <div className="commentMoreOption">
                            <button
                                {...triggerProps}
                                // {...hoverProps}
                                type="button"
                                aria-label="More options"
                                className="btnCommentAction"
                                onClick={(e)=>e.stopPropagation()}
                                onMouseEnter={() => setShowMenu(true)}
                                onMouseLeave={() => setShowMenu(false)}
                            >
                                <VerticalMenuDots height={16} width={16} />
                            </button>
                            {renderLayer(
                                <AnimatePresence>
                                    {showMenu ? <motion.div
                                        animate={{ opacity: 1 }}
                                        className="commentOptionsMenu"
                                        exit={{ opacity: 0 }}
                                        initial={{ opacity: 0 }}
                                        transition={{ ease: 'easeInOut', duration: 0.2 }}
                                        {...layerProps}
                                        onMouseEnter={() => setShowMenu(true)}
                                        onMouseLeave={() => setShowMenu(false)}
                                    >
                                        {
                                            isDrawer && isParent ? <>
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        selectedComment.resolved ? handleResolveComment(selectedComment.parentUuid ?? parentUuid, false, selectedComment) :
                                                            handleResolveComment(selectedComment.parentUuid ?? parentUuid, true, selectedComment);
                                                    }}
                                                    className={clsx('commentMoreOptionsInner__item', {
                                                        disabled: !isUserHasAccessToFeature(
                                                            'comment_modification',
                                                            userAccess
                                                        ),
                                                    })}
                                                >
                                                    {selectedComment.resolved ? 'Reopen thread' : 'Mark as resolved'}
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        checkIsRead() ? handleReadComment(selectedComment.parentUuid ?? parentUuid, selectedComment) : handleMarkAsUnreadComment(selectedComment.parentUuid ?? parentUuid, selectedComment?.replies?.length + 1)
                                                    }}
                                                    className={clsx('commentMoreOptionsInner__item', {
                                                        disabled: !isUserHasAccessToFeature(
                                                            'comment_modification',
                                                            userAccess
                                                        ),
                                                    })}
                                                >
                                                    Mark as {checkIsRead() ? 'read' : 'unread'}
                                                </button>
                                            </> : null
                                        }
                                        {!isDrawer ? <button
                                            className={clsx('commentMoreOptionsInner__item', {
                                                disabled: !isUserHasAccessToFeature(
                                                    'comment_modification',
                                                    userAccess
                                                ),
                                            })}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (
                                                    isUserHasAccessToFeature(
                                                        'comment_modification',
                                                        userAccess
                                                    )
                                                ) {
                                                    handleOpenEditInput();
                                                }
                                                closeMenu();
                                            }}
                                            type="button"
                                        >
                                            Edit
                                        </button> : null
                                        }
                                        {
                                            isOwner ? <button
                                                className={clsx('commentMoreOptionsInner__item', {
                                                    disabled: !isUserHasAccessToFeature(
                                                        'comment_modification',
                                                        userAccess
                                                    ),
                                                })}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        !isUserHasAccessToFeature(
                                                            'comment_modification',
                                                            userAccess
                                                        )
                                                    )
                                                        return;

                                                    if (isParent) {
                                                        // if this is not reply
                                                        // delete comment with parentUuid
                                                        // in this case parentUuid equals to selectedComment.uuid
                                                        handleDeleteComment(parentUuid);
                                                    } else {
                                                        handleDeleteComment(
                                                            selectedComment.uuid,
                                                            parentUuid
                                                        );
                                                    }

                                                    closeMenu();
                                                }}
                                                type="button"
                                            >
                                                Delete {isParent && isDrawer ? 'thread' : null}
                                            </button> : null
                                        }
                                    </motion.div> : null}
                                </AnimatePresence>
                            )}
                        </div>
                    </div> : null}
                </div>
            </header>
            <main className="commentItem__comment">
                <main
                    className="commentContent"
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{
                        __html: renderCommentContent(selectedComment.content),
                    }}
                    ref={contentRef}
                />
                {isOpenedEditInput ? <CommentEditBox
                    closeEditBox={closeEditBox} 
                    contentRef={contentRef} 
                    defaultTags={
                        selectedComment.tagged_user_ids
                            ? selectedComment.tagged_user_ids
                            : selectedComment.taggedUserIds
                    }
                    defaultValue={selectedComment.content}
                    handleEditEmit={handleEditEmit}
                    users={users}
                    wrapperRef={wrapperRef}
                /> : null}
                <footer className={clsx({ 'space-between': showRepliesButton })}>
                    {showRepliesButton ? <button
                        type="button"
                        className="toggleReplyBtn"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowReplies(!showReplies);
                            setMarkingReplies(prevState => !prevState);
                        }}
                    >
                        {getButtonLabel()}
                    </button> : null}
                    <div
                        className={clsx({
                            resolved: setShowReplies && selectedComment.resolved,
                        })}
                    >
                        {!isOpenedEditInput ? (((selectedComment.isEdited && !selectedComment.resolved) || (selectedComment.isEdited && !isDrawer)) && 'Edited') || (selectedComment.resolved && selectedComment?.resolvedBy?.name && isDrawer && (
                            <>
                                <CheckCircle
                                    filling="#00C853"
                                    height={14}
                                    outline="white"
                                    width={14}
                                />
                                <span>Resolved by {selectedComment?.resolvedBy?.name?.length > 10 ? `${selectedComment?.resolvedBy?.name?.trimStart().slice(0, 10)}...` : selectedComment?.resolvedBy?.name}</span>
                            </>
                        )) : null}
                    </div>
                </footer>
            </main>
        </li>
    );
};

CommentItem.propTypes = {
    selectedComment: PropTypes.shape({
        id: PropTypes.number,
        whiteboardId: PropTypes.number,
        pageId: PropTypes.number,
        userId: PropTypes.number,
        parentId: PropTypes.number,
        content: PropTypes.string,
        contentSearch: PropTypes.string,
        taggedUserIds: PropTypes.any,
        tagged_user_ids: PropTypes.any,
        resolved: PropTypes.any,
        position: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number
        }),
        colorCode: PropTypes.string,
        uuid: PropTypes.string,
        parentUuid: PropTypes.any,
        isDeleted: PropTypes.bool,
        isEdited: PropTypes.bool,
        threadLink: PropTypes.any,
        resolvedBy: PropTypes.object,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        replies: PropTypes.arrayOf(PropTypes.shape({
            userId: PropTypes.number,
            uuid: PropTypes.string,
            content: PropTypes.string,
            parentUuid: PropTypes.string,
            isDeleted: PropTypes.bool,
            user: PropTypes.shape({
                name: PropTypes.string,
            }),
            createdAt: PropTypes.string,
            userComment: PropTypes.arrayOf(PropTypes.shape({
                readAt: PropTypes.string
            }))
        })),
        userComment: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            userId: PropTypes.number,
            readAt: PropTypes.any
        })),
        user: PropTypes.shape({
            commentUsername: PropTypes.string,
            name: PropTypes.string
        })
    }),
    selectedCommentIcon: PropTypes.object,
    setSelectedCommentIcon: PropTypes.object,
    isOwner: PropTypes.bool,
    handleUpdateContent: PropTypes.func,
    handleDeleteComment: PropTypes.func,
    handleResolveComment: PropTypes.func, 
    handleReadComment: PropTypes.func,
    handleMarkAsUnreadComment: PropTypes.func,
    parentUuid: PropTypes.any,
    users: PropTypes.arrayOf(PropTypes.shape({
        commentEmail: PropTypes.string,
        commentUsername: PropTypes.string,
        email: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        permission: PropTypes.string
    })),
    isParent: PropTypes.bool,
    wrapperRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement)
    }),
    userAccess: PropTypes.oneOf(['view', 'comment', 'edit', 'removeAccess', 'NOT_ALLOWED']),
    showReplies: PropTypes.bool,
    setShowReplies: PropTypes.bool,
    canvas: PropTypes.object,
    isDrawer: PropTypes.bool, 
    setTriggerMarks: PropTypes.func, 
    setMarkingReplies: PropTypes.func
}

export default CommentItem;
