import { fabric } from 'fabric';
import { DEFAULT_TEXT_SIZE, SHAPE_DEFAULTS, TEXTBOX_LINE_HEIGHT } from './Constant';
import { rotatePointBack } from './lines/AttachedObjectTransformHandlers';
import { getSelectedCellByPointer } from './table/TableEventMethods';

let canvas, textEl;

export const getTextMetrics = (text, font) => {
    if (!canvas) {
        canvas = document.createElement('canvas');
    }
    const canvas2dContext = canvas.getContext('2d');
    canvas2dContext.font = font;
    const metrics = canvas2dContext.measureText(text);
    return metrics;
};

/**
 * Calculates the given text width. In addition to below function (getLineWidth), this fn also consider the characters bolder feature.
 * @param {string} text 
 * @param {object} options 
 * @returns {number}
 */
export const calculateTextWidth = (text, options = {}) => {
    const styles = options.styles ? { ...options.styles } : null;
    delete options.styles;

    const textGroups = [];
    if (styles) {
        let boldFlag = styles[0]?.fontWeight === 'bold';
        let word = text[0];

        for (let i = 1; i < text.length; i++) {
            const isBold = styles ? styles[i]?.fontWeight === 'bold' : false;
            word += text[i];

            if (isBold !== boldFlag) {
                textGroups.push({ text: word, isBold });

                boldFlag = isBold;
                word = '';
            }
        }

        if (word) {
            textGroups.push({ text: word, isBold: boldFlag });
        }
    } else {
        textGroups.push({
            text,
            isBold: options.fontWeight === 'bold'
        });
    }

    let width = 0;
    textGroups.forEach(({ text, isBold }) => {
        const metrics = getTextMetrics(text, `${isBold ? 'bold' : 'normal'} ${options.fontSize}px ${options.fontFamily}`);
        width += metrics.width;
    });

    return width;
}

export const getLineWidth = (text, font) => {
    const metrics = getTextMetrics(text, font);
    return metrics.width;
};

/**
 * Getting line styles per the originalLines.
 * @param {number} lineIndex 
 * @param {fabric.Object} object 
 * @param {number} startIndex 
 * @returns {object}
 */
export const getLineStyles = (lineIndex, object, startIndex) => {
    const styles = {};
    const lineStyles = object.styles[lineIndex];
    if (!lineStyles) return {};

    for (const [charIdx, style] of Object.entries(lineStyles)) {
        styles[charIdx - startIndex] = style;
    }
    
    return styles;
}

/**
 * Returns the given word with.
 * @param {string} text 
 * @param {fabric.Object} object 
 * @param {object} options 
 * @returns {number}
 */
export const getWordWidth = (text, object, options = {}) => {
    return calculateTextWidth(text, {
        ...options,
        fontWeight: object.fontWeight ?? 'normal',
        fontFamily: object.fontFamily ?? SHAPE_DEFAULTS.FONT_FAMILY,
        fontSize: options.fontSize ? options.fontSize : (object.fontSize ?? DEFAULT_TEXT_SIZE),
        scaleX: object.scaleX,
        scaleY: object.scaleY
    });
}

export const getTextHeight = (text, font, width) => {
    if (!textEl) {
        textEl = document.createElement('div')
    }

    // If last character is newline, dom doesn't calculate the height properly. Therefore, added one character after it.
    if (text.endsWith('\n')) {
        text += 'i';
    }

    const
        body = document.body;
    textEl.style.position = 'absolute';
    textEl.style.width = width + 'px';
    textEl.style.font = font; 
    textEl.style.wordBreak = 'break-word';
    textEl.style.left = '-9999px';
    textEl.style.top = '-9999px';
    textEl.style.lineHeight = TEXTBOX_LINE_HEIGHT;
    body.appendChild(textEl);

    textEl.innerText = text; // It doesn't matter what text goes here
    const height = textEl.getBoundingClientRect().height;
    body.removeChild(textEl);
    return height;
};

export const charWidth = (() => {
    const cachedCharWidth = {};
    const calculate = (char, font) => {
        const ascii = char.charCodeAt(0);
        if (!cachedCharWidth[font]) {
            cachedCharWidth[font] = [];
        }
        if (!cachedCharWidth[font][ascii]) {
            const width = getLineWidth(char, font);
            cachedCharWidth[font][ascii] = width;
        }
        return cachedCharWidth[font][ascii];
    };
    const getCache = (font) => {
        return cachedCharWidth[font];
    };
    return {
        calculate,
        getCache,
    };
})();

export const wrapText = (text, fontSize, maxWidth, lineIndex, totalLines, object) => {
    let actualLineIndex = 0;
    const lines = [];
    const lineIndices = {};
    const originalLines = text.split('\n');
    const spaceWidth = getWordWidth(' ', object, { fontSize });
    let removedWhiteSpacesByLineIndex = [];
    let removedWhiteSpaceCount = 0;
    const push = (str, isNotWrapping = false) => {
        lines.push(str);
        if (actualLineIndex >= 0) {
            // add line indices
            lineIndices[totalLines + actualLineIndex] = isNotWrapping;
            removedWhiteSpacesByLineIndex[totalLines + actualLineIndex] = removedWhiteSpaceCount;
            removedWhiteSpaceCount = 0;
            actualLineIndex++;
        }
    };
    originalLines.forEach((originalLine) => {
        const words = originalLine.split(' ');
        // This means its newline so push it
        if (words.length === 1 && words[0] === '') {
            lines.push(words[0]);
        }
        else {
            let currentLine = '';
            let currentLineWidthTillNow = 0;
            let charsCountTillNow = 0;
            let index = 0;
            while (index < words.length) {
                let word = words[index];

                const styles = getLineStyles(lineIndex, object, charsCountTillNow);

                let currentWordWidth;
                if (word === '') {
                    currentWordWidth = spaceWidth;
                    word = ' ';
                } else {
                    currentWordWidth = getWordWidth(word, object, { styles, fontSize });
                }
                
                // Start breaking longer words exceeding max width
                if (currentWordWidth >= maxWidth) {
                    // push current line since the current word exceeds the max width
                    // so will be appended in next line
                    // push(currentLine, true);
                    currentLine = '';
                    currentLineWidthTillNow = 0;
                    let count = 0;
                    while (word.length > 0) {
                        const currentChar = word[0];
                        let width = getWordWidth(currentChar, object, {
                            styles: { 0: object._getStyleDeclaration(lineIndex, charsCountTillNow + count) },
                            fontSize
                        });

                        currentLineWidthTillNow += width;
                        word = word.slice(1);
                        if (currentLineWidthTillNow >= maxWidth) {
                            // only remove last trailing space which we have added when joining words
                            if (currentLine.slice(-1) === ' ') {
                                removedWhiteSpaceCount += 1;
                                currentLine = currentLine.slice(0, -1);
                            }

                            if (currentLine) { // dont push empty line
                                push(currentLine, true);
                            }
                            currentLine = currentChar;
                            currentLineWidthTillNow = width;
                            if (currentLineWidthTillNow === maxWidth) {
                                currentLine = '';
                                currentLineWidthTillNow = 0;
                            }
                        }
                        else {
                            currentLine += currentChar;
                        }
                        count++;
                    }
                    // push current line if appending space exceeds max width
                    if (currentLineWidthTillNow + spaceWidth >= maxWidth) {
                        push(currentLine);
                        currentLine = '';
                        currentLineWidthTillNow = 0;
                    } else if (currentLine?.length > 0) {
                        push(currentLine);
                        currentLine = '';
                        currentLineWidthTillNow = 0;
                    } else {
                        // space needs to be appended before next word
                        // as currentLine contains chars which couldn't be appended
                        // to previous line
                        currentLine += ' ';
                        currentLineWidthTillNow += spaceWidth;
                    }
                    index++;
                }
                else {
                    // Start appending words in a line till max width reached
                    while (currentLineWidthTillNow < maxWidth && index < words.length) {
                        let word = words[index];
                        currentLineWidthTillNow = getWordWidth(currentLine + word, object, { styles, fontSize });

                        if (currentLineWidthTillNow >= maxWidth) {
                            if (currentLine.slice(-1) === ' ') {
                                removedWhiteSpaceCount += 1;
                                currentLine = currentLine.slice(0, -1);
                            }
                            push(currentLine);
                            currentLineWidthTillNow = 0;
                            currentLine = '';
                            break;
                        }
                        index++;
                        currentLine += `${word} `;
                        // Push the word if appending space exceeds max width
                        if (currentLineWidthTillNow + spaceWidth >= maxWidth) {
                            const word = currentLine.slice(0, -1);
                            push(word);
                            currentLine = '';
                            currentLineWidthTillNow = 0;
                            break;
                        }
                    }
                    if (currentLineWidthTillNow === maxWidth) {
                        currentLine = '';
                        currentLineWidthTillNow = 0;
                    }
                }

                charsCountTillNow += word.length;
            }
            if (currentLine) {
                // only remove last trailing space which we have added when joining words
                if (currentLine.slice(-1) === ' ') {
                    removedWhiteSpaceCount += 1;
                    currentLine = currentLine.slice(0, -1);
                }
                push(currentLine);
            }
        }
    });
    return { lines, lineIndices, removedWhiteSpaceCount, removedWhiteSpacesByLineIndex };
};
// helpers for shapes
const DUMMY_TEXT = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.toLocaleUpperCase();
export const BOUND_TEXT_PADDING = 20;

export const getApproxMinLineWidth = (
    font
) => {
    const maxCharWidth = getMaxCharWidth(font);
    if (maxCharWidth === 0) {
        return (
            measureText(DUMMY_TEXT.split('').join('\n'), font).width +
        BOUND_TEXT_PADDING * 2
        );
    }
    return maxCharWidth + BOUND_TEXT_PADDING * 2;
};

export const getMaxCharWidth = (font) => {
    const cache = charWidth.getCache(font);
    if (!cache) {
        return 0;
    }
    const cacheWithOutEmpty = cache.filter((val) => val !== undefined);
    return Math.max(...cacheWithOutEmpty);
};

export const measureText = (
    text,
    font
) => {
    text = text
        .split('\n')
    // replace empty lines with single space because leading/trailing empty
    // lines would be stripped from computation
        .map((x) => x || ' ')
        .join('\n');
    const fontSize = parseFloat(font);
    const height = getTextHeight(text, fontSize);
    const width = getTextWidth(text, font);
    const baseline = measureBaseline(text, font);
    return { width, height, baseline };
};

export const getTextWidth = (text, font) => {
    const lines = splitIntoLines(text);
    let width = 0;
    lines.forEach((line) => {
        width = Math.max(width, getLineWidth(line, font));
    });
    return width;
};

export const splitIntoLines = (text) => {
    return normalizeText(text).split('\n');
};
export const normalizeText = (text) => {
    return (
        text
        // replace tabs with spaces so they render and measure correctly
            .replace(/\t/g, '        ')
        // normalize newlines
            .replace(/\r?\n|\r/g, '\n')
    );
};

export const measureBaseline = (
    text,
    font,
    lineHeight,
    wrapInContainer,
) => {
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.whiteSpace = 'pre';
    container.style.font = font;
    container.style.minHeight = '1em';
    if (wrapInContainer) {
        container.style.overflow = 'hidden';
        container.style.wordBreak = 'break-word';
        container.style.whiteSpace = 'pre-wrap';
    }
  
    container.style.lineHeight = String(TEXTBOX_LINE_HEIGHT);
  
    container.innerText = text;
  
    // Baseline is important for positioning text on canvas
    document.body.appendChild(container);
  
    const span = document.createElement('span');
    span.style.display = 'inline-block';
    span.style.overflow = 'hidden';
    span.style.width = '1px';
    span.style.height = '1px';
    container.appendChild(span);
    let baseline = span.offsetTop + span.offsetHeight;
    document.body.removeChild(container);
    return baseline;
};


export const getApproxMinLineHeight = (
    fontSize,
    lineHeight,
) => {
    return getLineHeightInPx(fontSize, lineHeight) + BOUND_TEXT_PADDING * 2;
};

export const getLineHeightInPx = (
    fontSize,
    lineHeight,
) => {
    return fontSize * lineHeight;
};


export const getTextWidthForEllipse = (target, aspectRatio = 1 / 2) => {
    const a = target.rx;
    const b = target.rx;
    const textWidth = 2 * a * aspectRatio;
    const textHeight = 2 * b * aspectRatio;
    return {
        width: textWidth,
        height: textHeight,
    }
}

/**
 * Checking that whether the pointer position over a hyperlink. If so returning the hyperlink url.
 * @param {fabric.Object} target 
 * @param {{ x: number, y: number}} pointer 
 * @param hyperlinkedObjects
 * @returns {{ url: string, bl: { x: number, y: number } }}
 */
export const getHoveredHyperLink = (target, pointer, hyperlinkedObjects = []) => {
    let hyperLinkPositions = [];

    if (hyperlinkedObjects.length > 0) {
        hyperlinkedObjects.forEach((obj) => {
            if (obj.type === 'textbox') {
                hyperLinkPositions.push(...obj.hyperLinkPositions);
            } else if (obj.type === 'table') {
                const cell = getSelectedCellByPointer(pointer, obj.cells);
                if (cell?.renderedText) {
                    hyperLinkPositions.push(...cell.renderedText.hyperLinkPositions);
                }
            } else if (Array.isArray(obj._objects) && !!obj._objects[1]) {
                hyperLinkPositions.push(...obj._objects[1].hyperLinkPositions);
            }
        });
    } else if (target.type === 'textbox') {
        hyperLinkPositions = target.hyperLinkPositions;
    } else if (target.type === 'table') {
        const cell = getSelectedCellByPointer(pointer, target.cells);
        if (cell?.renderedText) {
            hyperLinkPositions = cell.renderedText.hyperLinkPositions;
        }
    } else {
        hyperLinkPositions = target._objects[1]?.hyperLinkPositions;
    }

    if (!Array.isArray(hyperLinkPositions)) return { url: '' };

    
    for (const pos of hyperLinkPositions) {
        // After addWithUpdate method run, although angle value is equal to 0 for group, we can get the actual angle value from the first object of group.
        let realAngle = pos.hoveredObject.angle;
        if (pos.hoveredObject.type === 'group') {
            const rectangleAngle = pos.hoveredObject.getObjects()[0].angle;
    
            if (rectangleAngle > 0 && rectangleAngle !== realAngle) {
                realAngle = rectangleAngle;
                pos.hoveredObject.realAngle = realAngle;
            } else if (pos.hoveredObject.angle > 0) {
                pos.hoveredObject.realAngle = pos.hoveredObject.angle;
            }
        }

        // Shapes can be rotatable. So we need to rotate the pointer back to understand whether these pointers inside of the hyperlink area..
        let unrotatedValue = {};

        if (hyperlinkedObjects.length > 0) { // If there is active selection
            const selectionCenter = target.getCenterPoint();
            const objCenter = pos.hoveredObject.getCenterPoint();

            unrotatedValue = rotatePointBack(
                pointer.x,
                pointer.y,
                selectionCenter.x + objCenter.x,
                selectionCenter.y + objCenter.y,
                realAngle
            );
        } else {
            unrotatedValue = rotatePointBack(
                pointer.x,
                pointer.y,
                target.getPointByOrigin('center').x,
                target.getPointByOrigin('center', 'center').y,
                realAngle
            );
        }

        if (
            unrotatedValue.x >= pos.tl.x &&
            unrotatedValue.x <= pos.tr.x &&
            unrotatedValue.y >= pos.tr.y &&
            unrotatedValue.y <= pos.br.y
        ) {
            return pos
        }
    }

    return { url: '' }
}