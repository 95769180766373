import { fabric } from 'fabric';
import { CellText } from './CellText';
import { TABLE_DEFAULTS } from '../../Constant';
import { getTextMetrics } from '../../TextWrapHelpers';
import { deepClone } from '../../CommonUtils';

const SELECTED_COLOR = '#536DFE';
const FONT_FAMILY = 'Rubik, sans-serif';


export const Table = fabric.util.createClass(fabric.Rect, {
    type: 'table',
    shapeType: 'table',
    objectCaching: false,
    rectRadius: TABLE_DEFAULTS.borderRadius,
    strokeColor: '#C0C3CE',
    fillColor: '#fff',
    title: 'Table Name',
    tempHideText: false,
    cellBorderWidth: TABLE_DEFAULTS.cellBorderWidth,
    strokeWidth: 0,
    isCellSelected: false,
    selectedCellIds: [],
    searchedCellIds: [],
    lockScalingFlip: true,
    cellTextPadding: TABLE_DEFAULTS.cellTextPadding,
    defaultCellHeight: TABLE_DEFAULTS.defaultCellHeight,
    defaultCellWidth: TABLE_DEFAULTS.defaultCellWidth,
    selected: false,  // is this table selected
    initialize: function(options = {}) {
        this.defaultCellHeight = options?.defaultCellHeight || TABLE_DEFAULTS.defaultCellHeight
        this.defaultCellWidth = options?.defaultCellWidth || TABLE_DEFAULTS.defaultCellWidth

        const totalRows = Array.isArray(options.rows) ? options.rows.length : (options.totalRows ?? 3);
        const totalCols = Array.isArray(options.cols) ? options.cols.length : (options.totalCols ?? 3);

        this.rows = Array.from(Array(totalRows)).map((i, idx) => ({
            height: Array.isArray(options.rows) ? options.rows[idx].height : this.defaultCellHeight
        }));

        this.cols = Array.from(Array(totalCols)).map((i, idx) => ({
            width: Array.isArray(options.cols) ? options.cols[idx].width : this.defaultCellWidth
        }));

        // No need to attach these informations to the table
        delete options.totalRows;
        delete options.totalCols;

        this.cells = [];
        this.initCells(options?.cellTexts);

        const { width, height } = this.getTableDimensions();
        options.width = width;
        options.height = height;
        this.callSuper('initialize', options);

        // set the default dimensions of the cells
        this.setCellDimensions();

        // while generating the table with the data in it, we need to calculate the cell positions
        // and dimensions
        if (options?.cellTexts) {
            this.calculateCellPositions();

            for (const cell of this.cells.filter(cell => cell.text)) {
                this.generateCellTextBox(cell, { calculateHeight: true });
                if (!cell.renderedText) {
                    console.log('could not check the cell dimensions for the cell: ', cell);
                    continue;
                }

                // check if the textBox height is bigger than cell's height
                // if so, then we need to update the cell height
                const textBoxHeight = cell.renderedText.height + this.cellTextPadding * 2;
                const isHeightChanged = textBoxHeight > cell.height;

                if (isHeightChanged) {
                    this.changeCellRowHeight(
                        cell.id,
                        Math.max(textBoxHeight, this.defaultCellHeight)
                    );
                }

            }
        }
    },
    /**
     * Automatically called by fabric js when this shape is selected.
     */
    onSelect() {
        // set mouse up count to 0 so that we can know not to select any cell
        // in the first click
        this.mouseUpCount = 0;
        this.selected = true;
        this.onShapeChanged();
    },
    /**
     * Automatically called by fabric js when this shape is unselected.
     */
    onDeselect() {
        const isEditing = this.editingTextbox && this.editingTextbox.isEditing;

        // Exit editing mode after deselection.
        if (isEditing) {
            this.editingTextbox.exitEditing();
        }

        this.selected = false;
        this.setAllCellsAsUnSelected();
        this.canvas.fire('exit-shape-text-editing');
        this.onShapeChanged();
    },
    _render: function(ctx) {
        ctx.save();
        // Drawing all cells
        this.drawCells(ctx);

        // Calculate the cell positions
        this.calculateCellPositions();

        // Rendering table title
        if (this.title && !this.tempHideText) this.renderTitle(ctx);

        const selectedCells = this.cells.filter(o => this.selectedCellIds.includes(o.id));
        const selectedCellIds = selectedCells.map((cell) => cell.id);

        // Highlighting the table if table selected
        this.highlightTable(ctx, selectedCells);

        // Highlighting the table calls if selected
        this.highlightCells(ctx, selectedCellIds);

        ctx.restore();

    },
    getTitlePosition() {
        const measurement = measureTableTitle(this.title, 1);
        const textWidth = Math.abs(measurement.actualBoundingBoxLeft) + Math.abs(measurement.actualBoundingBoxRight);
        const textHeight = Math.abs(measurement.actualBoundingBoxAscent) + Math.abs(measurement.actualBoundingBoxDescent);
    
        const rectDimensions = {
            width: textWidth + (TABLE_DEFAULTS.titleBoxPadding * 2),
            height: TABLE_DEFAULTS.titleBoxHeight
        }
    
        const startPos = {
            x: -this.width / 2 + this.cellBorderWidth / 2,
            y: (-this.height / 2) - 1, // origin bottom
        }

        const areaOfRectangle = {
            x: startPos.x,
            y: startPos.y - rectDimensions.height + this.cellBorderWidth
        }

        if (rectDimensions.width > this.width) {
            rectDimensions.width = this.width - this.cellBorderWidth;
        }

        return {
            left: areaOfRectangle.x,
            top: areaOfRectangle.y,
            right: areaOfRectangle.x + rectDimensions.width,
            bottom: areaOfRectangle.y + rectDimensions.height,
            width: rectDimensions.width,
            height: rectDimensions.height - this.cellBorderWidth,
            startPos,
            textWidth,
            textHeight
        }
    },
    /**
     * Rendering table title.
     * @param {*} ctx 
     */
    renderTitle(ctx) {
        ctx.save();
        ctx.fillStyle = '#FFFFFF';
        ctx.font = `${TABLE_DEFAULTS.fontSize}px ${FONT_FAMILY}`;
        const { left, top, width, height, textWidth } = this.getTitlePosition();
        ctx.beginPath();
        ctx.roundRect(left, top, width, height, [TABLE_DEFAULTS.borderRadius]);
        ctx.lineWidth = this.cellBorderWidth;
        ctx.fillStyle = '#fff';
        ctx.strokeStyle = this.selected ? SELECTED_COLOR : this.strokeColor;
        ctx.fill();
        ctx.stroke();

        ctx.fillStyle = '#3C3E49';
        let newText = this.title;
        if (textWidth + TABLE_DEFAULTS.titleBoxPadding * 2 > width) {
            newText = getWantedTextFromWidth(this.title, width - TABLE_DEFAULTS.titleBoxPadding * 2, 1);
        }
        ctx.textBaseline = 'middle';
        ctx.fillText(
            newText, 
            left + TABLE_DEFAULTS.titleBoxPadding,
            top - this.cellBorderWidth / 2 + TABLE_DEFAULTS.titleBoxHeight / 2
        );
        ctx.restore();
    },
    /**
     * Initalizes all cells during table initialization.
     */
    initCells(cellTexts = null) {
        let id = 0;
        for (let i = 0; i < this.rows.length; i++) {
            for (let j = 0; j < this.cols.length; j++) {
                const cell = {
                    id,
                    selected: false,
                    text: cellTexts !== null ? cellTexts[i][j] : null,
                    row: [i],
                    col: [j],
                    textAlign: 'left',
                    fontSize: 14,
                    styles: {}
                }

                this.cells.push(cell)
                id++;
            }
        }
    },
    /**
     * Calculating table width and height per the initial or default width and height properties.
     * @returns {{ width: number; height: number }}
     */
    getTableDimensions() {
        const width = this.cols.reduce((accum, curr) => accum + curr.width, this.cellBorderWidth);
        const height = this.rows.reduce((accum, curr) => accum + curr.height, this.cellBorderWidth);

        return { width, height };
    },

    /**
     * Calculation cell width and heights.
     * @param {object} cell 
     * @returns {{ width: number, height: number }}
     */
    getCellDimensions(cell) {
        const cellWidth = this.cols.slice(cell.col[0], cell.col[cell.col.length - 1] + 1)
            .map(o => o.width)
            .reduce((accum, curr) => accum + curr, 0);
    
        const cellHeight = this.rows.slice(cell.row[0], cell.row[cell.row.length - 1] + 1)
            .map(o => o.height)
            .reduce((accum, curr) => accum + curr, 0);
        return {
            width: cellWidth,
            height: cellHeight
        }
    },
    /**
     * Calculates and set the dimensions to all cells.
     */
    setCellDimensions() {
        const leftOffset = -this.width / 2;
        const topOffset = -this.height / 2;
        let xPos = leftOffset + this.cellBorderWidth / 2;
        let yPos = topOffset + this.cellBorderWidth / 2;
        let currentRowIndex = 0;

        for (const cell of this.cells) {
            if (typeof cell.mergedWith === 'number') { continue; }

            const { width: cellWidth, height: cellHeight } = this.getCellDimensions(cell);
            const cellRectRadius = this.getCellRadius(cell);
            cell.width = cellWidth;
            cell.height = cellHeight;
            cell.rectRadius = cellRectRadius;

            if (cell.row[0] !== currentRowIndex) {
                xPos = -this.width / 2 + this.cellBorderWidth / 2;
                yPos += this.rows[currentRowIndex].height;
                currentRowIndex = cell.row[0];
            }

            cell.left = xPos;
            cell.top = yPos;

            const thisCol = this.cols[cell.col[0]];
            xPos += thisCol.width;
        }
    },
    /**
     * @param {object} cell 
     * @returns {number | null}
     */
    getCellRadius(cell) {
        const rowsLength = this.rows.length,
            colsLength = this.cols.length;
        
        const rectRadius = [0, 0, 0, 0];
        let isRadiusUpdated = false;
        if (cell.row[0] === 0 && cell.col[0] === 0) {
            isRadiusUpdated = true;
            rectRadius[0] = this.rectRadius;
        }

        if (cell.row[0] === 0 && cell.col[cell.col.length - 1] === colsLength - 1) {
            isRadiusUpdated = true;
            rectRadius[1] = this.rectRadius;
        }

        if (cell.row[cell.row.length - 1] === rowsLength - 1 && cell.col[cell.col.length - 1] === this.cols.length - 1) {
            isRadiusUpdated = true;
            rectRadius[2] = this.rectRadius;
        }

        if (cell.row[cell.row.length - 1] === rowsLength - 1 && cell.col[0] === 0) {
            isRadiusUpdated = true;
            rectRadius[3] = this.rectRadius;
        }

        if (isRadiusUpdated) {
            return rectRadius;
        }

        // in case we need to check if the cell has rect radius or not
        return null;
    },
    /**
     * Selects the given cell and unselects other cells.
     * @param {number} cellId 
     * @param {boolean=true} unSelectOthers 
     */
    setCellAsSelected(cellId, unSelectOthers = true) {
        if (unSelectOthers) {
            if (this.editingTextbox) {
                this.editingTextbox.exitEditing();
            }

            this.isCellSelected = false;
            this.selectedCellIds = [];
        }

        const cell = this.cells.find(o => o.id === cellId);
        if (cell) {
            this.selectedCellIds.push(cell.id);
            this.isCellSelected = true;

            this.canvas.fire('hyperlink:table_cell_selected');
        }
        this.onShapeChanged();
    },
    /**
     * Unselect all cells.
     */
    setAllCellsAsUnSelected() {
        this.isCellSelected = false;
        this.selectedCellIds = [];
    },
    /**
     * Setting the textbox value to the cell.
     * @param {string} text 
     */
    setCellText(text) {
        const cell = this.getSelectedCell();
        if (cell) {
            cell.text = text;
        }
    },
    /**
     * Toggles visibility of cell text field.
     * @param {number} cellId 
     * @param {boolean} desiredVisibility 
     */
    toggleCellTextVisibility(cellId, desiredVisibility) {
        const cell = this.cells.find(o => o.id === cellId);
        let hideText = desiredVisibility;
        if (desiredVisibility === null || desiredVisibility === undefined) {
            hideText = !cell.hideText;
        } else {
            hideText = desiredVisibility
        }
        if (cell) {
            cell.hideText = hideText;
        }
    },
    /**
     * In case of any style properties change in the textbox, this method should be called. Its basically updates the cell, textbox and rendered text fields.
     * @param {object} styles 
     * @param {boolean} isTextboxStyles 
     * @param {number=} cellId 
     */
    changeCellStyles(styles, isTextboxStyles = false, cellId = null) {
        const cell = Number.isSafeInteger(cellId) ? this.getCellById(cellId) : this.getSelectedCell();
        const cellIdx = this.cells.findIndex((c) => c.id === cell?.id);

        if (cellIdx > -1) {
            const currentCellHeight = cell.height;

            if (isTextboxStyles) {
                this.cells[cellIdx].styles = styles;

                if (cell.renderedText) {
                    cell.renderedText.set({ styles });
                }

                if (this.editingTextbox) {
                    this.editingTextbox.set({ styles });
                }
            } else {
                for (const key in styles) {
                    this.cells[cellIdx][key] = styles[key];
    
                    if (cell.renderedText) {
                        cell.renderedText.set({ [key]: styles[key] });
                    }
    
                    if (this.editingTextbox) {
                        this.editingTextbox.set({ [key]: styles[key] });
                    }
                }
            }

            const textbox = this.editingTextbox ? this.editingTextbox : cell.renderedText;
            if (textbox) {
                const textboxHeight = calculateTextHeight(textbox.text, {
                    fontSize: this.cells[cellIdx].fontSize,
                    textAlign: this.cells[cellIdx].textAlign,
                    styles: this.cells[cellIdx].styles,
                    width: this.cells[cellIdx].width - (this.cellTextPadding * 2),
                    scaleX: this.scaleX,
                    scaleY: this.scaleY
                })

                if (textboxHeight !== currentCellHeight) {
                    this.changeCellRowHeight(
                        cell.id,
                        Math.max((textboxHeight ?? 0) + this.cellTextPadding * 2, this.defaultCellHeight)
                    );
                }
            }
        }
    },
    /**
     * In case of height of any cell is changed, this function should be called to calculate new height of rows.
     * @param {number} cellId 
     * @param {number} height 
     */
    changeCellRowHeight(cellId, height) {
        this.onShapeChanged();
        const cellIdx = this.cells.findIndex(o => o.id === cellId);
        if (cellIdx > -1) {
            // Update the rows first
            const cell = this.cells[cellIdx];
            const otherRowCells = this.cells.filter((c, idx) => c.row[0] === cell.row[0] && idx !== cellIdx);
            const otherRowCellsHeights = otherRowCells.map((c) => {
                const textboxHeight = (calculateTextHeight(c.text ?? '', {
                    fontSize: c.fontSize,
                    textAlign: c.textAlign,
                    styles: c.styles,
                    width: (c.cellCoords.width / this.scaleX) - (this.cellTextPadding * 2),
                    scaleX: this.scaleX,
                    scaleY: this.scaleY
                }) ?? 0);

                return Math.max(textboxHeight + this.cellTextPadding * 2, this.defaultCellHeight)
            });

            const newHeight = Math.max(...otherRowCellsHeights, height);
            const row = this.rows[cell.row[0]];

            if (row.height !== newHeight) {
                row.height = newHeight;
    
                // Update the table width and height
                const tableDimensions = this.getTableDimensions();
                this.set({
                    width: tableDimensions.width,
                    height: tableDimensions.height
                });
    
                // Calculate and set cells dimensions
                this.setCellDimensions();
    
                // Set new coordinates to cell
                this.calculateCellPositions();
                
                // Update the textbox positions if we are in editing mode
                this.updateTextboxPositions(cell.id);

                this.canvas?.requestRenderAll();
            }

            // If canvas is cached then we need to update the canvas cache in every row height changed.
            if (this.canvas?.cacheOperation === 'ENABLED' && this._cacheCanvas) {
                this._updateCacheCanvas();
            }
        }
    },
    /**
     * Drawing all cells into the table.
     * @param {*} ctx 
     */
    drawCells(ctx) {
        for (const cell of this.cells) {
            if (typeof cell.mergedWith !== 'number') {
                ctx.lineWidth = this.cellBorderWidth;
                ctx.beginPath();
                if (cell?.rectRadius?.length) {
                    ctx.roundRect(cell.left, cell.top, cell.width, cell.height, cell.rectRadius);
                } else {
                    ctx.rect(cell.left, cell.top, cell.width, cell.height);
                }

                ctx.fillStyle = cell.fill || this.fillColor;
                ctx.fill(); 
                ctx.strokeStyle = this.strokeColor;
                ctx.lineWidth = this.cellBorderWidth;
                ctx.stroke()
                ctx.closePath();

                if (cell.text && !cell.hideText) {
                    if (!cell.renderedText) {
                        this.generateCellTextBox(cell);
                    } else {
                        cell.renderedText.initDimensions();
                    }
                    if (cell.text !== cell.renderedText.text) {
                        cell.renderedText.text = cell.text;
                    }
                    cell.renderedText.left = cell.left + this.cellTextPadding;
                    cell.renderedText.top = cell.top + this.cellTextPadding;
                    cell.renderedText.render(ctx);
                }

            }
        }
    },
    /**
     * Calculates all cell coordinates.
     */
    calculateCellPositions() {
        let targetCenter = this.getCenterPoint();
        const scale = this.scaleX;
        if (
            this.canvas?._activeObject?.type === 'activeSelection' &&
            this.canvas?._activeObject?.shapeType !== 'loadingMockImagesGroup' &&
            this.group
        ) {
            // 1. get the matrix for the object.
            const  matrix = this.group.calcTransformMatrix();
            // 2. choose the point you want, fro example top, left.
            const point = { x: targetCenter.x, y: targetCenter.y };
            // 3. transform the point
            targetCenter = fabric.util.transformPoint(point, matrix)
        }

        this.cells = this.cells.map((cell) => {
            const cellPosition = {
                left: (cell.left * scale) + targetCenter.x,
                top: (cell.top * scale) + targetCenter.y,
            };
    
            const width = cell.width * scale;
            const height = cell.height * scale;
    
            const cellCoords = {
                tl: { x: cellPosition.left, y: cellPosition.top, },
                tr: { x: cellPosition.left + width, y: cellPosition.top },
                bl: { x: cellPosition.left, y: cellPosition.top + height },
                br: { x: cellPosition.left + width, y: cellPosition.top + height },
                width,
                height
            };
    
            return { ...cell, cellCoords };
        });
    },
    /**
     * Highlighes the table if table is selected.
     * @param {*} ctx 
     */
    highlightTable(ctx) {
        const isSelected = this.selected;
        if (!isSelected) return;

        ctx.beginPath();
        ctx.strokeStyle = SELECTED_COLOR;
        ctx.lineWidth = this.cellBorderWidth;
        ctx.roundRect(
            -this.width / 2 + this.cellBorderWidth / 2,
            -this.height / 2 + this.cellBorderWidth / 2, 
            this.width - this.cellBorderWidth,
            this.height - this.cellBorderWidth, 
            [this.rectRadius]
        );
        ctx.stroke();
    },
    highlightCells(ctx, selectedCellIds) {
        if (selectedCellIds.length === 0 && this.searchedCellIds.length === 0) return;

        ctx.beginPath();
        for (const cell of this.cells) {
            const isSearchMatched = this.searchedCellIds.includes(cell.id);
            const isCellSelected = selectedCellIds.includes(cell.id);

            if (isSearchMatched || isCellSelected) {
                ctx.roundRect(cell.left, cell.top, cell.width, cell.height, cell.rectRadius);
                ctx.strokeStyle = isSearchMatched ? 'red' : SELECTED_COLOR;
                ctx.stroke();
            }
        }
        ctx.closePath();
    },
    /**
     * Stores the searched cells to highlight.
     * @param {[number]} cellIds 
     */
    setSearchedCells(cellIds) {
        if (Array.isArray(cellIds)) {
            this.searchedCellIds = cellIds;
        }
    },
    /**
     * Clears the searched cells.
     */
    clearSearchedCells() {
        this.searchedCellIds = [];
    },
    /**
     * Storing flag in order to reach to the textbox in anywhere on the board.
     * @param {object} textboxObj 
     */
    setActiveTextbox(textboxObj) {
        if (textboxObj === null) {
            delete this.editingTextbox;
        } else {
            this.editingTextbox = textboxObj;
        }
    },
    /**
     * Updates the textbox position in case of cell height is changed.
     * @param {*} cellId 
     */
    updateTextboxPositions(cellId) {
        const cell = this.getCellById(cellId);
        if (!this.editingTextbox && !cell?.renderedText) return;
        
        const dimensions = {
            left: cell.cellCoords.tl.x + (this.cellTextPadding * this.scaleX),
            top: cell.cellCoords.tl.y + (this.cellTextPadding * this.scaleX),
            height: cell.cellCoords.height * 2,
        }

        if (this.editingTextbox) {
            this.editingTextbox.set({ ...dimensions });
        }

        if (cell.renderedText) {
            cell.renderedText.set({ restrictedHeight: dimensions.height });
        }
    },
    /**
     * Returns the selected cells.
     * @returns {object}
     */
    getSelectedCell() {
        if (!this.isCellSelected) return null;
        return this.cells.find((cell) => this.selectedCellIds.includes(cell.id));
    },
    /**
     * Returns cell by given cell id.
     * @param {number} cellId 
     * @returns 
     */
    getCellById(cellId) {
        return this.cells.find((cell) => cell.id === cellId);
    },
    /**
     * Generates the cell text object
     * @param {object} cell the cell object
     * @param {object} options
     * @param {boolean} options.calculateHeight - if true, will calculate the height of the text
     */
    generateCellTextBox(cell, options = {}) {
        // if we need to calculate the textBox height,
        // then we need to set the restricted height to null
        const textBoxHeight = options?.calculateHeight ? null : cell.height;
        cell.renderedText = new CellText(
            cell.text,
            {
                styles: deepClone(cell.styles),
                left: cell.left + this.cellTextPadding,
                top: cell.top + this.cellTextPadding,
                width: cell.width - this.cellTextPadding * 2,
                restrictedHeight: textBoxHeight,
                heightPadding: this.cellTextPadding,
                backgroundColor: 'rgba(0, 0, 255, 0.2)',
                fontFamily: 'Rubik',
                fontSize: cell.fontSize,
                textAlign: cell.textAlign,
                hasHyperlink: this.hasHyperlink,
                cellCoords: cell.cellCoords,
                tableUuid: this.uuid,
                cellId: cell.id,
                tableScaleX: this.scaleX,
                tableScaleY: this.scaleY,
            }
        )
    },
    // ******** OVERRIDES *********
    /**
     * @override
     */
    _getImageLines: function(oCoords) {
        let lines = {
            topline: { o: oCoords.tl, d: oCoords.tr },
            rightline: { o: oCoords.tr, d: oCoords.br },
            bottomline: { o: oCoords.br, d: oCoords.bl },
            leftline: { o: oCoords.bl, d: oCoords.tl }
        };

        if (
            oCoords.hasOwnProperty('textTL') &&
            oCoords.hasOwnProperty('textTR') &&
            oCoords.hasOwnProperty('textBL') &&
            oCoords.hasOwnProperty('textBR')
        ) {
            const newLines = {
                textTopline: { o: oCoords.textTL, d: oCoords.textTR },
                textRightline: { o: oCoords.textTR, d: oCoords.textBR },
                textBottomline: { o: oCoords.textBR, d: oCoords.textBL },
                textLeftline: { o: oCoords.textBL, d: oCoords.textTL }
            }

            lines = { ...lines, ...newLines }
        }

        return lines;
    },
    /**
     * @override
     */
    containsPoint: function(point, lines, absolute, calculate) {
        let coords = this._getCoords(absolute, calculate);
        let xLines = lines || this._getImageLines(coords);
        let xPoints = this._findCrossPoints(point, xLines);
        // if xPoints is odd then point is inside the object
        return (xPoints !== 0 && xPoints % 2 === 1);
    },
    /**
     * @override
     */
    calcACoords: function(getCompletePosition = false) {
        const center = this.getCenterPoint();
        if (getCompletePosition && this.group && this.group.type === 'activeSelection') {
            const groupCenter = this.group.getCenterPoint();
            center.x += groupCenter.x;
            center.y += groupCenter.y;
        }
        let translateMatrix = [1, 0, 0, 1, center.x, center.y];
        const rotateMatrix = this._calcRotateMatrix(),
            finalMatrix = fabric.util.multiplyTransformMatrices(translateMatrix, rotateMatrix),
            dim = this._getTransformedDimensions(),
            w = dim.x / 2, h = dim.y / 2

        const absoluteCoords =  {
            // corners
            tl: fabric.util.transformPoint({ x: -w, y: -h }, finalMatrix),
            tr: fabric.util.transformPoint({ x: w, y: -h }, finalMatrix),
            bl: fabric.util.transformPoint({ x: -w, y: h }, finalMatrix),
            br: fabric.util.transformPoint({ x: w, y: h }, finalMatrix)
        };

        // if text is present, then calculate the text bounding box and add it to the absolute coords
        if (this.canvas && typeof this.title === 'string' && this.title.trim().length > 0) {
            const titleMargin = 0.5 * this.scaleY;
            const titleHeight = TABLE_DEFAULTS.titleBoxHeight * this.scaleY;
            let startingPoint = {
                x: -w,
                y: -h - titleMargin - titleHeight
            } 
            let top = startingPoint.y;
            let left = startingPoint.x;
            let bottom = startingPoint.y + titleHeight;

            let { right } = this.getTitlePosition();
            right = right * this.scaleX;

            absoluteCoords.textTL = fabric.util.transformPoint({ x: left, y: top }, finalMatrix);
            absoluteCoords.textTR = fabric.util.transformPoint({ x: right, y: top }, finalMatrix);
            absoluteCoords.textBL = fabric.util.transformPoint({ x: left, y: bottom }, finalMatrix);
            absoluteCoords.textBR = fabric.util.transformPoint({ x: right, y: bottom }, finalMatrix);
        }

        return absoluteCoords;
    },
    /**
     * @override
     */
    calcLineCoords: function() {
        var vpt = this.getViewportTransform(),
            padding = this.padding, angle = fabric.util.degreesToRadians(this.angle),
            cos = fabric.util.cos(angle), sin = fabric.util.sin(angle),
            cosP = cos * padding, sinP = sin * padding, cosPSinP = cosP + sinP,
            cosPMinusSinP = cosP - sinP, aCoords = this.calcACoords();

        var lineCoords = {
            tl: fabric.util.transformPoint(aCoords.tl, vpt),
            tr: fabric.util.transformPoint(aCoords.tr, vpt),
            bl: fabric.util.transformPoint(aCoords.bl, vpt),
            br: fabric.util.transformPoint(aCoords.br, vpt),
        };

        if (aCoords.hasOwnProperty('textTL') && aCoords.hasOwnProperty('textTR') && aCoords.hasOwnProperty('textBL') && aCoords.hasOwnProperty('textBR')) {
            const textCoords = {
                textTL: fabric.util.transformPoint(aCoords.textTL, vpt),
                textTR: fabric.util.transformPoint(aCoords.textTR, vpt),
                textBL: fabric.util.transformPoint(aCoords.textBL, vpt),
                textBR: fabric.util.transformPoint(aCoords.textBR, vpt),
            }
            lineCoords = {
                ...lineCoords,
                ...textCoords
            }
        }

        if (padding) {
            lineCoords.tl.x -= cosPMinusSinP;
            lineCoords.tl.y -= cosPSinP;
            lineCoords.tr.x += cosPSinP;
            lineCoords.tr.y -= cosPMinusSinP;
            lineCoords.bl.x -= cosPSinP;
            lineCoords.bl.y += cosPMinusSinP;
            lineCoords.br.x += cosPMinusSinP;
            lineCoords.br.y += cosPSinP;
        }

        return lineCoords;
    },
    /**
     * Creates bounding box from points.
     * It's overridden because we want to handle title position of table also.
     * @param {Array} points Array representation of the coords.
     * @param {Array} transform An array of 6 numbers representing a 2x3 transform matrix.
     * @returns {object} Returns a bounding box object.
     */
    makeBoundingBoxFromPoints(points, transform) {
        if (transform) {
            for (let i = 0; i < points.length; i++) {
                points[i] = fabric.util.transformPoint(points[i], transform);
            }
        }
        const xPoints = points.map(p => p.x);
        let minX = fabric.util.array.min(xPoints),
            maxX = fabric.util.array.max(xPoints),
            width = maxX - minX;
        const yPoints = points.map(p => p.y);
        let minY = fabric.util.array.min(yPoints),
            maxY = fabric.util.array.max(yPoints),
            height = maxY - minY;

        return {
            left: minX,
            top: minY,
            width: width,
            height: height
        };
    },
    /**
     * It's overridden because we want to handle title position of table also.
     * @param {object} coords The coords that will be turned into an array.
     * @returns {Array} Array representation of the coords.
     */
    arrayFromCoords(coords) {
        const arrayCoords = [
            new fabric.Point(coords.tl.x, coords.tl.y),
            new fabric.Point(coords.tr.x, coords.tr.y),
            new fabric.Point(coords.br.x, coords.br.y),
            new fabric.Point(coords.bl.x, coords.bl.y)
        ];
        
        if (coords.textTL) {
            arrayCoords.push(new fabric.Point(coords.textTL.x, coords.textTL.y));
        }
        if (coords.textTR) {
            arrayCoords.push(new fabric.Point(coords.textTR.x, coords.textTR.y));
        }
        return arrayCoords
    },
    /**
     * It's overridden because we want to handle title position of table also. 
     * @override
     */
    getCompleteBoundingRect() {
        const coords = this.calcACoords(true);
        return this.makeBoundingBoxFromPoints(this.arrayFromCoords(coords));
    }
});

/**
 * Calculates the given text width.
 * @param {string} text 
 * @param {number=1} scale 
 * @returns {{ width: number }}
 */
const measureTableTitle = (text, scale = 1) => {
    const measurement = getTextMetrics(text, `${TABLE_DEFAULTS.fontSize * scale}px ${FONT_FAMILY}`);
    return measurement;
}

/**
 * Returns the formatted title according to the textbox width, font family, font size etc.
 * @param {string} text 
 * @param {number} width 
 * @param {number} scale 
 * @returns {string}
 */
const getWantedTextFromWidth = (text, width, scale) => {
    let fullText = '';
    for (const letter of text) {
        const measureText = measureTableTitle(fullText + letter, scale);

        if (measureText.width > width) {
            return fullText;
        } else if (measureTableTitle(fullText + letter + '...', scale).width > width) {
            if (!fullText.length) return '';
            return fullText + '...';
        }
        fullText += letter;
    }
    return fullText;
}

/**
 * Returns the height value of the given text. We can use this function to understand the textbox height in advance.
 * @param {string} text 
 * @param {object} options 
 * @returns {number}
 */
const calculateTextHeight = (text, options = {}) => {
    const textbox = new fabric.Textbox(text, {
        ...options,
        padding: 0,
        fontFamily: 'Rubik',
        objectCaching: false,
        hasBorders: false,
        hasControls: false,
        flipX: false,
        flipY: false
    });

    return textbox.height;
}