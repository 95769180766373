import { useEffect, useRef } from 'react'
import { fabric } from 'fabric';
import { checkIfLineIsConnectWithShape } from './UseLine';
import { isActionForTextModification, KEYS, usedControlKeys } from '../helpers/shortcuts/Keys';
import {
    attachToFrame,
    detachFromFrame,
    frameMouseUpHandler,
    generateLinkedShapes,
    getFrameAttachedShapes,
    setAttachedShapesCoords
} from '../helpers/frame/FrameMethods';
import { calculateObjPos, getAdditionalPositionForAttaching } from '../helpers/frame/CalculatePositions';
import generateFrameText from '../helpers/frame/GenerateFrameText';
import { arrangeTextInsideShape, createDuplicateShadowObject, createObjectProperties, createSelectionForObjects, customToObject, isObjectInsideOfObject, isObjectValidForAttachingLines, isTargetIncludeText, isTargetLine, shouldAllowActionForActiveSelection } from '../helpers/FabricMethods';
import { bindDuplicatedLine, clearLineBindings, setLinePointsForCurrentPosition } from '../helpers/lines/LineMethods';
import cloneTargetWithProperties from '../helpers/CloneTargetWithProperties';
import { getTextFromClipboard } from '../helpers/Clipboard';
import {getStackOrderFromCanvas, getSingleObjectStackOrder, setObjectsStackWithZIndex} from '../helpers/StackOrder';
import { createObjectToBeEmitted, getMapKeyByValue, isUserHasAccessToFeature } from '../helpers/CommonFunctions';
import {useDispatch} from 'react-redux';
import { removeAllExistedLasso } from './UseLasso';
import { TEXTBOX_TYPE, EMITTER_TYPES, SOCKET_EVENT, MOVE_DIRECTIONS_FROM_KEY } from '../helpers/Constant';
import { calculateDuplicatedObjPosition } from '../helpers/customControls/connector/ConnectorControlMethods';
import eventEmitter from '../helpers/EventEmitter';
import { compressData, decompressData, uint8ArrayToBase64, base64ToUint8Array } from '../helpers/OptimizationUtils';
import { onTableDrawn } from '../helpers/table/TableMethods';
import { generateTableTitle } from '../helpers/table/TableEventHandlers';
import {objMouseUpHandler} from '../helpers/frame/MoveHandler';
import {DuplicationSorter} from '../helpers/DuplicationHelper';
import {findBiggestZIndexOnHistory} from '../helpers/zIndex/ManageZIndex';
import ObjectStore from '../helpers/ObjectStore';

const useShortCuts = (canvas, mapFieldsToDrawInstance, userId, whiteboardId, uuidGenerator, userAccessRef, activePageId, actionSelected, emitData) => {
    const objectInMemory = useRef(); // this is the object that we will copy and paste
    const dispatch = useDispatch();
    const mousePos = useRef({ x: 0, y: 0 });
    const isPressingAlt = useRef(false);
    //for canvas moving
    const vx = useRef(0);
    const vy = useRef(0);
    //for active object moving
    const oVx = useRef(0);
    const oVy = useRef(0);
    const movementInterval = useRef();
    const movedObject = useRef();
    const movementStopRef = useRef({
        shouldStop: false,
        currentMovingDirection: null
    })
    const speed = 5; // Adjust the speed of movement
    const activeObjectSpeed = 2;
    
    useEffect(() => {
        console.log('render');
        if (canvas) {
            const setShapeToDefaultSettings = (shape) => {
                if (isTargetLine(shape)) {
                    // set the points for the current position
                    setLinePointsForCurrentPosition(shape);
                    // clear bindings. even if its need to be binded, we need new target uuids.
                    clearLineBindings(shape);
                } else if (isObjectValidForAttachingLines(shape)) {
                    shape.lines = [];
                }
            }
            const asyncClone = async (target, options = {}) => {
                const cloned = await new Promise((resolve) => {
                    target.clone(function (cloned) {
                        delete cloned.stackOrder;
                        resolve(cloned);
                    });
                });
                if (cloned.type && cloned.type === 'activeSelection') return;
                if (target.subTargetObject) {
                    target.subTargetObject.clone(clonedObj => {
                        cloned._objects[1] = clonedObj;
                        cloned._objects[1].left = target._objects[1].left;
                        cloned._objects[1].top = target._objects[1].top;
                    });
                }
            
                cloned.left = options.left || cloned.left;
                cloned.top = options.top || cloned.top;
                cloned.shapeType = target.shapeType;
                cloned.uuid = null;
                if (cloned.type === 'frame') {
                    cloned.set({
                        objectCaching: false,
                    })
                    if (!target.flowchartProps) {
                        cloned.set({
                            text: generateFrameText(canvas)
                        });
                    }
                    cloned.attachments = [];
                    cloned.on('mouseup', frameMouseUpHandler);
                } else if (cloned.type === 'table') {
                    onTableDrawn(cloned);
                    cloned.title = generateTableTitle(canvas);
                }

                // if object is not frame or object is an attachment of a frame, then we need to attach it to the frame
                if (target.type !== 'frame' || (target.type === 'frame' && target.attachedFrameId)) {
                    const frames = canvas.getObjects().filter((o) => o.type === 'frame');

                    // If duplicated object is inside of the frame, then attach it to the frame. Otherwise, don't attach it.
                    if (options.wiredFrame && isObjectInsideOfObject(options.wiredFrame, cloned, { manualCheck: true })) {
                        attachToFrame(cloned, options.wiredFrame, { allowAttachingToLockedFrame: true });
                    } else if (frames.some((f) => isObjectInsideOfObject(f, cloned, { manualCheck: true }))) {
                        // If duplicated object is on a frame, then attach it to the frame.
                        const frame = frames.find((f) => isObjectInsideOfObject(f, cloned, { manualCheck: true }));
                        attachToFrame(cloned, frame, { allowAttachingToLockedFrame: true });
                    } else {
                        cloned.calculatedPos = null;
                        cloned.wiredFrame = null;
                        cloned.attachedFrameId = null;
                    }
                }

                cloned.lockScalingFlip = true;
                cloned.isDuplicated = true;

                if (options.duplicatedFromConnector === true) {
                    if (Array.isArray(cloned._objects) && cloned._objects[1]?.type === TEXTBOX_TYPE) {
                        cloned._objects[1].text = '';
                    }
                }

                if (options?.userId) {
                    cloned.modifiedBy = options.userId;
                    cloned.createdBy = options.userId;
                }

                if (isTargetIncludeText(cloned)) {
                    const textObj = cloned.getObjects().find(o => o.type === 'textbox');
                    if (textObj) {
                        textObj.visible = true;
                        textObj.breakWords = true;
                        textObj.splitByGrapheme = false;
                        arrangeTextInsideShape(cloned);
                    }
                }
                if (!options.avoidEmit) {
                    mapFieldsToDrawInstance(canvas, cloned, whiteboardId, userId, { preventAddToUndoStack: true });
                }

                cloned.flowchartProps = null;
                cloned.constantFlowchartProps = null;
                return cloned;
            }
            /**
             * Clones the target object with its instance in canvas and returns the cloned object.
             * @param {fabric.Object} target - The object that will be copied.
             * @param {object} options
             * @param {Map<string, string>=} allDuplicatedObjectsMap
             * @returns
             */
            const cloneTarget = async (target, options = {}, allDuplicatedObjectsMap = null, allDuplicatedInstancesMap = null) => {
                const clonedTarget = await asyncClone(target, options);
                clonedTarget.uuid = uuidGenerator(canvas);
                const sideObjects = [];
                if (target.type === 'frame' && target.linkedShapes) {
                    const duplicatedObjectsMap = new Map();

                    const linkedShapeCopyHandler = async (linkedShape, targetFrame) => {
                        const newPos = calculateObjPos(targetFrame, linkedShape.calculatedPos);
                        const additionalPosition = getAdditionalPositionForAttaching(linkedShape);
                        const frameElement = await asyncClone(linkedShape, {
                            left: newPos.x - additionalPosition.left,
                            top: newPos.y - additionalPosition.top,
                            wiredFrame: targetFrame,
                            avoidEmit: true
                        })
                        frameElement.uuid = uuidGenerator(canvas);
                        setShapeToDefaultSettings(frameElement);
                        targetFrame.attachments.push(frameElement.uuid);

                        if (!duplicatedObjectsMap.has(linkedShape.uuid)) {
                            duplicatedObjectsMap.set(linkedShape.uuid, frameElement.uuid);
                        }
                        // if duplicated object map from all duplicated objects is not null,
                        // add the uuids to the map in order to attach the lines
                        if (allDuplicatedObjectsMap && !allDuplicatedObjectsMap.has(linkedShape.uuid)) {
                            allDuplicatedObjectsMap.set(linkedShape.uuid, frameElement.uuid);
                        }

                        if (allDuplicatedInstancesMap && !allDuplicatedInstancesMap.has(frameElement.uuid)) {
                            allDuplicatedInstancesMap.set(frameElement.uuid, frameElement)
                        }

                        if (frameElement.shapeType === 'curvedLine') {
                            bindDuplicatedLine(canvas, frameElement, linkedShape, duplicatedObjectsMap, allDuplicatedInstancesMap);
                        }
                        return frameElement;
                    }

                    for (const linkedShape of target.linkedShapes) {
                        const clonedFrameElement = await linkedShapeCopyHandler(linkedShape, clonedTarget);
                        sideObjects.push(clonedFrameElement);

                        // handle nested frame copying
                        if (linkedShape.type === 'frame' && linkedShape.linkedShapes) {
                            for (const nestedLinkedShape of linkedShape.linkedShapes) {
                                const clonedNestedFrameElement = await linkedShapeCopyHandler(nestedLinkedShape, clonedFrameElement);
                                sideObjects.push(clonedNestedFrameElement);
                            }
                        }
                    } 
                    canvas.renderAll();
                }
                setShapeToDefaultSettings(clonedTarget);
                return {
                    clonedTarget,
                    sideObjects
                }
            }
            
            const stopMoving = (direction) => {
                if (direction === 'left' || direction === 'right') {
                    vx.current = 0;
                    oVx.current = 0;
                }

                if (direction === 'up' || direction === 'down') {
                    vy.current = 0;
                    oVy.current = 0;
                }

                if (vx.current === 0 && vy.current === 0) {
                    clearInterval(movementInterval.current);
                    movementInterval.current = null;
                }

                if (movedObject.current) {
                    movedObject.current.isMoving = false;
                    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_SHOW)
                    canvas.fire('object:modified', {
                        target: movedObject.current,
                        transform: {
                            target: movedObject.current
                        }
                    })
                    objMouseUpHandler({target: movedObject.current})
                    if (movedObject.current?.type === 'curvedLine') {
                        movedObject.current.organizeControls(true)
                    }
                    movedObject.current = null
                    canvas.renderAll()
                }
            }
            
            const keyDownListener = async (event) => {
                const eventTarget = event.target;
                if (event.key === KEYS.TAB) {
                    event.preventDefault();
                    return;
                }
                if (eventTarget && eventTarget.nodeName !== 'BODY') {
                    // if the event target is not the body, 
                    // we check if the shortcuts stills should be active
                    let shouldActiveShourtcuts = false;

                    if (eventTarget.dataset.hasOwnProperty('fabricHiddentextarea')) {
                        if (isActionForTextModification(event)) {
                            shouldActiveShourtcuts = true;
                        }
                    } else if (eventTarget.dataset.hasOwnProperty('ignoreeventkeys')) {
                        shouldActiveShourtcuts = true;
                    }
                    if (!shouldActiveShourtcuts) return;
                }

                // capitalize the key
                if (
                    'Proxy' in window &&
                    ((event.shiftKey && /^[A-Z]$/.test(event.key)) ||
                    (!event.shiftKey && /^[a-z]$/.test(event.key)))
                ) {
                    event = new Proxy(event, {
                        get(ev, prop) {
                            const value = ev[prop];
                            if (typeof value === 'function') {
                                return value.bind(ev);
                            }
                            return prop === 'key'
                                ? ev.key.toLowerCase()
                                : value;
                        },
                    });
                }
                if(KEYS.L.includes(event.key)) {
                    if (isUserHasAccessToFeature('lasso', userAccessRef.current)) {
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'lasso')
                    }
                }

                const moveCanvas = () => {
                    let newViewportTransform = canvas.viewportTransform.slice();
       
                    newViewportTransform[4] += vx.current;
                    newViewportTransform[5] += vy.current;

                    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_HIDE);
                    eventEmitter.fire(EMITTER_TYPES.DESELECT_COMMENTS);
                    canvas.setViewportTransform(newViewportTransform);
                    canvas.fire('board:pan')
                    canvas.renderAll();
                }
                
                const moveObject = () => {
                    let activeObject = canvas.getActiveObject();
                    let newViewportTransform = canvas.viewportTransform.slice(); 
                    if (!activeObject || activeObject.locked || activeObject.lockMovementX || activeObject.lockMovementY) {
                        return
                    }
                    eventEmitter.fire(EMITTER_TYPES.TOOLBAR_HIDE);
                    
                    // invalidate the tiles with the old dimensions
                    activeObject.onShapeChanged();

                    // change object positions
                    activeObject.set('left', activeObject.left - oVx.current)
                    activeObject.set('top', activeObject.top - oVy.current)
                    activeObject.isMoving = true;

                    // call object moving - we handle the frame attachments, line attachments and comment
                    // attachments with this handler
                    canvas.fire('object:moving', ({target: activeObject, transform: { target: activeObject }}))

                    movedObject.current = activeObject; // store the object so we can call moseUpHandler

                    const activeObjectBounds = activeObject.getBoundingRect(false, true);
                    
                    const objCenterCoords = {
                        x: activeObjectBounds.left + (activeObjectBounds.width / 2),
                        y: activeObjectBounds.top + (activeObjectBounds.height / 2),
                    }

                    // check left overflow
                    if (objCenterCoords.x < 0) {
                        newViewportTransform[4] -= objCenterCoords.x
                    }
                    
                    // check right overflow
                    if (objCenterCoords.x > canvas.width) {
                        newViewportTransform[4] -= objCenterCoords.x - canvas.width
                    }

                    // check top overflow
                    if (objCenterCoords.y < 0) {
                        newViewportTransform[5] -=objCenterCoords.y
                    }

                    // check bottom overflow
                    if (objCenterCoords.y > canvas.height) {
                        newViewportTransform[5] -= objCenterCoords.y - canvas.height
                    }

                    canvas.setViewportTransform(newViewportTransform);
                    canvas.renderAll(); 
                }
                
                const startMoving = (direction) => {
                    const isPanMode = canvas.isPanMode;
                    const activeObject = canvas.getActiveObject();
                    if (isPanMode) {
                        switch (direction) {
                            case 'left':
                                vx.current = speed;
                                break;
                            case 'right':
                                vx.current = -speed;
                                break;
                            case 'up':
                                vy.current = speed;
                                break;
                            case 'down':
                                vy.current = -speed;
                                break;
                            default:
                                break;
                        }
                        if (!movementInterval.current) {
                            movementInterval.current = setInterval(moveCanvas, 16);
                        }
                    } else if (activeObject && (activeObject.uuid || activeObject.type === 'activeSelection') && !movementStopRef.current.shouldStop) {
                        movementStopRef.current = {
                            ...movementStopRef.current,
                            currentMovingDirection: direction
                        }
                        if (direction === 'left') {
                            oVx.current = activeObjectSpeed;
                            oVy.current = 0;
                        }

                        if (direction === 'right') {
                            oVx.current = -activeObjectSpeed;
                            oVy.current = 0;
                        }

                        if (direction === 'up') {
                            oVy.current = activeObjectSpeed;
                            oVx.current = 0;
                        }

                        if (direction === 'down') {
                            oVy.current = -activeObjectSpeed;
                            oVx.current = 0;
                        }
                        moveObject() // do not use any timer for moving the object
                    }
                }

                if (event.key.startsWith('Arrow')) {
                    event.preventDefault();
                    // we use arrows for selecting deleting option for frames
                    // if it's activated, do not move the object
                    const activeDeleteButton = document.querySelector('.delete.deleteBlock.active');
                    if (!activeDeleteButton) {
                        startMoving(MOVE_DIRECTIONS_FROM_KEY[event.key])
                    }
                }

                
                if (event[KEYS.CTRL_OR_CMD]) {
                    if (movementInterval.current) {
                        clearInterval(movementInterval.current)
                    }
                    // override default browser shortcuts
                    if (usedControlKeys.filter(key => key !== 'v' && key !== 'V').includes(event.key)) event.preventDefault();
                    if (event.repeat) return;
                    if (isUserHasAccessToFeature('shortcuts', userAccessRef.current)) {
                        if (KEYS.A.includes(event.key)) {
                            console.log('select all');
                            canvas.discardActiveObject().requestRenderAll();
                            const canvasObjects = canvas.getObjects().filter(obj => obj.uuid);
                            let selection = new fabric.ActiveSelection(canvasObjects, {canvas: canvas, });
                            canvas.setActiveObject(selection);
                            canvas.requestRenderAll();
                        } else if (KEYS.C.includes(event.key)) {
                            const activeObject = canvas.getActiveObject();
                            if (activeObject?.type === 'table' && activeObject.isCellSelected) return;
                            if (activeObject?.type === 'activeSelection' && !shouldAllowActionForActiveSelection(activeObject)) return;
                            if (activeObject && 
                                (activeObject.uuid || 
                                (activeObject.type && activeObject.type === 'activeSelection')) &&
                                !activeObject.isLocked
                            ) {
                                const objectData = createObjectProperties(activeObject);
                                await copyObjectDataToClipboard(objectData);
                            }
                        } else if (KEYS.X.includes(event.key) && isUserHasAccessToFeature('remove_object', userAccessRef.current)) {
                            const activeObject = canvas.getActiveObject();
                            if (activeObject?.type === 'table' && activeObject.isCellSelected) return;
                            const stackOrders = getStackOrderFromCanvas(canvas);
                            if (!activeObject || activeObject.isLocked) return;
                            if (activeObject?.type === 'activeSelection' && !shouldAllowActionForActiveSelection(activeObject)) return;
                            console.log('cut');
                    
                            const affectedObjects = [];
                            const objectData = createObjectProperties(activeObject);
                            const allObjects = canvas.getObjects();
                            await copyObjectDataToClipboard(objectData);
                            canvas.discardActiveObject().requestRenderAll();
                    
                            if (activeObject.type && activeObject.type === 'activeSelection') {
                                const removedLineUuids = [];
                                activeObject._objects.forEach(obj => {
                                    if (Array.isArray(obj.lines) && obj.lines.length > 0) {
                                        const lines = allObjects.filter((o) => obj.lines.includes(o.uuid) && !removedLineUuids.includes(o.uuid) && checkIfLineIsConnectWithShape(o, obj));
                                        
                                        lines.forEach((line) => {
                                            removedLineUuids.push(line.uuid);
                                            affectedObjects.push(line);
                                            canvas.remove(line);
                                        });
                                    }
                                });

                                activeObject._objects.forEach(obj => {
                                    if (removedLineUuids.includes(obj.uuid)) return;

                                    if (obj.type === 'frame') {
                                        generateLinkedShapes(obj, { shouldGenerateForNestedFrames: true });
                                    }
                                    affectedObjects.push(obj);
                                    canvas.remove(obj);
                                });
                            } else if (activeObject && activeObject.uuid) {
                                affectedObjects.push(activeObject);
                                if (activeObject.type === 'frame') {
                                    generateLinkedShapes(activeObject, { shouldGenerateForNestedFrames: true });
                    
                                    const frameObjects = allObjects.filter(o => o.attachedFrameId === activeObject.uuid);
                                    frameObjects.forEach(o => {
                                        if (o) {
                                            affectedObjects.push(o);
                                            // handle deleting frames with cut for nested frames as well
                                            if (o.type === 'frame') {
                                                const nestedFrameObjects = canvas.getObjects().filter(nestedObject => nestedObject.attachedFrameId === o.uuid);
                                                nestedFrameObjects.forEach(nestedFrameObject => {
                                                    if (nestedFrameObject) {
                                                        affectedObjects.push(nestedFrameObject);
                                                        canvas.remove(nestedFrameObject);
                                                    }
                                                });
                                            }
                                            canvas.remove(o);
                                        }
                                    });
                                }
                                
                                if (Array.isArray(activeObject.lines) && activeObject.lines.length > 0) {
                                    const lines = canvas.getObjects().filter((o) => activeObject.lines.includes(o.uuid) && checkIfLineIsConnectWithShape(o , activeObject));
                                    lines.forEach((line) => {
                                        affectedObjects.push(line);
                                        canvas.remove(line);
                                    });
                                }

                                canvas.remove(activeObject);
                            }
                            canvas.fire('remove-object', { activeObject, isOnlyFrame: false, stackOrders: getStackOrderFromCanvas(canvas) });
                            canvas.fire('remove-to-undo-stack', {objects: affectedObjects, stackOrders});
                        } else if (KEYS.V.includes(event.key)) {
                            canvas.discardActiveObject().requestRenderAll();
                            const copyObject = objectInMemory.current;
                            removeAllExistedLasso(canvas);
                            if (!copyObject) return;
                            console.log('paste')
                            console.log(copyObject);
                            if (copyObject.type && copyObject.type === 'activeSelection') {
                                await onDuplicateObject(copyObject, {
                                    duplicateOnPaste: true,
                                    customPosition: {
                                        left: mousePos.current.x - (copyObject.width / 2),
                                        top: mousePos.current.y - (copyObject.height / 2),
                                    }
                                })
                            } else {
                                const clonePosition = {
                                    left: mousePos.current.x,
                                    top: mousePos.current.y,
                                }
                                // since frame's origin x,y is left top, 
                                // we need to calculate the position
                                if (copyObject.type === 'frame') {
                                    clonePosition.left = mousePos.current.x - (copyObject.width / 2);
                                    clonePosition.top = mousePos.current.y - (copyObject.height / 2);
                                } else if (copyObject.type === 'optimizedImage') {
                                    clonePosition.left = mousePos.current.x - (copyObject.width * copyObject.scaleX / 2);
                                    clonePosition.top = mousePos.current.y - (copyObject.width * copyObject.scaleY / 2);
                                }
                                await onDuplicateObject(
                                    copyObject,
                                    {
                                        duplicateOnPaste: true,
                                        customPosition: clonePosition,
                                    }
                                )
                            }
                        } else if (KEYS.D.includes(event.key)) {
                            console.log('duplicate');
                            const target = canvas.getActiveObject();
                            if (target?.type === 'table' && target.isCellSelected) return;
                            if (target?.isLocked) return;
                            if (target?.lockMovementX && target?.lockMovementY) return;
                            if (target?.type === 'activeSelection' && !shouldAllowActionForActiveSelection(target)) return;
                            canvas.discardActiveObject().requestRenderAll();  // required for the clone
                            canvas.fire('duplicate-object', target);
                        } else if (KEYS.Z.includes(event.key)) {
                            canvas.fire('undo-redo', 'undo');
                        } else if (KEYS.Y.includes(event.key)) {
                            canvas.fire('undo-redo', 'redo');
                        } else if (KEYS.B.includes(event.key)) {
                            console.log('bold');
                            canvas.fire('text-bold');
                        } else if (KEYS.U.includes(event.key)) {
                            console.log('underline');
                            canvas.fire('text-underline');
                        } else if (KEYS.I.includes(event.key)) {
                            console.log('italic');
                            canvas.fire('text-italic');
                        }
                    }

                    if (KEYS.S.includes(event.key)) {
                        canvas.fire('close-comment-drawer');
                        eventEmitter.fire(EMITTER_TYPES.TOGGLE_SHORTCUT_LIST, true);
                    }

                    if (event.key === '1') {
                        eventEmitter.fire(EMITTER_TYPES.FIT_TO_SCREEN);
                    }

                    if (KEYS.F.includes(event.key)){
                        event.preventDefault();
                        eventEmitter.fire(EMITTER_TYPES.OPEN_SEARCH_TEXT);
                    }
                } else if (KEYS.C.includes(event.key)) {
                    canvas.fire('toggle-comment-drawer');
                } else if (KEYS.M.includes(event.key)) {
                    canvas.fire('toggle-minimap-visibility');
                } else if (isUserHasAccessToFeature('shortcuts', userAccessRef.current)) {
                    let shortcutPerformed = false;
                    if (KEYS.N.includes(event.key)) {
                        canvas.fire('close-comment-drawer');
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'Sticky');
                        shortcutPerformed = true;
                    } else if (KEYS.E.includes(event.key)) {
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'Eraser');
                        shortcutPerformed = true;
                    } else if (KEYS.H.includes(event.key)) {
                        canvas.fire('close-comment-drawer');
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'pan');
                        shortcutPerformed = true;
                    } else if (KEYS.V.includes(event.key)) {
                        canvas.fire('close-comment-drawer');
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'select');
                        shortcutPerformed = true;
                    } else if (KEYS.P.includes(event.key)) {
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'Pen');
                        shortcutPerformed = true;
                    } else if (KEYS.R.includes(event.key)) {
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'Rectangle');
                        shortcutPerformed = true;
                    } else if (KEYS.T.includes(event.key)) {
                        canvas.fire('close-comment-drawer');
                        eventEmitter.fire(EMITTER_TYPES.CHANGE_ACTION_MODE, 'Text');
                        shortcutPerformed = true;
                    } else if ((event.key === 'Backspace' || event.key === 'Delete') && isUserHasAccessToFeature('remove_object', userAccessRef.current)) {
                        const activeObject = canvas.getActiveObject();
                        if (activeObject?.type === 'activeSelection' && !shouldAllowActionForActiveSelection(activeObject)) return;
                        const deleteButton = document.querySelector('.delete.deleteBlock');
                        if (!activeObject || (activeObject.isLocked && activeObject.lockMovementX && activeObject.lockMovementY)) return;
                        if (activeObject?.type === 'table' && activeObject.isCellSelected) return;
                        if (activeObject?.type === 'frame' && activeObject.attachments.length > 0 && deleteButton) {
                            document.querySelector('.delete.deleteBlock').classList.add('active');
                        } else {
                            canvas.fire('remove-object', { activeObject });
                            shortcutPerformed = true;
                        }
                    } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Tab' || event.key === 'Enter') {
                        const activeDeleteButton = document.querySelector('.delete.deleteBlock.active');
                        const activeDeleteOption = document.querySelector('.deleteOptionsInner__item.active')
                        if ((event.key === 'ArrowDown' || event.key === 'ArrowUp') && activeDeleteButton) {
                            const deleteOptions = document.querySelectorAll('.deleteOptionsInner__item');
                            let activeDeleteOptionIndex;
                            deleteOptions.forEach((item, index) => {
                                if (item.className.includes('active')) {
                                    activeDeleteOptionIndex = index;
                                    item.classList.remove('active');
                                }
                            });
                            deleteOptions[activeDeleteOptionIndex === 0 ? 1 : 0].classList.add('active');
                        }
                        else if ((event.key === 'Enter' || event.key === 'Tab') && activeDeleteButton) {
                            const activeObject = canvas.getActiveObject();
                            const activeDeleteOptionText = activeDeleteOption.innerHTML;
                            if (activeDeleteOptionText === 'Delete frame with objects' && activeDeleteButton) {
                                activeObject.set({ deleteOnlyMainFrame: false })
                                canvas.fire('remove-object', { activeObject, isOnlyFrame: false, stackOrders: getStackOrderFromCanvas(canvas) });
                            }
                            else {
                                activeObject.set({ deleteOnlyMainFrame: true })
                                canvas.fire('remove-object', { activeObject, isOnlyFrame: true, stackOrders: getStackOrderFromCanvas(canvas) });
                            }
                        }
                    } else if (event.key === 'Alt') {
                        isPressingAlt.current = true;
                        shortcutPerformed = true;
                    } else if (event.code === 'Space') {
                        // for avoiding the emitting toggle pan mode more than once,
                        // we need to check if repeat is false
                        if (!event.repeat) {
                            canvas.fire('toggle-pan-mode', {shouldActivate: true});
                        }
                        shortcutPerformed = true;
                    }
                    
                    // all above shortcuts deactivates the pan mode if it is activated,
                    // so we need to check if the user was moving the canvas with shortcut
                    // if so, we need to stop it
                    if (shortcutPerformed && movementInterval.current) {
                        clearInterval(movementInterval.current)
                    }
                }

                // avoid the duplicating object while moving them on alt tab pressed 
                if (event.altKey && event.key !== 'Alt') {
                    isPressingAlt.current = false;
                }
            }
            const keyUpListener = (event) => {
                if (movementInterval.current || movedObject.current) {
                    stopMoving(MOVE_DIRECTIONS_FROM_KEY[event.key])
                }
                
                if (event.key.startsWith('Arrow')) {
                    movementStopRef.current = {
                        ...movementStopRef.current,
                        shouldStop: false
                    }
                }

                // if alt key is key up, we need to reset the isPressingAlt
                if (isPressingAlt.current && event.key === 'Alt') {
                    isPressingAlt.current = false;
                }

                if (event.target && event.target.nodeName !== 'BODY') return;
                if (event.code === 'Space') {  // if space key is key up, we need to rechange the select mode
                    canvas.fire('toggle-pan-mode', {shouldActivate: false});
                }
            }
            
            // move handler for the shortcuts
            // this saves the mouse for pasting
            // do not change the name of this function. 
            // because it is excluded on removing event listeners for mouse:move
            const shortcutMouseMoveHandler = (event) => {
                mousePos.current = canvas.getPointer(event.e);
                canvas.mousePosition = mousePos.current;
            }

            const activateShortcutsMouseMoveHandler = () => {
                let isListenerAdded = false;
                const mouseMoveListeners = canvas.__eventListeners['mouse:move'];
                for (const mouseMoveListener of mouseMoveListeners) {
                    if (mouseMoveListener.name === 'shortcutMouseMoveHandler') {
                        isListenerAdded = true;
                        break;
                    }
                }
                if (!isListenerAdded) {
                    canvas.on('mouse:move', shortcutMouseMoveHandler);
                }
            }

            const onDuplicateObject = async (target, duplicateOptions = {}) => {
                ObjectStore.initFromCanvas(canvas);
                const duplicatedObjects = [];
                const duplicatedObjectsMap = new Map();
                const duplicatedInstancesMap = new Map();
                const stackOrders = getStackOrderFromCanvas(canvas);
                const duplicatedObjectStackOrders = {};
                const duplicationSorter = new DuplicationSorter()

                if (target.type && target.type === 'activeSelection') {
                    const objectWrapperPosition = {
                        left: target.left + target.width + 10,
                        top: target.top,
                    }
                    // if we copy the objects with alt key or paste, we need to set the position of the objects
                    if (duplicateOptions.duplicateOnMouseUp) {
                        objectWrapperPosition.left = duplicateOptions.customPosition.left;
                        objectWrapperPosition.top = duplicateOptions.customPosition.top;
                    } else if (duplicateOptions.duplicateOnPaste) {
                        objectWrapperPosition.left = duplicateOptions.customPosition.left;
                        objectWrapperPosition.top = duplicateOptions.customPosition.top;
                    }
                    const objectWrapper = new fabric.Rect({
                        ...objectWrapperPosition,
                        width: target.getScaledWidth(),
                        height: target.getScaledHeight(),
                    });

                    const targetObjects = target.getObjects();
                    
                    targetObjects.forEach(obj => {
                        duplicationSorter.addToList(obj)
                        if (obj.type === 'frame') {
                            const frameObjects = getFrameAttachedShapes(obj, true)
                            frameObjects.forEach(frameObj => {
                                duplicationSorter.addToList(frameObj)
                            })
                        }
                    })
                    // sort objects
                    targetObjects.sort((a) => a.shapeType === 'curvedLine' ? 1 : -1);
                    for (const obj of targetObjects) {
                        let copyThis = true;
                        let customOptions = {};

                        const objInCanvas = canvas.getObjects().find(o => o.uuid === obj.uuid);
                        if (objInCanvas) {
                            if (objInCanvas.type === 'frame') {
                                generateLinkedShapes(objInCanvas);
                            }
                            if (objInCanvas.attachedFrameId) {
                                // if the attached frame is in the selection, do not copy this object
                                // since it will be copied with the frame
                                if (targetObjects.find(obj => obj.uuid === objInCanvas.attachedFrameId)) {
                                    copyThis = false;
                                } else {
                                    customOptions.wiredFrame = objInCanvas.wiredFrame;
                                }
                            }
                            const thisAttachedPoints = {
                                x: target.left - obj.left,
                                y: target.top - obj.top
                            }
                            const newPoints = {
                                left: objectWrapper.left - thisAttachedPoints.x,
                                top: objectWrapper.top - thisAttachedPoints.y
                            } 
                            if (duplicateOptions.duplicateOnMouseUp) {
                                newPoints.left = objectWrapper.left + obj.left
                                newPoints.top = objectWrapper.top + obj.top;
                            }
                            if (copyThis) {
                                const options = {
                                    ...newPoints,
                                    ...customOptions,
                                    avoidEmit: true,
                                    userId
                                }
                                const cloneResponse = await cloneTarget(objInCanvas, options, duplicatedObjectsMap, duplicatedInstancesMap);
                                const clonedTarget = cloneResponse.clonedTarget;
                                if (!duplicatedObjectsMap.has(obj.uuid)) {
                                    duplicatedObjectsMap.set(obj.uuid, clonedTarget.uuid);
                                }
                                if (!duplicatedInstancesMap?.has(clonedTarget.uuid)) {
                                    duplicatedInstancesMap.set(clonedTarget.uuid, clonedTarget)
                                }

                                if (clonedTarget.shapeType === 'curvedLine') {
                                    bindDuplicatedLine(canvas, clonedTarget, obj, duplicatedObjectsMap, duplicatedInstancesMap);

                                    clonedTarget.calculateBoundingBoxForCurvedLine();
                                }

                                if (clonedTarget.type !== 'frame') {
                                    const objStackOrder = getSingleObjectStackOrder(canvas, objInCanvas.uuid, stackOrders);
                                    if (objStackOrder > -1) {
                                        duplicatedObjectStackOrders[clonedTarget.uuid] = { order: objStackOrder, item: clonedTarget };
                                    } 
                                } else if (cloneResponse.sideObjects.length > 0) {
                                    for (const sideObj of cloneResponse.sideObjects) {
                                        const originalSideObjUuid = getMapKeyByValue(duplicatedObjectsMap, sideObj.uuid);
                                        const objStackOrder = getSingleObjectStackOrder(canvas, originalSideObjUuid, stackOrders);

                                        if (objStackOrder > -1) {
                                            duplicatedObjectStackOrders[sideObj.uuid] = { order: objStackOrder, item: sideObj };
                                        }
                                    }
                                }

                                duplicatedObjects.push(cloneResponse);
                            }
                        }
                    }
                    const emitObjects = [];
                    for (const duplicatedObj of duplicatedObjects) {
                        emitObjects.push(duplicatedObj.clonedTarget);
                        emitObjects.push(...duplicatedObj.sideObjects);
                    }

                    duplicationSorter.sortObjects(emitObjects, duplicatedObjectsMap)
                    
                    emitObjects.forEach(obj => {
                        if (obj.shapeType === 'frame') obj.text = generateFrameText(canvas);
                        obj.zIndex = undefined;
                        canvas.add(obj)
                    })
                    

                    Object.entries(duplicatedObjectStackOrders)
                        .filter(([, { item }]) => item.type !== 'frame')
                        .sort(([,a], [,b]) => a.order - b.order)
                        .forEach(([, { item }]) => {
                            item.bringToFront();
                        });


                    if (duplicateOptions.dontEmit !== true) {
                        canvas.fire('history-emit-data', {
                            objects: emitObjects,
                            action: 'created'
                        });
                    }
                } else if (target.uuid) {
                    duplicationSorter.addToList(target);
                    if (target.type === 'frame') {
                        const frameObjects = getFrameAttachedShapes(target, true)
                        frameObjects?.forEach(frameObj => {
                            duplicationSorter.addToList(frameObj);
                        }) 
                    }
                    const options = {
                        avoidEmit: true,
                        duplicatedFromConnector: duplicateOptions.duplicatedFromConnector,
                        left: target.left + target.getScaledWidth() + 10,
                        top: target.top,
                        userId
                    }

                    if (duplicateOptions.duplicatedFromConnector === true) {
                        const calculatedPosition = calculateDuplicatedObjPosition(target, duplicateOptions.actualCorner, duplicateOptions.corner);
                        options.left = calculatedPosition.x;
                        options.top = calculatedPosition.y;
                    }

                    if (target.type === 'frame') {
                        generateLinkedShapes(target, { shouldGenerateForNestedFrames: true});
                    }
                    if (duplicateOptions.duplicateOnMouseUp || duplicateOptions.duplicateOnPaste) {
                        options.left = duplicateOptions.customPosition.left;
                        options.top = duplicateOptions.customPosition.top;
                    }

                    if (target.wiredFrame) {
                        options.wiredFrame = target.wiredFrame;
                    }

                    const cloneResponse = await cloneTarget(target, options, duplicatedObjectsMap, duplicatedInstancesMap);
                    const emitObjects = [cloneResponse.clonedTarget, ...cloneResponse.sideObjects];
                    
                    duplicationSorter.sortObjects(emitObjects, duplicatedObjectsMap)
                    
                    for (const object of emitObjects) {
                        if(object.shapeType === 'frame') object.text = generateFrameText(canvas);
                        object.zIndex = findBiggestZIndexOnHistory(canvas, object.shapeType);
                        canvas.add(object);
                    }

                    if (cloneResponse.sideObjects.length > 0 && Array.isArray(target.linkedShapes)) {
                        const duplicatedObjectsMapValues = [...duplicatedObjectsMap.values()];
                        const duplicatedObjectsMapKeys = [...duplicatedObjectsMap.keys()];

                        for (const [index, sideObj] of cloneResponse.sideObjects.entries()) {
                            const sideObjUUIDIndex = duplicatedObjectsMapValues.findIndex(v => v === sideObj.uuid);
                            const originalSideObjUUID = sideObjUUIDIndex > -1 
                                ? duplicatedObjectsMapKeys[sideObjUUIDIndex]
                                : target.linkedShapes[index]?.uuid;
                            const objStackOrder = getSingleObjectStackOrder(canvas, originalSideObjUUID, stackOrders);

                            if (objStackOrder > -1) {
                                if (sideObj.type !== 'frame') {
                                    duplicatedObjectStackOrders[sideObj.uuid] = { order: objStackOrder, item: sideObj };
                                }
                            }
                        }
                    }

                    if (duplicateOptions.duplicatedFromConnector === true) {
                        target.clonedObject = cloneResponse.clonedTarget;
                    }

                    Object.entries(duplicatedObjectStackOrders)
                        .sort(([,a], [,b]) => a.order - b.order)
                        .forEach(([, { item }]) => {
                            item.bringToFront();
                        });

                    if (duplicateOptions.dontEmit !== true) {
                        canvas.fire('history-emit-data', {
                            objects: emitObjects,
                            action: 'created'
                        })
                    }

                    duplicatedObjects.push(cloneResponse);
                }

                if (!duplicatedObjects.length) return;

                const actualDuplicatedObjects = duplicatedObjects.map(obj => obj.clonedTarget);
                if (duplicateOptions.dontAddToHistoryStack !== true) {
                    canvas.fire('add-to-undo-stack', { objects: actualDuplicatedObjects });
                }

                // We need to update global state history with the duplicated objects for edit permissions users.
                // Thus, the duplicated objects can be finded in undo operation.
                if (!isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                    actualDuplicatedObjects.forEach((o) => {
                        const objs = [o];

                        // If the object is frame, then we need to add the frame objects to the history as well.
                        if (o.type === 'frame' && Array.isArray(o.attachments) && o.attachments.length > 0) {
                            const frameObjects = canvas.getObjects().filter((obj) => o.attachments.includes(obj.uuid));
                            objs.push(...frameObjects);
                        }

                        for (const objItem of objs) {
                            const obj = createObjectToBeEmitted(whiteboardId, userId, customToObject(objItem), false, objItem.shapeType);
                            obj.actionTaken = 'created';
                            obj.modifiedBy = userId;
    
                            try {
                                dispatch({ type: 'history/addShapeToHistory', payload: { shape: structuredClone(obj), pageId: activePageId } });
                            } catch (err) {
                                dispatch({ type: 'history/addShapeToHistory', payload: { shape: obj, pageId: activePageId } });
                            }
                        }
                    });
                }

                createSelectionForObjects(canvas, actualDuplicatedObjects);
                setObjectsStackWithZIndex(canvas);
                ObjectStore.clear();
                return true;
            }

            const onDuplicateObjectListener = async (args) => {
                if (args && args.withOptions === true) {
                    await onDuplicateObject(args.target, args.options ?? {});
                    if (typeof args.options?.onDuplicate === 'function') {
                        args.options.onDuplicate();
                    }
                } else {
                    await onDuplicateObject(args);
                }
            }

            const objectMovingForDuplicateHandler = (event) => {
                const target = event.target;
                if (isPressingAlt.current) {
                    // if alt key is pressed, we need to duplicate the object
                    if (!target.duplicateShadowObj) {
                        createDuplicateShadowObject(canvas, target);
                    }
                    const duplicateShadowObj = target.duplicateShadowObj;
                    if (duplicateShadowObj) {

                        // instead of moving the object, we need to move the shadow object
                        duplicateShadowObj.set({
                            left: event.pointer.x,
                            top: event.pointer.y,
                        });
                    }

                    // since we are duplicating the object, we need to set object coords
                    // to the object position before moving
                    target.set({
                        left: event.transform.original.left,
                        top: event.transform.original.top,
                    });
                    if (target.type === 'frame') {
                        setAttachedShapesCoords(target);
                    }
                } else if (target.duplicateShadowObj) {
                    if (target.type === 'frame') {
                        setAttachedShapesCoords(target);
                    }
                    canvas.remove(target.duplicateShadowObj);
                    target.duplicateShadowObj = null;
                }
                target.off('mouseup', duplicatingMouseUpHandler);
                target.on('mouseup', duplicatingMouseUpHandler);
            }

            const duplicatingMouseUpHandler = async (event) => {
                const { target } = event;
                // if the target has duplicateShadowObj, we need to duplicate the object
                if (target.duplicateShadowObj) {
                    const duplicateShadowObj = target.duplicateShadowObj;
                    if (target.type !== 'activeSelection') {
                        canvas.remove(duplicateShadowObj);
                       
                        await onDuplicateObject(
                            target, 
                            {
                                duplicateOnMouseUp: true,
                                customPosition: {
                                    left: duplicateShadowObj.left,
                                    top: duplicateShadowObj.top,
                                }
                            }
                        )
                        target.duplicateShadowObj = null;
                    } else {
                        canvas.remove(duplicateShadowObj);
                        await onDuplicateObject(target, {
                            duplicateOnMouseUp: true,
                            customPosition: {
                                left: duplicateShadowObj.left,
                                top: duplicateShadowObj.top,
                            }
                        })
                    }
                }
            }

            const onRemoveObject = (value) => {
                const { activeObject : target, isOnlyFrame, stackOrders } = value;
                const affectedObjects = [];
                const removedObjects = [];
                const detachedObjects = [];

                if (target.type && target.type === 'activeSelection') {
                    const selectedObjects = target.getObjects();  
                    const allObjects = canvas.getObjects();
                    canvas.discardActiveObject().requestRenderAll();

                    const removedLineUuids = [];

                    // Remove the connector lines first.
                    selectedObjects.forEach((obj) => {
                        if (Array.isArray(obj.lines) && obj.lines.length > 0) {
                            const lines = allObjects.filter((o) => obj.lines.includes(o.uuid) && !removedLineUuids.includes(o.uuid) && checkIfLineIsConnectWithShape(o,obj));

                            lines.forEach((line) => {
                                removedLineUuids.push(line.uuid);
                                affectedObjects.push(line);
                                removedObjects.push(line);
                                canvas.remove(line); 
                            });
                        }
                    })  

                    selectedObjects.forEach(obj => {
                        if (obj.uuid && !removedLineUuids.includes(obj.uuid)) {
                            affectedObjects.push(obj);
                            removedObjects.push(obj);
                            canvas.remove(obj); 
                        }
                    });
                }
                if (target && target.uuid) {
                    affectedObjects.push(target);
                    removedObjects.push(target);
                    if (target.type === 'frame') {
                        const frame = target;
                        const frameObjects = canvas.getObjects().filter(o => o.attachedFrameId === frame.uuid);
                        affectedObjects[affectedObjects.length - 1].thisAttachments = frameObjects;

                        if (!isOnlyFrame) {
                            frameObjects.forEach(attachedObject => {
                                if (attachedObject) {
                                    canvas.remove(attachedObject);
                                    removedObjects.push(attachedObject);

                                    // handle nested frames deleting
                                    if (attachedObject.type === 'frame') {
                                        const frameObjects = canvas.getObjects().filter(o => o.attachedFrameId === attachedObject.uuid);
                                        affectedObjects[affectedObjects.length - 1].thisAttachments.push(...frameObjects);
                                        frameObjects.forEach(nestedAttachedObject => {
                                            if (nestedAttachedObject) {
                                                canvas.remove(nestedAttachedObject);
                                                removedObjects.push(nestedAttachedObject);
                                            }
                                        });
                                    }
                                }
                            });
                        } else {
                            frameObjects.forEach(attachedObject => {
                                if (attachedObject) {
                                    detachFromFrame(attachedObject);
                                    detachedObjects.push(attachedObject);
                                }
                            })
                            target.set({ stackOrders })
                            affectedObjects[0].set({ formerAttachments: removedObjects, stackOrders })
                            removedObjects[0].set({ formerAttachments: removedObjects, stackOrders })
                        }
                        canvas.remove(frame);
                    } else if (Array.isArray(target.lines) && target.lines.length > 0) {
                        const lines = canvas.getObjects().filter((o) => target.lines.includes(o.uuid) && checkIfLineIsConnectWithShape(o,target));
                        lines.forEach((line) => {
                            affectedObjects.push(line);
                            removedObjects.push(line);
                            canvas.remove(line);
                        });
                        canvas.remove(target);
                    } else {
                        canvas.remove(target);
                    }
                }

                canvas.fire('history-emit-data', {
                    objects: removedObjects,
                    action: 'deleted'
                })

                // need modified action here when removing only frame. Need to deattach sub objects  first-step
                canvas.fire('remove-to-undo-stack', {objects: affectedObjects, stackOrders});
            }

            const blurListener = () => {
                isPressingAlt.current = false;
            }

            const cloneWithProps = async (canvas, props, position, clonedObjectsMap = null) => {
                const clonedObject = await cloneTargetWithProperties(props, canvas, { ...position, userId, avoidAdding: true });
                clonedObject.uuid = uuidGenerator(canvas);
                if (clonedObjectsMap) {
                    clonedObjectsMap.set(props.uuid, clonedObject.uuid);
                }
                const sideObjects = [];
                if (clonedObject.shapeType === 'frame' && props.linkedShapes?.length) {

                    // handle duplicating single linked shape
                    const handleLinkedShapeCloning = async (linkedShape, targetFrame) => {
                        const newPos = calculateObjPos(targetFrame, linkedShape.calculatedPos);
                        const additionalPosAccordingToFrame = getAdditionalPositionForAttaching(linkedShape);
                        newPos.x += additionalPosAccordingToFrame.left;
                        newPos.y += additionalPosAccordingToFrame.top;

                        // try to align frames correctly since we don't consider frame text
                        // while calculating the attached position
                        try {
                            if (linkedShape.type === 'frame') {
                                const frameText = linkedShape.objects[1];
                                if (frameText.visible) {
                                    newPos.y -= linkedShape.objects[1].height / 2 + 2;
                                }
                            }
                        } catch (err) {
                            console.error('Error happended during aligning frames', err);
                        }

                        const clonedLinkedShape = await cloneTargetWithProperties(linkedShape, canvas, { ...newPos, avoidAdding: true }, true);
                        clonedLinkedShape.attachedFrameId = targetFrame.uuid;
                        attachToFrame(clonedLinkedShape, targetFrame, { allowAttachingToLockedFrame: true });
                        clonedLinkedShape.uuid = uuidGenerator(canvas);

                        if (clonedObjectsMap) {
                            clonedObjectsMap.set(linkedShape.uuid, clonedLinkedShape.uuid);
                        }
                        return clonedLinkedShape;
                    }

                    const linkedShapes = props.linkedShapes;
                    for (const linkedShape of linkedShapes) {
                        const clonedLinkedShape = await handleLinkedShapeCloning(linkedShape, clonedObject);
                        sideObjects.push(clonedLinkedShape);

                        // duplicate nested frame objects if exists
                        if (linkedShape.shapeType === 'frame' && Array.isArray(linkedShape.linkedShapes)) {
                            for (const nestedLinkedShape of linkedShape.linkedShapes) {
                                const clonedNestedLinkedShape = await handleLinkedShapeCloning(nestedLinkedShape, clonedLinkedShape);
                                sideObjects.push(clonedNestedLinkedShape);
                            }
                        }
                    }
                }

                return {
                    clonedObject,
                    sideObjects
                }
            }

            const getAdditionalPositions = (obj) => {
                const additionalPosAccordingToOrigin = {
                    x: 0,
                    y: 0
                }
                if (obj.originX === 'right') {
                    additionalPosAccordingToOrigin.x = -(obj.width * obj.scaleX / 2);
                }
                if (obj.originY === 'bottom') {
                    additionalPosAccordingToOrigin.y = -(obj.height * obj.scaleY / 2);
                }
                if (obj.originX === 'left') {
                    additionalPosAccordingToOrigin.x = obj.width * obj.scaleX / 2;
                }
                if (obj.originY === 'top') {
                    additionalPosAccordingToOrigin.y = obj.height * obj.scaleY / 2;
                }
                return additionalPosAccordingToOrigin;
            }

            /**
             * Attaches the lines after copying the object.
             * @param {fabric.Object} clonedObject - The copied object that will be attached to the lines.
             * @param {Map<string,string>} clonedObjectsMap - The map that contains the original and new uuids for the copied objects.
             * @param {fabric.Canvas} canvas
             */
            const attachLinesAfterCopying = (clonedObject, clonedObjectsMap, canvas) => {
                ['leftPolygon', 'rightPolygon'].forEach(polygon => {
                    try {
                        if (clonedObject[polygon] && clonedObjectsMap.has(clonedObject[polygon].uuid)) {
                            const polygonInCanvas = canvas.getObjects().find(obj => obj.uuid === clonedObjectsMap.get(clonedObject[polygon].uuid));
                            clonedObject[polygon] = polygonInCanvas;
                            polygonInCanvas.lines = [...polygonInCanvas.lines, clonedObject.uuid];
                        }
                    } catch (err) {
                        console.error('Error while attaching lines after copying', err);
                    }
                });
            }
            /**
             * Listens the paste event for duplicating the objects in the clipboard.
             * @param {ClipboardEvent} e 
             * @returns 
             */
            const pasteListener = async (e) => {
                if (e.target) {
                    // if the target is input or textarea, do not copy the object in clipboard
                    if ((e.target?.localName === 'input' && e.target?.id !== 'text-copy-input') || e.target?.localName === 'textarea') {
                        return;
                    }
                }

                if (!isUserHasAccessToFeature('copy_paste', userAccessRef.current)) {
                    return;
                }

                // if the clipboard has files, do not copy the object in memory because we are using the clipboard to copy the image
                const clipboardItems = e.clipboardData?.files;
                if (clipboardItems && clipboardItems.length) {
                    
                    return ;
                }
                // try catch read statement because it is not supported in all browsers
                const data = await getTextFromClipboard(e, 'html');
                const pattern = /(\(apeiros-data\))((?:[A-Za-z0-9+/=])*)(\(\/apeiros-data\))/;
                const matches = data.match(pattern);

                if (!matches || matches.length < 3) return;
                const base64text = matches[2];
                const compressedData = base64ToUint8Array(base64text);  // convert base64 to uint8array
                const parsedObject = decompressData(compressedData);  // decompress the uint8array to object

                // start cloning the object with the properties
                const clonedObjects = [];
                // for fixing line attaching after copying, we need to store the original and new uuids
                const clonedObjectsMap = new Map(); 
                
                const duplicationSorter = new DuplicationSorter()
                
                const addObjectsToSorter = (objects) => {
                    if (!Array.isArray(objects)) {
                        return
                    }
                    for (const object of objects) {
                        if (object.type === 'activeSelection') {
                            addObjectsToSorter(object.objects)
                            continue
                        }
                        
                        duplicationSorter.addToList(object)
                        
                        if (object.type === 'frame' && object?.linkedShapes) {
                            addObjectsToSorter(object?.linkedShapes)
                        }
                    }
                }
                
                addObjectsToSorter([parsedObject])
                
                if (parsedObject && parsedObject.type === 'activeSelection') {
                    // if the object is a selection, we need to clone each object
                    // for that, we need to get proper position for each object
                    
                    // objectWrapper acts like a group for the cloning multiple objects
                    const objectWrapper = new fabric.Rect({
                        left: mousePos.current.x - (parsedObject.width / 2),
                        top: mousePos.current.y - (parsedObject.height / 2),
                        width: parsedObject.width,
                        height: parsedObject.height,
                    });
                    
                    const parsedObjectObjects = parsedObject.objects;
                    // sort objects
                    parsedObjectObjects.sort((a) => a.shapeType === 'curvedLine' ? 1 : -1);
                    for (const obj of parsedObjectObjects) {
                        // If frame is not cloned, copy the attached frame.
                        if (!obj.attachedFrameId || (obj.attachedFrameId && !parsedObjectObjects.some((o) => o.type === 'frame' && o.uuid === obj.attachedFrameId))) {
                            const additionalPosAccordingToOrigin = getAdditionalPositions(obj);
                            const newPoints = {
                                x: objectWrapper.getCenterPoint().x + obj.left + additionalPosAccordingToOrigin.x,
                                y: objectWrapper.getCenterPoint().y + obj.top + additionalPosAccordingToOrigin.y
                            }
                            const cloneResponse = await cloneWithProps(
                                canvas, 
                                obj, 
                                {...newPoints}, 
                                clonedObjectsMap
                            );
                            clonedObjects.push(cloneResponse.clonedObject, ...cloneResponse.sideObjects);
                        }
                    }
                } else {
                    const cloneResponse = await cloneWithProps(
                        canvas, 
                        parsedObject, 
                        { ...mousePos.current }, 
                        clonedObjectsMap
                    );
                    clonedObjects.push(cloneResponse.clonedObject, ...cloneResponse.sideObjects);
                }
                const sortedCloneObjects = [...clonedObjects]
                let modifiedFrames = [];
                duplicationSorter.sortObjects(sortedCloneObjects, clonedObjectsMap)
                for (const object of sortedCloneObjects) {
                    object.zIndex = findBiggestZIndexOnHistory(canvas, object.shapeType);
                    if (object.shapeType === 'frame') object.text = generateFrameText(canvas);
                    if (object.attachedFrameId) {
                        const frame = canvas.getObjects().find(item => item.uuid === object.attachedFrameId);
                        frame.attachments = [...frame.attachments, object.uuid];

                        const frameData = createObjectToBeEmitted(
                            whiteboardId,
                            userId,
                            customToObject(frame),
                            false,
                            frame.shapeType
                        );
                        frameData.actionTaken = 'modified';
                        frameData.modifiedBy = userId;
                        modifiedFrames.push(frameData);
                    }
                    canvas.add(object)
                }
                // We need to update global state history with newly created objects for edit permissions users.
                // Thus, the duplicated objects can be finded in undo operation.
                if (!isUserHasAccessToFeature('history_create_or_delete', userAccessRef.current)) {
                    clonedObjects.forEach((o) => {
                        const obj = createObjectToBeEmitted(whiteboardId, userId, customToObject(o), false, o.shapeType);
                        obj.actionTaken = 'created';
                        obj.modifiedBy = userId;

                        try {
                            dispatch({ type: 'history/addShapeToHistory', payload: { shape: structuredClone(obj), pageId: activePageId }});
                        } catch (err) {
                            dispatch({ type: 'history/addShapeToHistory', payload: { shape: obj, pageId: activePageId } });
                        }
                    });
                    return;
                }
                // fix line attaching
                const firstObject = clonedObjects[0];
                let attachments = [];
                for (const clonedObject of clonedObjects) {
                    //need to attach every sub object to it's frame
                    if(clonedObject.attachedFrameId === firstObject.uuid){
                        attachments.push(clonedObject.uuid);
                    }
                    
                    if (clonedObject.shapeType !== 'curvedLine')
                        continue;
                    
                    // set new line points for the cloned object
                    setLinePointsForCurrentPosition(clonedObject);

                    // find the left and right polygons and set the lines
                    attachLinesAfterCopying(clonedObject, clonedObjectsMap, canvas);

                    clonedObject.calculateBoundingBoxForCurvedLine();
                }
                clonedObjects[0].attachments = attachments;
                if (clonedObjects.length) {
                    createSelectionForObjects(canvas, (parsedObject.type !== 'activeSelection' && parsedObject.type === 'frame') ? [clonedObjects[0]] : clonedObjects);
                }
                // modify part
                emitData(modifiedFrames, SOCKET_EVENT.MODIFIED);

                // add the objects to the undo stack
                canvas.fire('add-to-undo-stack', {objects: clonedObjects, useCalculatedPosition: true });

                // emit the data
                canvas.fire('history-emit-data', {
                    objects: clonedObjects,
                    action: 'created',
                    customToObjectOptions: {
                        useCalculatedPosition: true
                    }
                })
                
                setObjectsStackWithZIndex(canvas);
            }

            // copies the object data to clipboard
            const copyObjectDataToClipboard = async (objData) => {
                // compress object data
                const compressedData = compressData(objData)
                if (!compressData) {
                    console.error('Error while compressing data, compress was not successful');
                    return false;
                }

                // convert compressed object data to string in order to copy to clipboard 
                const compressedBase64Text = uint8ArrayToBase64(compressedData);
                try {
                    const copyingData = `<span data-apeiros="<!--(apeiros-data)${compressedBase64Text}(/apeiros-data)!-->"></span>`;
                    const copyTextInput = document.getElementById('text-copy-input');
                    copyTextInput.value = copyingData;
                    copyTextInput.focus();
                    copyTextInput.select();

                    let copied = false;
                    try {
                        document.execCommand('copy');
                        copied = true;
                    } catch (err) {
                        console.error('error while copying', err);
                    }
                    
                    copyTextInput.value = '';
                    copyTextInput.blur();
                    return copied
                } catch (err) {
                    console.error(err.name, err.message);
                }
                return false;
            }

            /**
             * Listens copy event to save the object data as html to clipboard.
             * @param {ClipboardEvent} event 
             */
            const copyListener = (event) => {
                const copyTextInput = document.getElementById('text-copy-input');
                if (!copyTextInput || copyTextInput.value.length === 0) return;
                event.clipboardData.setData('text/html', copyTextInput.value);
                event.preventDefault();
            }
            
            const stopMovementOfObjectListener = () => {
                const movedKey = movementStopRef?.current?.currentMovingDirection
                movementStopRef.current = {
                    ...movementStopRef.current,
                    currentMovingDirection: null,
                    shouldStop: true,
                }
                stopMoving(movedKey)
            }
            
            document.addEventListener('keydown', keyDownListener);
            document.addEventListener('keyup', keyUpListener);
            document.addEventListener('paste', pasteListener);
            document.addEventListener('copy', copyListener);
            window.addEventListener('blur', blurListener);
            canvas.on('mouse:move', shortcutMouseMoveHandler);
            canvas.on('duplicate-object', onDuplicateObjectListener);
            canvas.on('remove-object', onRemoveObject);
            canvas.on('object:moving', objectMovingForDuplicateHandler);
            canvas.on('activate-shortcuts-mousemove', activateShortcutsMouseMoveHandler);
            canvas.on('stop-movement-of-object', stopMovementOfObjectListener);

            return () => {
                // remove event listeners
                document.removeEventListener('keydown', keyDownListener);
                document.removeEventListener('keyup', keyUpListener);
                document.removeEventListener('paste', pasteListener);
                document.removeEventListener('copy', copyListener);
                window.removeEventListener('blur', blurListener);
                canvas.off('mouse:move', shortcutMouseMoveHandler);
                canvas.off('duplicate-object', onDuplicateObjectListener);
                canvas.off('remove-object', onRemoveObject);
                canvas.off('object:moving', objectMovingForDuplicateHandler);
                canvas.off('activate-shortcuts-mousemove', activateShortcutsMouseMoveHandler);
                canvas.off('stop-movement-of-object', stopMovementOfObjectListener);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvas, userId, whiteboardId, eventEmitter]);

    useEffect(() => {
        if (!actionSelected?.dragMode && movementInterval.current) {
            clearTimeout(movementInterval.current);
        }
    }, [actionSelected]);
}


export default useShortCuts;