import { fabric } from 'fabric';
import { removeCanvasListener } from '../helpers/CommonFunctions'
import { SHAPE_DEFAULTS, modes } from '../helpers/Constant';
import store from '../redux/Store';
import { changeObjectsSelectableProp } from '../helpers/FabricMethods';

import {getTextColorBaseOnShape} from '../helpers/ColorHelper';
import { getShapeTextareaDimensions, onShapeTextboxChanged } from '../helpers/shapes/Common';
let mouseDown;

export const createSticky = (canvas, emitOnMouseDown, onMouseDownLineHandler, handleChangeSelectMode, userId) => {
    mouseDown = true;
    store.dispatch({
        type: 'board/changeCurrentMode',
        payload: modes.STICKY
    });
    removeCanvasListener(canvas);
    canvas.toggleDragMode(canvas, false);
    onMouseDownLineHandler(canvas);
    canvas.on('mouse:up', (e) => {
        if (mouseDown) {
            drawSticky(e, canvas, emitOnMouseDown, userId);
            mouseDown = false;
            handleChangeSelectMode('select');
        }
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
};

export const drawSticky = (e, canvas, emitOnMouseDown, userId) => {
    const pointer = canvas.getPointer(e);
    const text = new fabric.Textbox('', {
        originX: 'center',
        originY: 'center',
        textAlign: 'center',
        fontSize: 60,
        fill: SHAPE_DEFAULTS.TEXT_COLOR,
        // splitByGrapheme: true,
        breakWords: true,
        fixedWidth: 190,
        width: 190,
        hasBorders: false,
        selectable: false,
        fontFamily: SHAPE_DEFAULTS.FONT_FAMILY,
        underline: false,
        fontStyle: 'normal',
        fontWeight: 'normal',
        lockScalingFlip : true,
        flipX :false,
        flipY:false
    })

    const rect = new fabric.Rect({
        width: 220,
        height: 220,
        fill: SHAPE_DEFAULTS.STICKY_NOTE_BACKGROUND,
        rx: 10,
        ry: 10,
        originX: 'center',
        originY: 'center',
        selectable: false,
        shadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        lockScalingFlip : true,
        flipX :false,
        flipY:false
    });

    const group = new fabric.Group([rect, text], {
        shapeType: 'sticky',
        left: pointer.x,
        top: pointer.y,
        originX: 'center',
        originY: 'center',
        lockScalingFlip : true,
        flipX :false,
        flipY:false,
        isLocked: false
    });
    editSticky(group, canvas, emitOnMouseDown, userId);
    canvas.add(group);
    emitOnMouseDown(group, group);
    changeObjectsSelectableProp(canvas, true);
    canvas.hoverCursor = 'all-scroll';
    canvas.setActiveObject(group);
    canvas.renderAll();
};

export const editSticky = (group, canvas, emitOnMouseDown, userId) => {
    group.off('mousedblclick');

    group.on('mousedblclick', (e) => {
        if(!e.target){
            return;
        }
        if(e.target.isLocked || (e.target.lockMovementX && e.target.lockMovementY)){
            return;
        }
        if(e.target.type ==='textbox') canvas.fire('textbox-editing'); 
        else canvas.fire('shapeText-editing');
        if(e.target._objects && e.target._objects.length){
            const textboxObject = e.target._objects[1];
            const actualScaleX = group.scaleX * textboxObject.scaleX;
            const actualScaleY = group.scaleX * textboxObject.scaleY;
            textboxObject.set({
                visible: false
            })
            group.onShapeChanged();
            // Textarea Dimensions
            const { width: textWidth, height: textHeight } = getShapeTextareaDimensions(e.target);
            const textColor = e.target.isTextColorApplied ? textboxObject.fill : getTextColorBaseOnShape(e.target);
            const textForEditing = new fabric.Textbox(textboxObject.text, {
                originX: 'center',
                originY: 'center',
                textAlign: textboxObject.textAlign,
                fontSize: textboxObject.fontSize,
                left: group.left,
                top: group.top,
                fill: textColor,
                // splitByGrapheme: true,
                breakWords: true,
                fixedWidth: textWidth,
                width: textWidth,
                height: textHeight,
                hasBorders: false,
                selectable: false,
                fontFamily: textboxObject.fontFamily,
                selected: false,
                fontWeight: textboxObject.fontWeight,
                underline: textboxObject.underline,
                fontStyle: textboxObject.fontStyle,
                lockScalingFlip : true,
                flipX :false,
                flipY:false,
                scaleX: actualScaleX,
                scaleY: actualScaleY,
                isDynamic: true
            });
            textForEditing.set({ styles: textboxObject.styles });
            // NOTE: below line was affecting changing the width of sticky note
            // group.addWithUpdate();
            canvas.renderAll();
            e.target.onShapeChanged()
            textboxObject.visible = false;
            textForEditing.visible = true;
            textForEditing.hasConstrols = false;
            textForEditing.editFor = group;
            canvas.add(textForEditing);
            canvas.setActiveObject(textForEditing);
            group.editingText = true;
            group.editingTextObj = textForEditing;
            textForEditing.enterEditing();
            textForEditing.setSelectionStart(textForEditing.text.length);
            textForEditing.setSelectionEnd(textForEditing.text.length);
            canvas.on('change-text-size', (e) => {
                textForEditing.set({ fontSize: e });
                canvas.renderAll();
            });
            canvas.on('before-reload-canvas', () => {
                if (canvas.getObjects().find(e => e.uuid === group.uuid)) {
                    canvas.fire('modified-with-event', { target: group });
                    canvas.fire('modify-to-undo-stack', { target: group });
                    emitOnMouseDown(group, group, true);
                    canvas.remove(textForEditing);
                    canvas.setActiveObject(group);
                    canvas.fire('exit-shape-text-editing');
                }
            });
            textForEditing.on('editing:exited', () => {
                canvas.fire('exit-shape-text-editing');
                group.editingTextObj = null;
                const newVal = textForEditing.text;
                textboxObject.set({
                    text: newVal,
                    visible: true,
                    fontSize: textForEditing.fontSize,
                    width: textForEditing.width,
                    height: textForEditing.height,
                    fill: textForEditing.fill,
                    underline: textForEditing.underline,
                    fontStyle: textForEditing.fontStyle,
                    fontWeight: textForEditing.fontWeight,
                    textAlign: textForEditing.textAlign,
                    breakWords: true,
                    splitByGrapheme: false,
                });

                group.modifiedBy = userId;
                emitOnMouseDown(group, group, true);
                canvas.fire('modified-with-event', { target: group });
                canvas.fire('modify-to-undo-stack', { target: group });
                textboxObject.styles = null;
                textboxObject.set({ styles: textForEditing.styles, fill: textForEditing.fill });
                group.onShapeChanged();
                canvas.renderAll();
                textForEditing.visible = false;
                canvas.remove(textForEditing);
                group.editingText = null;
                canvas.setActiveObject(group);
                e.target.onShapeChanged()
            });

            const lastOnChangeInfo = { linesCount: -1 };
            textForEditing.on('changed', (opt) => onShapeTextboxChanged({
                opt,
                canvas,
                group,
                lastOnChangeInfo,
                textForEditing,
                textInstWidth: textWidth,
                textInstHeight: textHeight,
                actualScaleX,
                actualScaleY    
            }));
        }
    });
};

