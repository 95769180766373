import {preciseRoundDown, preciseRoundUp} from '../utils/MathUtils';

export class Viewport {
    get centerX() {
        return this.x + this.width / 2
    }
    get centerY() {
        return this.y + this.height / 2
    }
    get left() {
        return this.x
    }
    set left(value) {
        this.width += this.x - value;
        this.x = value;
        if (!isFinite(this.width)) this.width = -Infinity;
    }
    get right() {
        let t = this.x + this.width;
        return isFinite(t) ? t : this.width === 1 / 0 ? 1 / 0 : -1 / 0
    }
    set right(value) {
        this.width = value - this.x;
        if (!isFinite(this.width)) this.width = -Infinity;
    }
    get top() {
        return this.y
    }
    set top(value) {
        this.height += this.y - value;
        this.y = value;
        if (!isFinite(this.height)) this.height = -Infinity;
    }
    get bottom() {
        let t = this.y + this.height;
        return isFinite(t) ? t : this.height === 1 / 0 ? 1 / 0 : -1 / 0
    }
    set bottom(value) {
        this.height = value - this.y;
        if (!isFinite(this.height)) this.height = -Infinity;
    }
    get area() {
        return this.width * this.height
    }
    static createFromRect(rect) {
        return new Viewport(rect.x, rect.y, rect.width, rect.height);
    }
    static createFromBbox(bbox) {
        return new Viewport(bbox.minX, bbox.minY, bbox.maxX - bbox.minX, bbox.maxY - bbox.minY);
    }
    clone() {
        return new Viewport(this.x, this.y, this.width, this.height);
    }
    copy(rect) {
        this.x = rect.x;
        this.y = rect.y;
        this.width = rect.width;
        this.height = rect.height;
        return this;
    }
    set(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        return this;
    }
    setBounds(left, top, right, bottom) {
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
        return this;
    }
    dpr(dprValue) {
        this.x *= dprValue;
        this.y *= dprValue;
        this.width *= dprValue;
        this.height *= dprValue;
        return this;
    }
    merge(...rects) {
        rects.forEach(rect => {
            this.left = Math.min(this.left, rect.left);
            this.right = Math.max(this.right, rect.right);
            this.top = Math.min(this.top, rect.top);
            this.bottom = Math.max(this.bottom, rect.bottom);
        });
        return this;
    }
    indefinite() {
        this.x = Infinity;
        this.y = Infinity;
        this.width = -Infinity;
        this.height = -Infinity;
        return this;
    }
    infinite() {
        this.x = -Infinity;
        this.y = -Infinity;
        this.width = Infinity;
        this.height = Infinity;
        return this;
    }
    empty() {
        this.x = 0;
        this.y = 0;
        this.width = 0;
        this.height = 0;
        return this;
    }
    isFinite() {
        return isFinite(this.x) && isFinite(this.y) && isFinite(this.width) && isFinite(this.height)
    }
    isIndefinite() {
        return this.width === -Infinity || this.height === -Infinity;
    }
    isInfinite() {
        return this.width === Infinity || this.height === Infinity;
    }
    isEmpty() {
        return this.width === 0 || this.height === 0;
    }
    contains(x, y) {
        if (this.width <= 0 || this.height <= 0) return false;
        let startX = this.x;
        if (x >= startX && x <= startX + this.width) {
            let startY = this.y;
            if (y >= startY && y <= startY + this.height) return true;
        }
        return false;
    }
    getBounds() {
        return this
    }
    intersects(rect) {
        return this.left < rect.right && this.right > rect.left && this.top < rect.bottom && this.bottom > rect.top
    }
    containsRect(rect) {
        return this.left <= rect.left && this.right >= rect.right && this.top <= rect.top && this.bottom >= rect.bottom
    }
    equals(rect) {
        return this.x === rect.x && this.y === rect.y && this.width === rect.width && this.height === rect.height
    }
    toJSON() {
        return {
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height
        }
    }
    get minX() {
        return this.left
    }
    set minX(value) {
        this.left = value;
    }
    get minY() {
        return this.top;
    }
    set minY(value) {
        this.top = value;
    }
    get maxX() {
        return this.right
    }
    set maxX(value) {
        this.right = value;
    }
    get maxY() {
        return this.bottom
    }
    set maxY(t) {
        this.bottom = t
    }

    constructor(x = 0, y = 0, width= 0, height = 0) {
        this.x = x,
        this.y = y,
        this.width = width,
        this.height = height;
    }
}

export function isViewportValid(viewport) {
    return !viewport.isEmpty() && viewport.isFinite();
}

export function createFromRect(bounds) {
    return {
        minX: bounds.left,
        minY: bounds.top,
        maxX: bounds.right,
        maxY: bounds.bottom
    }
}

export function copyFromRect(destination, source) {
    destination.minX = source.left;
    destination.minY = source.top;
    destination.maxX = source.right;
    destination.maxY = source.bottom;
    return destination;
}

export function equalsWithRect(rect1, rect2) {
    return rect1.minX === rect2.left && rect1.minY === rect2.top &&
        rect1.maxX === rect2.right && rect1.maxY === rect2.bottom;
}

export function intersects(rect1, rect2) {
    return rect1.maxX > rect2.minX && rect1.maxY > rect2.minY &&
        rect1.minX < rect2.maxX && rect1.minY < rect2.maxY;
}

export function contains(container, contained) {
    return container.minX <= contained.minX && container.minY <= contained.minY &&
        container.maxX >= contained.maxX && container.maxY >= contained.maxY;
}

export function setupCanvasViewport(canvasWidth, canvasHeight, tileWidth, tileHeight, canvasOffsetX, canvasOffsetY, canvasScale, dpr, inverseDPR, viewport) {
    // round the canvas offsets
    let roundedCanvasOffsetX = preciseRoundDown(canvasOffsetX),
        roundedCanvasOffsetY = preciseRoundDown(canvasOffsetY),

        // calculate the offset positions adjusted by screen DPR
        offsetXWithDPR = roundedCanvasOffsetX * inverseDPR,
        offsetYWithDPR = roundedCanvasOffsetY * inverseDPR,

        // calculate the inverse scale factor
        inverseScale = 1 / canvasScale,

        // calculate the dimensions of the tiles adjusted for pixel ratio
        tileWidthWithDPR = preciseRoundDown(tileWidth * dpr),
        tileHeightWithDPR = preciseRoundDown(tileHeight * dpr),

        // calculate the scaling factors for tile dimensions
        scaleWidth = tileWidthWithDPR / tileWidth,

        // calculate the width and height of tiles on the screen considering DPR and scale
        tileScreenWidth = tileWidthWithDPR * inverseDPR / canvasScale,
        tileScreenHeight = tileHeightWithDPR * inverseDPR / canvasScale,

        // calculate the viewport offsets adjusted by the inverse scale
        viewportOffsetX = -offsetXWithDPR * inverseScale,
        viewportOffsetY = -offsetYWithDPR * inverseScale,

        // determine the indices for the visible viewport
        leftIndex = preciseRoundDown(viewportOffsetX / tileScreenWidth),
        rightIndex = preciseRoundUp((viewportOffsetX + canvasWidth * inverseScale) / tileScreenWidth) - 1,
        topIndex = preciseRoundDown(viewportOffsetY / tileScreenHeight),
        bottomIndex = preciseRoundUp((viewportOffsetY + canvasHeight * inverseScale) / tileScreenHeight) - 1,

        // calculate the viewport dimensions
        viewportX = leftIndex * tileScreenWidth,
        viewportY = topIndex * tileScreenHeight,
        viewportWidth = (rightIndex - leftIndex + 1) * tileScreenWidth,
        viewportHeight = (bottomIndex - topIndex + 1) * tileScreenHeight;

    // populate or initialize the viewport object
    if (viewport) {
        viewport.rect.x = viewportX;
        viewport.rect.y = viewportY;
        viewport.rect.width = viewportWidth;
        viewport.rect.height = viewportHeight;
        viewport.tileScale = canvasScale;
        viewport.tileScreenWidth = tileScreenWidth;
        viewport.tileScreenHeight = tileScreenHeight;
        viewport.zoomLevel = canvasScale;
        viewport.leftIndex = leftIndex;
        viewport.topIndex = topIndex;
        viewport.rightIndex = rightIndex;
        viewport.bottomIndex = bottomIndex;
        viewport.tileScreenWidthWithDPR = tileWidthWithDPR;
        viewport.tileScreenHeightWithDPR = tileHeightWithDPR;
        viewport.tileScreenOffsetXWithDPR = roundedCanvasOffsetX;
        viewport.tileScreenOffsetYWithDPR = roundedCanvasOffsetY;
        viewport.truncatedPixelRatio = scaleWidth;
    } else {
        viewport = {
            rect: new Viewport(viewportX, viewportY, viewportWidth, viewportHeight),
            tileScreenWidth: tileScreenWidth,
            tileScreenHeight: tileScreenHeight,
            tileScale: canvasScale,
            zoomLevel: canvasScale,
            leftIndex: leftIndex,
            topIndex: topIndex,
            rightIndex: rightIndex,
            bottomIndex: bottomIndex,
            tileScreenWidthWithDPR: tileWidthWithDPR,
            tileScreenHeightWithDPR: tileHeightWithDPR,
            tileScreenOffsetXWithDPR: roundedCanvasOffsetX,
            tileScreenOffsetYWithDPR: roundedCanvasOffsetY,
            truncatedPixelRatio: scaleWidth
        };
    }
    return viewport;
}

export const pixelRatio = {
    dpr: window.devicePixelRatio,
    inverseDPR: 1 / window.devicePixelRatio
}
