// WARNING: this file includes some old methods for our old implementation for the lines.
// We are not using those methods anymore, but we keep them here just in case we need them later.
import { fabric } from 'fabric';
import {LINE_ATTACHABLE_OBJECT_TYPES, modes} from '../helpers/Constant';
import { removeCanvasListener, stopDrawing } from '../helpers/CommonFunctions'
import { changeObjectsSelectableProp } from '../helpers/FabricMethods';
import { getLocalStorage, setLocalStorage } from '../services/CookieService';
import { customContainPointsWidthPadding } from '../helpers/lines/LineMethods';
import { makeCustomFlowchartHotspot } from '../helpers/flowchart/CustomFlowchartShapeUtils';

let drawInstance = null,
    mouseDown = false,
    options;

export const LineArrow = fabric.util.createClass(fabric.Line, {
    type: 'LineArrow',

    initialize(element, options) {
        options || (options = {});
        this.callSuper('initialize', element, options);
    },

    _render(ctx) {
        this.callSuper('_render', ctx);

        // do not render if width/height are zeros or object is not visible
        if (this.width === 0 || this.height === 0 || !this.visible) return;
        ctx.save();

        const xDiff = this.x2 - this.x1;
        const yDiff = this.y2 - this.y1;
        const angle = Math.atan2(yDiff, xDiff);
        ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
        ctx.rotate(angle);
        ctx.beginPath();
        // Move 5px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
        ctx.moveTo(5, 0);
        ctx.lineTo(-5, 5);
        ctx.lineTo(-5, -5);
        ctx.closePath();
        ctx.fillStyle = this.stroke;
        ctx.fill();
        ctx.restore();
    }
});

export const lineArrowFromObject = function (object, callback) {
    /**
     * @param instance
     */
    function _callback(instance) {
        delete instance.points;
        callback && callback(instance);
    }
    let options = JSON.parse(JSON.stringify(object));
    options.points = [object.x1, object.y1, object.x2, object.y2];
    fabric.Object._fromObject('LineArrow', options, _callback, 'points');
};

export const createLine = (canvas, emitOnMouseDown, onMouseDownLineHandler, isArrow) => {
    if (getLocalStorage('bboard_options')) {
        options = JSON.parse(getLocalStorage('bboard_options'));
    }
    options.currentMode = modes.LINE;
    setLocalStorage('bboard_options', JSON.stringify(options));
    removeCanvasListener(canvas);
    canvas.on('mouse:down', startAddLine(canvas, isArrow));
    canvas.on('mouse:move', startDrawingLine(canvas));
    canvas.on('mouse:up', () => {
        mouseDown = false;
        stopDrawing(canvas, drawInstance, emitOnMouseDown, onMouseDownLineHandler);
    });
    canvas.selection = false;
    canvas.defaultCursor = 'default';
    canvas.hoverCursor = 'auto';
    canvas.isDrawingMode = false;
    changeObjectsSelectableProp(canvas, false);
    canvas.discardActiveObject().requestRenderAll();
};

const startAddLine = (canvas, isArrow) => {
    return _ref => {
        let {
            e
        } = _ref;
        mouseDown = true;
        let pointer = canvas.getPointer(e);
        if (isArrow) {
            drawInstance = new LineArrow([pointer.x, pointer.y, pointer.x, pointer.y], {
                strokeWidth: options.currentWidth,
                stroke: options.currentColor,
                selectable: true,
                hasBorders: false,
                hasControls: true,
                perPixelTargetFind: true
            });
        }
        else {
            drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                strokeWidth: options.currentWidth,
                stroke: options.currentColor,
                selectable: true,
                hasBorders: false,
                hasControls: true,
                perPixelTargetFind: true
            });
        }
        // setFabricObjectContorlsVisibility(drawInstance, false);
        canvas.add(drawInstance);
        canvas.requestRenderAll();
    };
};

const startDrawingLine = (canvas) => {
    return _ref2 => {
        let {
            e
        } = _ref2;

        if (mouseDown) {
            const pointer = canvas.getPointer(e);
            drawInstance.set({
                x2: pointer.x,
                y2: pointer.y
            });
            drawInstance.setCoords();
            canvas.requestRenderAll();
        }
    };
};

export const createPoint = (pointType) => {
    return new fabric.Circle({
        left: 0,
        top: 0,
        radius: 6,
        visible: false,
        selectable: false,
        stroke: 'blue',
        fill: '',
        originX: 'center',
        originY: 'center',
        hasControls: false,
        hasBorders: false,
        pointType: pointType
    });
};

const setCirclePoint = (target, startPoint, endPoint) => {
    startPoint.set({
        left: target.x1,
        top: target.y1,
    }).setCoords();
    endPoint.set({
        left: target.x2,
        top: target.y2,
    }).setCoords();
};

export const selectLine = (canvas, target, startPoint, endPoint, selectedLine) => {
    setCirclePoint(target, startPoint.current, endPoint.current);
    startPoint.current.visible = true;
    startPoint.current.selectable = true;
    endPoint.current.visible = true;
    endPoint.current.selectable = true;
    selectedLine.current = target;
    startPoint.current.bringToFront();
    endPoint.current.bringToFront();
    canvas.requestRenderAll();
};

export const deSelectLine = (startPoint, endPoint, selectedLine) => {
    startPoint.current.visible = false;
    endPoint.current.visible = false;
    selectedLine.current = false;
};

export const customContainPoints = (shape, point) => {
    const shapeCoordinates = shape.getBoundingRect(true, true);
    const objectPos = {
        xStart: shapeCoordinates.left,
        xEnd: shapeCoordinates.left + shapeCoordinates.width,
        yStart: shapeCoordinates.top,
        yEnd: shapeCoordinates.top + shapeCoordinates.height
    }

    if (point.x >= objectPos.xStart && point.x <= (objectPos.xEnd)) {
        if (point.y >= objectPos.yStart && point.y <= objectPos.yEnd) return true;
    }

    return false;
}

/**
 * Attaches the line to the nearest polygon.
 * @param {fabric.Canvas} canvas 
 * @param {fabric.CurvedLine} line 
 * @param {*} socketRef 
 * @param {number} whiteBoardId 
 * @param {number} userId 
 * @param {object} options 
 * @param {boolean} options.dontEmitAttachedLine - If true, the line will not be emitted with shapeModified which is unnecessary when the line is just drawn.
 */
export const connectToGroup = (canvas, line, socketRef, whiteBoardId, userId, options = {}) => {
    let leftPolygons = [], rightPolygons = [], isLineModified = false;
    let isDeltaChanged = false;
    const lineHeadPoints = line.getHeadPoints();

    const lineX1 = parseFloat(lineHeadPoints[0].x?.toFixed(2));
    const lineY1 = parseFloat(lineHeadPoints[0].y?.toFixed(2));
    const lineX2 = parseFloat(lineHeadPoints[1].x?.toFixed(2));
    const lineY2 = parseFloat(lineHeadPoints[1].y?.toFixed(2));

    canvas.getObjects().forEach((element, index) => {
        if (element.uuid && (LINE_ATTACHABLE_OBJECT_TYPES.includes(element.type))) {
            if (customContainPointsWidthPadding(element, { x: lineX1, y: lineY1 }, 5)) leftPolygons.push(index);
            if (customContainPointsWidthPadding(element, { x: lineX2, y: lineY2 }, 5)) rightPolygons.push(index);
            
        }
    });

    if (!leftPolygons.length && line.leftPolygon) {
        let lineIndex = line.leftPolygon.lines?.findIndex(e => e?.uuid === line.uuid);
        if (lineIndex !== -1) {
            line.leftPolygon.lines.splice(lineIndex, 1);

            emitConnectorAddedObj(canvas, line.leftPolygon, whiteBoardId, userId, { dontEmit: options.dontEmitTarget});
            line.leftPolygon.isDeleted = true;
            line.leftDeltaX = 0;
            line.leftDeltaY = 0;
            isLineModified = true;
            isDeltaChanged = true;
        }
    }
    if (!rightPolygons.length && line.rightPolygon) {
        let lineIndex = line.rightPolygon.lines?.findIndex(e => e?.uuid === line.uuid);
        if (lineIndex !== -1) {
            line.rightPolygon.lines.splice(lineIndex, 1);

            emitConnectorAddedObj(canvas, line.rightPolygon, whiteBoardId, userId, { dontEmit: options.dontEmitTarget});
            line.rightPolygon.isDeleted = true;
            line.rightDeltaX = 0;
            line.rightDeltaY = 0;
            isLineModified = true;
            isDeltaChanged = true;
        }
    }
    if (leftPolygons.length) {
        const leftPolygon = canvas.getObjects()[Math.max(...leftPolygons)];
        if (!leftPolygon.lines) leftPolygon.lines = [];
        let lineIndex = leftPolygon.lines?.indexOf(line.uuid);
        if (lineIndex === -1 && options.dontAddLines !== true) leftPolygon.lines.push(line.uuid);

        // calculate attached posiitons according to the origin based position of the polygon
        const centerPoint = leftPolygon.getCenterPoint();
        line.leftDeltaX = lineX1 - parseFloat(centerPoint.x?.toFixed(2));
        line.leftDeltaY = lineY1 - parseFloat(centerPoint.y?.toFixed(2));
        isDeltaChanged = true;

        line.leftPolygon = leftPolygon;

        if (leftPolygon.flowchartProps) {
            makeCustomFlowchartHotspot(line, 'left');
        }

        emitConnectorAddedObj(canvas, leftPolygon, whiteBoardId, userId, { dontEmit: options.dontEmitTarget});
        if (!options.dontEmitAttachedLine)
            isLineModified = true;
    }
    if (rightPolygons.length) {
        const rightPolygon = canvas.getObjects()[Math.max(...rightPolygons)];
        if (!rightPolygon.lines) rightPolygon.lines = [];
        let lineIndex = rightPolygon.lines?.indexOf(line.uuid);
        if (lineIndex === -1 && options.dontAddLines !== true) rightPolygon.lines.push(line.uuid);

        // calculate attached posiitons according to the origin based position of the polygon
        const centerPoint = rightPolygon.getCenterPoint();
        line.rightDeltaX = lineX2 - parseFloat(centerPoint.x?.toFixed(2));
        line.rightDeltaY = lineY2 - parseFloat(centerPoint.y?.toFixed(2));
        isDeltaChanged = true;

        line.rightPolygon = rightPolygon;

        if (rightPolygon.flowchartProps) {
            makeCustomFlowchartHotspot(line, 'right');
        }

        emitConnectorAddedObj(canvas, rightPolygon, whiteBoardId, userId, { dontEmit: options.dontEmitTarget});
        if (!options.dontEmitAttachedLine)
            isLineModified = true;
    }

    // We are changing arrow direction in some scenarios in case of delta is changed. So need to re-render.
    if (isDeltaChanged) {
        line.onShapeChanged();
    }

    if (isLineModified) emitConnectorModifiedObj(canvas, line, whiteBoardId, userId, true);
    return { isDeltaChanged }
};

const emitConnectorAddedObj = (canvas, polygon, whiteBoardId, userId, options = {}) => {
    if (options.dontEmit !== true) {
        canvas.fire('history-emit-data', { 
            objects: [polygon],
            action: 'modified',
        })
    }
};

export const emitConnectorModifiedObj = (canvas, connector) => {
    canvas.fire('history-emit-data', {
        objects: [connector],
        action: 'modified',
    });
    return true;
};

export const emitMultipleConnectorModifiedObj = (canvas, connectors, options = {}) => {
    if (connectors.length > 0) {
        let customToObjectOptions = {};

        if (options?.useCalculatedPosition) {
            customToObjectOptions.useCalculatedPosition = true;
        }

        canvas.fire('history-emit-data', {
            objects: [...connectors],
            action: 'modified',
            customToObjectOptions
        });
    }
};

export const checkIfLineIsConnectWithShape = (line, element) => {
    const lineHeadPoints = line.getHeadPoints();

    const lineX1 = lineHeadPoints[0].x;
    const lineY1 = lineHeadPoints[0].y;
    const lineX2 = lineHeadPoints[1].x;
    const lineY2 = lineHeadPoints[1].y;
    return customContainPointsWidthPadding(element, { x: lineX2, y: lineY2 }, 5) || customContainPointsWidthPadding(element, { x: lineX1, y: lineY1 }, 5);
}

export const calcLineCoords = (line) => {
    const {
        tl, tr, bl, br,
    } = line.aCoords;
    let coordsStart;
    let coordsEnd;
  
    if (line.x1 > line.x2) {
        if (line.y1 > line.y2) {
            coordsStart = br;
            coordsEnd = tl;
        } else {
            coordsStart = tr;
            coordsEnd = bl;
        }
    } else {
        if (line.y1 > line.y2) {
            coordsStart = bl;
            coordsEnd = tr;
        } else {
            coordsStart = tl;
            coordsEnd = br;
        }
    }
  
    return [coordsStart, coordsEnd];
}
