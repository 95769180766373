import { fabric } from 'fabric';

export class CompositeRenderer {
    constructor(canvas) {
        this.fabricCanvas = canvas;
    }
    
    beginRender() {
        if (this.fabricCanvas.contextTopDirty && !this.fabricCanvas._groupSelector && !this.fabricCanvas.isDrawingMode) {
            this.fabricCanvas.clearContext(this.fabricCanvas.contextTop);
            this.fabricCanvas.contextTopDirty = false;
        }
        if (this.fabricCanvas.hasLostContext) {
            this.fabricCanvas.renderTopLayer(this.fabricCanvas.contextTop);
            this.fabricCanvas.hasLostContext = false;
        }
        
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas.cancelRequestedRender();
        this.fabricCanvas.calcViewportBoundaries();
        this.fabricCanvas.clearContext(ctx);
        fabric.util.setImageSmoothing(ctx, this.fabricCanvas.imageSmoothingEnabled);
        this.fabricCanvas.fire('before:render', { ctx: ctx, });
    }
    renderBackground() {
        // I don't know why but this comes empty
        // if we decide the change background color of the board, please handle this also.
        if (this.fabricCanvas.backgroundColor === '') {
            this.fabricCanvas.backgroundColor = '#fafafa';
        }
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas._renderBackground(ctx);
    }
    
    renderControlsUI() {
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas.drawControls(ctx);
    }
    
    renderDynamicObjects() {
        const vpt = this.fabricCanvas.viewportTransform;
        const ctx = this.fabricCanvas.contextContainer;
        ctx.save();
        ctx.transform(vpt[0], vpt[1], vpt[2], vpt[3], vpt[4], vpt[5]);
        for (const object of this.fabricCanvas.dirtyObjects) {
            if (!object) {
                continue
            }
            object.render(ctx, {
                isRenderingDynamic: true,
                isRenderingWithTiles: true
            });
        }
        ctx.restore();    
    }
    
    endRender() {
        const ctx = this.fabricCanvas.contextContainer;
        this.fabricCanvas.fire('after:render', { ctx: ctx, });
    }

    renderDefault() {
        const ctx = this.fabricCanvas.contextContainer;
        const v = this.fabricCanvas.viewportTransform;
        const objects = this.fabricCanvas._chooseObjectsToRender({ excludeDynamics: true });

        ctx.save();
        //apply viewport transform once for all rendering process
        ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
        this.fabricCanvas._renderObjects(ctx, objects, { renderingDefault: true });
        ctx.restore();
    }
}