import { useEffect, useState } from 'react';
import { getActiveWord, replaceAt } from '../helpers/mentions';

const useMention = (inputRef) => {
    const [value, setValue] = useState('');
    const [visibleValue, setVisibleValue] = useState('');
    const [queryFilter, setQueryFilter] = useState('');
    const [showMentions, setShowMentions] = useState(false);
    const [activeWord, setActiveWord] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [taggedUsers, setTaggedUsers] = useState([]);

    const closeMentions = () => {
        setShowMentions(false);
    }

    const handleSelect = (obj) => {
        setShowMentions(false);
    
        const [index] = activeWord.range;
        const replacement = `@${obj.commentUsername}`;
    
        const replacedText = replaceAt(
            visibleValue,
            replacement,
            index,
            activeWord.word.length
        )
    
        setVisibleValue(replacedText);
        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.selectionEnd = index + replacement.length;
                inputRef.current.focus();
                setVisibleValue(replacedText + ' ');
            }, 0);
        }
    
        const { name, id, commentUsername } = obj;
        setTaggedUsers([
            ...taggedUsers, { 
                name, 
                commentUsername,
                id,
                range: [index, index + replacement.length],
                fullText: replacement,
                index: activeWord.index
            }
        ]);
    }

    const handleChange = (e) => {
        let inputVal = e.target.value;
        
        const selectionStart = e.target.selectionStart;
        let activeWord = getActiveWord(inputVal, selectionStart);
        if (activeWord && activeWord.word.startsWith('@')) {
            let query = activeWord.word.substring(1);
            setQueryFilter(query);
            setActiveWord(activeWord);
            setShowMentions(true);
        } else {
            setQueryFilter('');
            setShowMentions(false);
        }
    
        setVisibleValue(inputVal);
        
        if (taggedUsers.length > 0) {
            let tempAddedLinks = [...taggedUsers];
            for (let i = 0; i < tempAddedLinks.length; i++ ) {
                const element = tempAddedLinks[i];
                if (inputVal.substring(element.range[0], element.range[1]) !== element.fullText) {
                    tempAddedLinks.splice(i);
                }
            }
            setTaggedUsers(tempAddedLinks);
        }
    }


    const getTaggedUserObject = () => [...taggedUsers];
    
    useEffect(() => {
        if (taggedUsers.length > 0) {
            let content = visibleValue.split(/[\s\n]/);
            for (const element of taggedUsers) {
                let wordsCount = element.fullText.split(/\s/).length;
                content.splice(element.index, 1, `@[${element.commentUsername}](${element.id})`);
                if (wordsCount > 1) {
                    content.splice(element.index + 1, 1, null);
                }
            }
            let newContent = content.filter(item => item !== null);
            setValue(newContent.join(' '));
        } else {
            setValue(visibleValue);
        }
    }, [visibleValue, taggedUsers]);

    useEffect(() => {
        if (visibleValue.trim().length > 0) setDisabled(false);
        else setDisabled(true);
    }, [visibleValue])

    return {
        value,
        setValue,
        visibleValue,
        setVisibleValue,
        queryFilter,
        showMentions,
        closeMentions,
        disabled,
        handleSelect,
        handleChange,
        getTaggedUserObject
    }
}


export default useMention;