import { createSlice } from '@reduxjs/toolkit';
import { getActivePage } from '../../../helpers/pages/CommonPagesMethods';

const initialState = {
    activeContentType: 'PAGES',
    activePage: {
        id: -1,
        pageNo: 1,
        slugId: ''
    },
    pages: []
}

const rightDrawerReducer = createSlice({
    name: 'rightDrawer',
    initialState,
    reducers: {
        toggleVisibility: (state, action) => {
            if (action.payload && typeof action.payload === 'string') {
                state.activeContentType = action.payload;
            } else {
                state.activeContentType = '';
            }
        },
        closeDrawer: (state) => {
            state.activeContentType = '';
        },
        resetPagesData: (state) => {
            state.activeContentType = '';
            state.pages = [];
            state.activePage = {
                id: -1,
                pageNo: 1,
                slugId: ''
            }
        },
        initPages: (state, action) => {
            state.pages = action.payload.pages;

            const idx = action.payload.isReinit ?
                state.pages.findIndex((page) => page.id === state.activePage.id)
                : 0;
            
            const activePageNumber = getActivePage(action.payload.pages)

            if (activePageNumber) {
                const newPage = action.payload.pages[activePageNumber - 1]

                const activePageN = {
                    slugId: newPage.wbPageId,
                    pageNo: activePageNumber,
                    id: newPage.id
                }
                state.activePage = activePageN;
                state.pages = state.pages.map((item,index) => {
                    return (index === (activePageNumber - 1)) ? {...item, active:true} : {...item, active:false}
                })
            }
            else state.activePage = {
                id: state.pages[idx]?.id,
                pageNo: action.payload.isReinit ? idx + 1 : 1,
                slugId: state.pages[idx]?.wbPageId
            }
        },
        setPage: (state, action) => {
            const idx = state.pages.findIndex((page) => page.id === action.payload.pageId);
            if (idx === -1) return;

            state.pages[idx].isFetched = true;
            state.pages[idx].shapes = action.payload.shapes;
            state.pages[idx].stackOrder = action.payload.stackOrder;
            if (action.payload?.properties?.forNima) {
                state.pages[idx].forNima = true;
            }
        },
        changePage: (state, action) => {
            const pageIdx = state.pages.findIndex((page) => page.wbPageId === action.payload);
            if (pageIdx === -1) return;

            state.activePage = {
                id: state.pages[pageIdx].id,
                pageNo: pageIdx + 1,
                slugId: state.pages[pageIdx].wbPageId
            }

            state.pages[pageIdx].active = true;
        },
        changePageByPageNo: (state, action) => {
            const newPage = state.pages[action.payload - 1];
            if (!newPage) return;

            state.activePage = {
                slugId: newPage.wbPageId,
                pageNo: action.payload,
                id: newPage.id
            };

            state.pages[action.payload - 1].active = true;
        },
        createNewPage: (state, action) => {
            state.pages.push({
                ...action.payload,
                shapes: [],
                stackOrder: [],
                isActive: false,
                isFetched: false
            });
        },
        changePageName: (state, action) => {
            const idx = state.pages.findIndex((page) => page.wbPageId === action.payload.wbPageId);
            if (idx !== -1) {
                state.pages[idx].pageName = action.payload.pageName;
            }
        },
        deletePage: (state, action) => {
            const idx = state.pages.findIndex((page) => page.wbPageId === action.payload.wbPageId);
            if (idx === -1) return;

            const activePageObj = state.pages[idx];

            if (idx > 0 && activePageObj.wbPageId === state.activePage.slugId) { // If any page is removing except first page
                state.activePage = {
                    id: state.pages[0].id,
                    pageNo: 1,
                    slugId: state.pages[0].wbPageId
                }

                state.pages[0].active = true;
            } else if (activePageObj.wbPageId === state.activePage.slugId) { // If the first page is selected and it's removing
                state.activePage = {
                    id: state.pages[1].id,
                    pageNo: 1,
                    slugId: state.pages[1].wbPageId
                }

                state.pages[idx + 1].active = true;
            } else if (state.pages.length === state.activePage.pageNo) { // If last page is deleted and its not selected
                state.activePage.pageNo -= 1; 
            }

            state.pages.splice(idx, 1);
        }
    }
});


export default rightDrawerReducer.reducer;
