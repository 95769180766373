import { fabric } from 'fabric';
import { applyTransformToObject, saveObjectTransform, hasStyleChanged } from './UtilOverrides';
import { pan } from '../../hooks/PanZoom';
import toCanvasEl from './canvas/ToCanvasEl';
import { bringToFront, findNewLowerIndex, findNewUpperIndex, sendToBack } from './canvas/FindIndexes';
import { _setSVGHeader, group_toSVG, objectToSVG, toSVG } from './canvas/ToSVG';
import renderCanvas from './canvas/RenderCanvas';
import { initDimensions, getHoveredHyperlinkSelectionIndexes, removeHyperLinkFromText, getHyperlinksFromStyle, onInput, mouseUpHandler, _mouseDownHandlerBefore, getAllStyles, onTextCopy, _stylePropertiesForCompare, getCompleteStyleDeclaration } from './Text';
import dragHandler from './DragHandler';
import { Rhombus, rhombusFromObject } from '../../customClasses/shapes/Rhombus';
import { Parallelogram, parallelogramFromObject } from '../../customClasses/shapes/Parallelogram';
import _getStyleDeclaration from './GetStyleDeclaration';
import { CurvedLine, curvedLineFromObject } from '../../hooks/UseCurvedLine';
import OptimizedImage, { optimizedImageFromObject } from '../../customClasses/image/OptimizedImage';
import { Frame, frameFromObject } from '../../hooks/UseFrame';
import { connectorControlBottom, connectorControlLeft, connectorControlRight, connectorControlTop } from '../customControls/connector/ConnectorControls';
import { heigthControlMB, heigthControlMT, scaleControlBL, scaleControlBR, scaleControlTL, scaleControlTR, widthControlML, widthControlMR } from '../customControls/groupScale/Controls';
import get2DCursorLocation from './Get2dCursorLocation';
import newLineMissing from './IsEndOfWrapping';
import { expanseBottom, expanseLeft, expanseRight, expanseTop } from '../customControls/expansion/Controls';
import _finalizeCurrentTransform from './FinalizeCurrentTransform';
import _wrapLine from './WrapLine';
import { _renderChar, _renderChars, _renderText, _renderTextCommon } from './RenderChar';
import RotateImg from '../../assets/images/rotate_icon.svg';
import isTargetTransparent from './canvas/IsTargetTransparent';
import fireSyntheticInOutEvents from './canvas/FireSyntheticInOutEvents';
import _chooseObjectsToRender from './canvas/ChooseObjectsToRender';
import { Table } from '../table/shapes/Table';
import { tableFromObject } from '../table/TableMethods';
import { _shouldClearSelection } from './canvas/ShouldClearSelection';
import _clearTextArea from './ClearTextArea';
import isCacheDirty from './IsCacheDirty';
import render from './Render';
import { exitEditing } from './ExitEditing';
import _getCacheCanvasDimensions from './GetCacheCanvasDimensions';
import isOnScreen, { defaultIsOnScreen } from './IsOnScreen';
import _updateCacheCanvas from './UpdateCacheCanvas';
import _removeCacheCanvas from './RemoveCacheCanvas';
import isTransparent from './IsTransparent';
import {_renderControls, toObject} from './ActiveSelectionOverrides';
import {_initEngine, initialize, renderAll, renderDefault, requestRenderAll} from './canvas/Init';
import zoomToPoint from './canvas/ZoomToPoint';
import {add, remove, moveTo} from './canvas/CollectionOverrides';
import {onObjectChanged, onOrderChanged, onShapeChanged, onTransformChanged, onVisibilityChanged} from './ObjectEvents';
import _renderObjects from './canvas/RenderObjects';
import {__onMouseMove} from './canvas/CanvasEvents';
import {addToDirtyList, removeFromDirtyList} from './object/RemoveFromDirtyList';
import {updateFromTextArea} from './textbox/UpdateFromTextArea';
import {dispose, clear} from './canvas/ResourceCleanup';
import {getCompleteBoundingRect} from './object/GetCompleteBoundingRect';
import selectAll from './textbox/SelectAll';
import {_set} from './object/Set';
import { _createGroup } from './canvas/CreateGroup';
import getBoundingBox from './canvas/GetBoundingBox';

const renderIcon = (icon) => {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        const size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size / 2, -size / 2, size, size);
        ctx.restore();
    }
};

const overrideFabric = () => {
    fabric.util.saveObjectTransform = saveObjectTransform;
    fabric.util.isTransparent = isTransparent;

    fabric.Canvas.prototype.toggleDragMode = pan;
    fabric.Canvas.prototype.toCanvasEl = toCanvasEl;
    fabric.Canvas.prototype._findNewLowerIndex = findNewLowerIndex;
    fabric.Canvas.prototype._findNewUpperIndex = findNewUpperIndex;
    fabric.Canvas.prototype.sendToBack = sendToBack;
    fabric.Canvas.prototype.bringToFront = bringToFront;
    fabric.Canvas.prototype.toSVG = toSVG;
    fabric.Canvas.prototype._setSVGHeader = _setSVGHeader;
    fabric.Canvas.prototype.renderCanvas = renderCanvas;
    fabric.Canvas.prototype.isTargetTransparent = isTargetTransparent;
    fabric.Canvas.prototype.fireSyntheticInOutEvents = fireSyntheticInOutEvents;
    fabric.Canvas.prototype._chooseObjectsToRender = _chooseObjectsToRender;
    fabric.Canvas.prototype._shouldClearSelection = _shouldClearSelection;
    fabric.Canvas.prototype.initialize = initialize;
    fabric.Canvas.prototype._initEngine = _initEngine;
    fabric.Canvas.prototype.renderAll = renderAll;
    fabric.Canvas.prototype.requestRenderAll = requestRenderAll;
    fabric.Canvas.prototype.renderDefault = renderDefault;
    fabric.Canvas.prototype.zoomToPoint = zoomToPoint;
    fabric.Canvas.prototype.add = add;
    fabric.Canvas.prototype.remove = remove;
    fabric.Canvas.prototype._renderObjects = _renderObjects;
    fabric.Canvas.prototype.__onMouseMove = __onMouseMove;
    fabric.Canvas.prototype.dispose = dispose;
    fabric.Canvas.prototype.clear = clear;
    fabric.Canvas.prototype.moveTo = moveTo;
    fabric.Canvas.prototype._createGroup = _createGroup;
    fabric.Canvas.prototype.getBoundingBox = getBoundingBox;
    fabric.Text.prototype.initDimensions = initDimensions;
    fabric.IText.prototype.mouseUpHandler = mouseUpHandler;
    fabric.IText.prototype._mouseDownHandlerBefore = _mouseDownHandlerBefore;
    fabric.IText.prototype._clearTextArea = _clearTextArea;
    fabric.controlsUtils.dragHandler = dragHandler;
    // // fabric.Object.prototype.controls.clone = new fabric.Control({
    // //   x: -0.5,
    // //   y: -0.5,
    // //   offsetY: -16,
    // //   offsetX: -16,
    // //   render: renderSubToolbar
    // // });
    // fabric.Textbox.prototype.controls.clone =  fabric.Object.prototype.controls.clone;
    fabric.Text.prototype._getStyleDeclaration = _getStyleDeclaration;
    fabric.Text.prototype._styleProperties.push('url');
    fabric.Text.prototype._stylePropertiesForCompare = _stylePropertiesForCompare;
    fabric.Text.prototype.getCompleteStyleDeclaration = getCompleteStyleDeclaration;
    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerStyle = 'circle';
    fabric.Object.prototype.borderColor = '#536DFE';
    fabric.Object.prototype.cornerColor = '#536DFE';
    fabric.Object.prototype.cornerSize = 10;
    fabric.Object.prototype.toSVG = objectToSVG;
    fabric.Object.prototype.render = render;
    fabric.Object.prototype.isCacheDirty = isCacheDirty;
    fabric.Object.prototype._getCacheCanvasDimensions = _getCacheCanvasDimensions;
    fabric.Object.prototype.isOnScreen = isOnScreen;
    fabric.Object.prototype.defaultIsOnScreen = defaultIsOnScreen;
    fabric.Object.prototype._updateCacheCanvas = _updateCacheCanvas;
    fabric.Object.prototype._removeCacheCanvas = _removeCacheCanvas;
    fabric.Object.prototype.onObjectChanged = onObjectChanged;
    fabric.Object.prototype.onShapeChanged = onShapeChanged;
    fabric.Object.prototype.onTransformChanged = onTransformChanged;
    fabric.Object.prototype.onOrderChanged = onOrderChanged;
    fabric.Object.prototype.onVisibilityChanged = onVisibilityChanged;
    fabric.Object.prototype.getCompleteBoundingRect = getCompleteBoundingRect;
    fabric.Group.prototype._toSVG = group_toSVG;
    fabric.Object.prototype.getZoomedRatio = function () {
        if (!this.canvas) {
            return;
        }
        const zoomLevel = this.canvas.getZoom();
        const widthRatio = this.width * this.scaleX * zoomLevel;
        const heightRatio = this.height * this.scaleY * zoomLevel;
        
        return {
            widthRatio,
            heightRatio,
            maxRatio: Math.max(widthRatio, heightRatio)
        }
        
    }
    fabric.Object.prototype.shouldCache = function() {
        return false;
    }
    fabric.Object.prototype.removeFromDirtyList = removeFromDirtyList;
    fabric.Object.prototype.addToDirtyList = addToDirtyList;
    fabric.Object.prototype._set = _set;
    
    
    fabric.CurvedLine = CurvedLine;
    fabric.CurvedLine.fromObject = curvedLineFromObject;
    fabric.OptimizedImage = OptimizedImage;
    fabric.OptimizedImage.fromObject = optimizedImageFromObject;
    
    fabric.Frame = Frame;
    fabric.Frame.fromObject = frameFromObject;
    fabric.Rhombus = Rhombus;
    fabric.Rhombus.fromObject = rhombusFromObject;
    fabric.Parallelogram = Parallelogram;
    fabric.Parallelogram.fromObject = parallelogramFromObject;

    fabric.Table = Table;
    fabric.Table.fromObject = tableFromObject;
    
    let orgControl = fabric.Object.prototype.controls.mtr,
        img = document.createElement('img');
    img.src = RotateImg;
    
    fabric.Object.prototype.controls.mtr.visible = false;
    fabric.Object.prototype.controls.rotate = new fabric.Control({
        x: -0.5,
        y: 0.5,
        offsetY: 16,
        offsetX: -16,
        cursorStyle: 'pointer',
        actionHandler: orgControl.actionHandler,
        actionName: 'rotate',
        render: renderIcon(img),
        cornerSize: 24
    });
    fabric.Object.prototype.controls.connectorLeft = connectorControlLeft(true);
    fabric.Object.prototype.controls.connectorTop = connectorControlTop(true);
    fabric.Object.prototype.controls.connectorRight = connectorControlRight(true);
    fabric.Object.prototype.controls.connectorBottom = connectorControlBottom(true);
    fabric.Object.prototype.controls.ml = widthControlML;
    fabric.Object.prototype.controls.mr = widthControlMR;
    fabric.Object.prototype.controls.mt = heigthControlMT;
    fabric.Object.prototype.controls.mb = heigthControlMB;
    fabric.Object.prototype.controls.tr = scaleControlTR;
    fabric.Object.prototype.controls.tl = scaleControlTL;
    fabric.Object.prototype.controls.br = scaleControlBR;
    fabric.Object.prototype.controls.bl = scaleControlBL;
    fabric.Textbox.prototype.controls.connectorLeft = connectorControlLeft(false);
    fabric.Textbox.prototype.controls.connectorTop = connectorControlTop(false);
    fabric.Textbox.prototype.controls.connectorRight = connectorControlRight(false);
    fabric.Textbox.prototype.controls.connectorBottom = connectorControlBottom(false);
    fabric.Textbox.prototype.setControlsVisibility({
        mt: false,
        mb: false
    });
    fabric.Textbox.prototype.get2DCursorLocation = get2DCursorLocation;
    fabric.Textbox.prototype.isEndOfWrapping = newLineMissing;
    fabric.Textbox.prototype.missingNewlineOffset = newLineMissing;
    fabric.Textbox.prototype.removeHyperLinkFromText = removeHyperLinkFromText;
    fabric.Textbox.prototype.getHoveredHyperlinkSelectionIndexes = getHoveredHyperlinkSelectionIndexes;
    fabric.Textbox.prototype.getHyperlinksFromStyle = getHyperlinksFromStyle;
    fabric.Textbox.prototype.getAllStyles = getAllStyles;
    fabric.Textbox.prototype.exitEditing = exitEditing;
    fabric.Textbox.prototype.updateFromTextArea = updateFromTextArea;
    fabric.Textbox.prototype.selectAll = selectAll;
    fabric.ActiveSelection.prototype.controls.expanseRight = expanseRight;
    fabric.ActiveSelection.prototype.controls.expanseLeft = expanseLeft;
    fabric.ActiveSelection.prototype.controls.expanseTop = expanseTop;
    fabric.ActiveSelection.prototype.controls.expanseBottom = expanseBottom;
    fabric.ActiveSelection.prototype._renderControls = _renderControls;
    fabric.ActiveSelection.prototype.toObject = toObject;
    fabric.Canvas.prototype._finalizeCurrentTransform = _finalizeCurrentTransform;
    fabric.util.applyTransformToObject = applyTransformToObject;
    fabric.util.hasStyleChanged = hasStyleChanged;
    
    fabric.Object.prototype.ignoreZoom = false;
    fabric.Object.prototype.transform = function(ctx) {
        if (this.ignoreZoom) {
            const zoom = this?.canvas.getZoom();
            this.scaleX = 1 / zoom;
            this.scaleY = 1 / zoom;
        }
        const needFullTransform = (this.group && !this.group._transformDone) ||
        (this.group && this.canvas && ctx === this.canvas.contextTop);
        const m = this.calcTransformMatrix(!needFullTransform);
        ctx.transform(m[0], m[1], m[2], m[3], m[4], m[5]);
    }
    fabric.util.object.extend(fabric.Textbox.prototype, {
        _wrapLine: _wrapLine,
        onInput: onInput,
        copy: onTextCopy
    });
    fabric.util.object.extend(fabric.Text.prototype, {
        _renderChar: _renderChar,
        _renderChars: _renderChars(),
        _renderTextCommon: _renderTextCommon,
        _renderText: _renderText
    })
    fabric.util.object.extend(fabric.IText.prototype, {
        onInput: onInput
    })
}

export default overrideFabric;