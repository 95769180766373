
/**
 * Renders an object on a specified context.
 * @override
 * @param {CanvasRenderingContext2D} ctx Context to render on.
 * @param {object} renderingOptions Rendering options for the current render.
 */
export default function render(ctx, renderingOptions) {
    if (this.canvas?.cacheOperation === 'DISABLED') {
        this.dirty = true;
    }
    // do not render if width/height are zeros or object is not visible
    if (this.isNotVisible()) {
        return;
    }

    if (this.canvas && this.canvas.skipOffscreen && !this.group && !this.isOnScreen()) {
        return;
    }

    ctx.save();
    this._setupCompositeOperation(ctx);
    this.drawSelectionBackground(ctx);
    this.transform(ctx);
    this._setOpacity(ctx);
    this._setShadow(ctx, this);
    this.renderingOptions = renderingOptions;

    if (this.cannotForceToCache !== true && (this.canvas?.cacheOperation === 'ENABLED' || this.shouldCache())) {
        this.renderCache();
        this.drawCacheOnCanvas(ctx);

        if (typeof this._renderEvenInCache === 'function') {
            this._renderEvenInCache(ctx);
        }
    }
    else {
        this._removeCacheCanvas();
        this.dirty = false;
        this.drawObject(ctx);
        if (this.objectCaching && this.statefullCache) {
            this.saveState({ propertySet: 'cacheProperties' });
        }
    }
    this.renderingOptions = null;
    ctx.restore();
}
