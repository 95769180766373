import { fabric } from 'fabric';
import { TEXTBOX_TYPE, EMITTER_TYPES } from '../../Constant';
import cloneTargetWithProperties from '../../CloneTargetWithProperties';
import magnetEffectForPolygon from '../../lines/MagnetEffect';
import { calculateDuplicatedObjPosition, createCurvedLineByConnector, calculateCurvedLinePosition, findPositionOfConnectorControl, getActualCorner, isPlusConnectorSupported, drawCornerLine, emitLineDrawing, emitObjectsCreatedByConnector } from './ConnectorControlMethods';
import eventEmitter from '../../EventEmitter';
import { lineAlignmentHandler } from '../../alignment/GenerateAlignmentLines';
import { connectToGroup } from '../../../hooks/UseLine';

let isMoved = false;
let clearLongPressTimeout = null;

const isActionAllowed = (action, target) => {
    let isAllowed = true;
    if (action !== 'connector' || (target.lockMovementX && target.lockMovementY)) isAllowed = false;
    return isAllowed;
}

/**
 * @param e
 * @param actionData
 * @param x
 * @param y
 */
export function mouseDownHandlerForConnectors(e, actionData) {
    const {
        action,
        corner,
        target
    } = actionData;

    if (!isActionAllowed(action, target)) return;
    if (!isPlusConnectorSupported(target)) return;

    clearLongPressTimeout = setTimeout(async () => {
        const actualCorner = target.angle !== 0 ? getActualCorner(target.angle, corner) : corner;
        const position = calculateDuplicatedObjPosition(target, actualCorner, corner);

        const values = target.toObject();
        values.left = position.x;
        values.top = position.y;
        values.selectable = false;
        values.evented = false;
        values.hasControls = false;
        values.hasBorders = false;
        values.hasRotatingPoint = false;
        values.originY = 'center';
        values.originX = 'center';

        if (Array.isArray(values.objects)) {
            // Clear text objects from shape if exist
            values.objects = values.objects.filter((o) => o.type !== TEXTBOX_TYPE);

            values.objects[0].fill = 'rgba(0,0,0,0)';
            values.objects[0].strokeDashArray = [5, 5];
            values.objects[0].stroke = '#b7403f';
        }

        delete values.uuid;
        delete values.canvas;

        const clonedObj = await cloneTargetWithProperties(values, target.canvas, { isDynamic: true });
        target.dummyDuplicatedObject = clonedObj;

        // Create Line
        const pos = calculateCurvedLinePosition(target, corner);

        const newLine = new fabric.Line([pos.x1, pos.y1, pos.x2, pos.y2], {
            stroke: '#b7403f',
            strokeWidth: 2,
            strokeDashArray: [5, 5],
            originY: 'center',
            originX: 'center',
            strokeUniform: true,
            objectCaching: false,
            arrowEnabled: true,
            arrowRight: true,
            isDynamic: true  // tells that we will use this instance only for mocking
        })

        target.canvas.add(newLine);
        target.dummyDuplicatedObjectLine = newLine;
    }, 500);
}

/**
 * @param e
 * @param actionData
 * @param x
 * @param y
 */
export function connectorControlActionHandler(e, actionData, x, y) {
    const {
        action,
        corner,
        target
    } = actionData;

    if (!isActionAllowed(action, target)) return;
    if (!target[`${corner}LineDrawing`]) { // if the line drawing does not exist, create it
        const pos = findPositionOfConnectorControl(target, corner);

        if (isPlusConnectorSupported(target)) {
            const pointers = target.canvas.getPointer(true);

            if (Math.abs(pointers.x - pos.x) < 5 || Math.abs(pointers.y - pos.y) < 5) return;
        }

        const curve = createCurvedLineByConnector(pos.x, pos.y, x, y);
        connectToGroup(target.canvas, curve, null, null, null, { dontEmitAttachedLine: true, dontEmitTarget: true, dontAddLines: true });
        curve.movingPointIndex = 1;
        curve.isDynamic = true;
        curve.forceAddZIndex = true;
        target.canvas.add(curve);
        target[`${corner}LineDrawing`] = curve;

        // discard the active object so that while drawing the line, the controls of the object are not visible
        target.canvas.setActiveObject(curve);
        eventEmitter.fire(EMITTER_TYPES.TOOLBAR_HIDE);

        // Hiding hypo points.
        if (curve.controls) {
            Object.values(curve.controls).filter(c => c.isHypoPoint).forEach(c => {
                c.setVisibility(false)
            });
        }
    }
    
    // Clearing the timeout because we need to understand long press action.
    clearTimeout(clearLongPressTimeout);

    if (target.dummyDuplicatedObject) {
        target.canvas.remove(target.dummyDuplicatedObject);
        target.canvas.remove(target.dummyDuplicatedObjectLine);
    }

    // get the line drawing and update the second point
    const curve = target[`${corner}LineDrawing`];
    const position = { x, y}
    lineAlignmentHandler(curve, 1, position);
    curve.points[1] = position;
    magnetEffectForPolygon(curve, 1, {excludedObjects: [target.uuid]});
    isMoved = true;
    curve._setPositionDimensions({});
    curve.canvas.renderAll();
}


/**
 * @param e
 * @param actionData
 * @param x
 * @param y
 */
export function mouseUpHandlerForConnectors(e, actionData) {
    const {
        action,
        corner,
        target
    } = actionData;
    if (!isActionAllowed(action, target)) return;
    const isPlusConnector = isPlusConnectorSupported(target);
    let actualCorner = corner;

    if (target.canvas) {
        if (isPlusConnector && !isMoved) {
            actualCorner = target.angle !== 0 ? getActualCorner(target.angle, corner) : corner;

            target.canvas.fire('duplicate-object', {
                target,
                options: {
                    actualCorner,
                    corner,
                    duplicatedFromConnector: true,
                    dontEmit: true,
                    dontAddToHistoryStack: true,
                    onDuplicate: () => {
                        drawCornerLine(target, corner, actualCorner);
                        const clonedObj = target.clonedObject;
                        emitObjectsCreatedByConnector(target, corner, actualCorner);
                        target.canvas.setActiveObject(clonedObj);
                    }
                },
                withOptions: true
            });
        }

        const line = target[`${actualCorner}LineDrawing`];
        if (line && typeof line.organizeControls === 'function') {
            delete line.movingPointIndex;
            line.organizeControls();
        }

        if (isMoved) {
            target[`${actualCorner}LineDrawing`].removeFromDirtyList();
            emitLineDrawing(target, corner, actualCorner);
        }

        // Clearing the timeout because we need to understand long press action.
        clearTimeout(clearLongPressTimeout);
        isMoved = false;
    }
}