/**
 * Method that defines the actions when mouse is hovering the canvas.
 * The currentTransform parameter will define whether the user is rotating/scaling/translating
 * an image or neither of them (only hovering). A group selection is also possible and would cancel
 * all any other type of action.
 * In case of an image transformation only the top canvas will be rendered.
 * @override
 * @param {Event} e Event object fired on mousemove.
 */
export function __onMouseMove(e) {
    this._handleEvent(e, 'move:before');
    this._cacheTransformEventData(e);
    var target, pointer;

    if (this.isDrawingMode) {
        this._onMouseMoveInDrawingMode(e);
        return;
    }

    if (!this._isMainEvent(e)) {
        return;
    }

    var groupSelector = this._groupSelector;

    // We initially clicked in an empty area, so we draw a box for multiple selection
    if (groupSelector) {
        pointer = this._absolutePointer;

        groupSelector.left = pointer.x - groupSelector.ex;
        groupSelector.top = pointer.y - groupSelector.ey;

        this.renderTop();
    }
    else if (!this._currentTransform) {
        target = this.findTarget(e) || null;
        this._setCursorFromEvent(e, target);
        this._fireOverOutEvents(target, e);
    }
    else {
        // override
        const { target } = this._currentTransform;
        if (target) {
            target.onTransformChanged();
        }
        this._transformObject(e);
    }
    this._handleEvent(e, 'move');
    this._resetTransformEventData();
}